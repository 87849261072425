import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useGetTemporalSharesToList } from 'apis/rest/temporalShares/hooks';
import { TemporalShare, TemporalShareBase, TemporalSharePermissions, TemporalSharesResult } from 'apis/rest/temporalShares/types';
import { isInPeriod, Period } from 'components/pages/sharing/helpers';
import usePermissions from 'hooks/session/usePermissions';
import { config } from 'chai';
import { useGetDevicesList } from 'apis/rest/devices/hooks';

interface DeviceAccessResult {
  isLoading: boolean
  check(deviceId: number, date?: Date): TemporalSharePermissions & { date: Date }
}

interface NewDeviceAccessResult extends TemporalSharePermissions {
  date: Date
}

const DEFAULT: DeviceAccessResult = {
  isLoading: true,
  check: () => ({
    date: new Date(),
    canViewCurrent: false,
    canViewHistory: false,
    canViewForms: false,
    canSendTextMessages: false,
    canSendConfiguration: false,
    canEditCallSign: false,
  }),
};

export const useCheckDeviceAccess = () => {
  const sharesQuery = useGetTemporalSharesToList();

  const { canEditShare } = usePermissions();

  const result = useCallback((deviceId: number, date?: Date) => {
    const defaultDate = new Date(sharesQuery.dataUpdatedAt);
    const trueDate = date ?? defaultDate;

    // if user is staff they can see all the shares anyway
    // If they're not staff but admin then all their self shares need to be visible
    if (canEditShare) {
      return {
        date: trueDate,
        canViewCurrent: true,
        canViewHistory: true,
        canViewForms: true,
        canSendTextMessages: true,
        canSendConfiguration: true,
        canEditCallSign: true,
      };
    }

    //combine the shares for the device
    const filteredDeviceShares = [...(sharesQuery.data?.groupShares.filter(s => s.deviceId === deviceId && isInPeriod(s, Period.Present, DateTime.fromJSDate(trueDate))) ?? []),
    ...(sharesQuery.data?.selfShares.filter(s => s.deviceId === deviceId && isInPeriod(s, Period.Present, DateTime.fromJSDate(trueDate))) ?? []),
    ...(sharesQuery.data?.shares.filter(s => s.deviceId === deviceId && isInPeriod(s, Period.Present, DateTime.fromJSDate(trueDate))) ?? [])];


    if (filteredDeviceShares?.length > 0) {
      //return the most permissive combination of shares
      return {
        date: trueDate,
        canViewCurrent: filteredDeviceShares.some(s => s.canViewCurrent),
        canViewHistory: filteredDeviceShares.some(s => s.canViewHistory),
        canViewForms: filteredDeviceShares.some(s => s.canViewForms),
        canSendTextMessages: filteredDeviceShares.some(s => s.canSendTextMessages),
        canSendConfiguration: filteredDeviceShares.some(s => s.canSendConfiguration),
        canEditCallSign: filteredDeviceShares.some(s => s.canEditCallSign),
      };
    }



    return null;
  }, [canEditShare, sharesQuery.data, sharesQuery.dataUpdatedAt]);

  return result;
};
