import { useQuery } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { userHistoryQueryKeys } from './userHistory-queryKeys';
import { getUserHistories } from './userHistory-requests';
import type { UserHistory } from './userHistory-types';

export const useGetUserHistories = <T = UserHistory[]>(from: number, until: number, isStaff = false) => {
  const organisationId = useOrganisationId();
  const queryKey = userHistoryQueryKeys.all(organisationId, from, until, isStaff);
  const query = useQuery<UserHistory[], HttpResponseError, T>({
    queryKey,
    queryFn: () => getUserHistories(organisationId, from, until, isStaff),
  });
  return { query, queryKey };
};
