import React, { useMemo } from 'react';
import LatLon from 'geodesy/latlon-spherical';
import { useCursor } from 'contexts/cursor/useCursor';
import { Stack, Typography } from '@mui/material';

const CursorPosition = ({ precision = 7 }) => {
  const [cursor] = useCursor();
  const text = useMemo(() => {
    if (!cursor || (cursor.latitude === 0 && cursor.longitude)) return [];

    const lat = (cursor.latitude || 0);
    let lng = (cursor.longitude || 0);

    if (lng > 180) lng -= 360;
    else if (lng < -180) lng += 360;

    const n = `${lat.toFixed(precision)}, ${lng.toFixed(precision)}`;
    const dm = new LatLon(lat, lng).toString('dm');
    const dms = new LatLon(lat, lng).toString('dms');

    return [n, dm, dms];
  }, [cursor, precision]);

  return (
    <Stack width={500} direction="row" gap={2}>
      {text.map((t, i) => (
        <Typography key={i} fontSize="0.9em" align="left" color="#A0A7B4">{t}</Typography>
      ))}
    </Stack>
  );
};

export default CursorPosition;
