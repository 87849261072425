import React from 'react';
import { ButtonBase, SvgIcon, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useStyles from '../loginButton/loginButton-styles';

interface MicrosoftLoginButtonProps {
  onClick: (e: React.MouseEvent, provider: string) =>void;
}

const MicrosoftLoginButton = ({ onClick }:MicrosoftLoginButtonProps): JSX.Element => {
  const t = useTranslation('pages.login');
  const classes = useStyles();
  const provider = 'microsoft.com';
  const onClickHandler = (e: React.MouseEvent): void => {
    onClick(e, provider);
  };

  return (
    <ButtonBase
      focusRipple
      onClick={onClickHandler}
      className={classes.loginButton}
    >
      <SvgIcon viewBox="0 0 23 23" style={{ width: 24, height: 24 }}>
        <svg xmlns="http://www.w3.org/2000/svg"><path fill="#f3f3f3" d="M0 0h23v23H0z"/><path fill="#f35325" d="M1 1h10v10H1z"/><path fill="#81bc06" d="M12 1h10v10H12z"/><path fill="#05a6f0" d="M1 12h10v10H1z"/><path fill="#ffba08" d="M12 12h10v10H12z"/></svg>
      </SvgIcon>
      <Typography>{ t('titleMicrosoft') }</Typography>
    </ButtonBase>
  );
};

export default MicrosoftLoginButton;
