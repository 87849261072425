import { useQuery } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useMemo } from 'react';
import { getFeatureFlag } from './requests';

type FeatureFlagsGroupsQueryKey = ['featureFlags', string, { organisationId: string }];
const useFeatureFlagsQueryKey = (feature: string, organisationId: string): FeatureFlagsGroupsQueryKey =>
  useMemo(() => ['featureFlags', feature, { organisationId }], [feature, organisationId]);

export const useGetFeatureFlag = (feature: string, enabled = true) => {
  const organisationId = useOrganisationId();
  const queryKey = useFeatureFlagsQueryKey(feature, organisationId);
  const query = useQuery({
    queryKey,
    queryFn: () => getFeatureFlag(feature, organisationId),
    enabled: enabled && !!organisationId,
    staleTime: 60 * 60 * 1000, // 1 hour
  });
  return { query, queryKey };
};
