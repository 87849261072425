import {
  StorageErrorCode,
  type UploadTask,
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

import { FirebaseError } from '@firebase/util';
import { di } from '../di';

const isNoImageError = (error: unknown) =>
  error instanceof FirebaseError &&
  (error.code === `storage/${StorageErrorCode.UNAUTHORIZED}` ||
    error.code === `storage/${StorageErrorCode.OBJECT_NOT_FOUND}`);

const firebaseDep = di.depend('firebaseService');

export const uploadAssetImage = (assetId: number, data: File | Blob): UploadTask => {
  const { storage } = firebaseDep.inject();
  const imageRef = ref(storage, `assets/${assetId}`);
  return uploadBytesResumable(imageRef, data);
};

export const getAssetImage = async (assetId: number): Promise<string | null> => {
  const { storage } = firebaseDep.inject();
  const imageRef = ref(storage, `assets/${assetId}`);

  try {
    return await getDownloadURL(imageRef);
  } catch (error) {
    if (isNoImageError(error)) return null;
    throw error;
  }
};

export const removeAssetImage = async (assetId: number): Promise<void> => {
  const { storage } = firebaseDep.inject();
  const imageRef = ref(storage, `assets/${assetId}`);

  try {
    return await deleteObject(imageRef);
  } catch (error) {
    if (isNoImageError(error)) return undefined;
    throw error;
  }
};
