export const eventNotificationQueryKeys = {
  all: (org: string) => [org, 'eventNotifications'] as const,
  group: (org: string, id: number) => [...eventNotificationQueryKeys.all(org), 'eventNotificationsGroup', id] as const,
  warnings: (org: string, id: number) =>
    [...eventNotificationQueryKeys.group(org, id), 'notificationsGroupWarnings'] as const,
  rules: (org: string) => [...eventNotificationQueryKeys.all(org), 'eventNotificationsRules'] as const,
  rule: (org: string, id: number, ruleId: number) =>
    [...eventNotificationQueryKeys.all(org), 'eventNotificationsRules', ruleId] as const,
  byContactGroup: (org: string, contactGroupId: number) =>
    [...eventNotificationQueryKeys.all(org), 'eventNotificationContactGroup', contactGroupId] as const,
  byGeofenceId: (org: string, geofenceId: number) =>
    [...eventNotificationQueryKeys.all(org), 'eventNotificationGeofence', geofenceId] as const,
};
