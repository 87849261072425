import { HttpResponseError, serenityFetch } from 'helpers/api';
import type { GeofenceCreationRequestBody, GeofenceResponseItem, GeofenceUpdateRequestBody } from './types';

export const getGeofences = async (organisationId: string): Promise<GeofenceResponseItem[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/geofences`, null);
  HttpResponseError.detect(response);
  return (await response.json()).geofences;
};

export const createGeofence = async (
  organisationId: string,
  geofence: GeofenceCreationRequestBody,
): Promise<number> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/geofences`, {
    ...geofence,
    organisationId,
  });
  HttpResponseError.detect(response);
  return (await response.json()).geofenceId;
};

export const deleteGeofence = async (organisationId: string, geofenceId: number): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/geofences/${geofenceId}`, null);
  HttpResponseError.detect(response);
};

export const updateGeofence = async (organisationId: string, geofence: GeofenceUpdateRequestBody): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/geofences/${geofence.id}`, geofence);
  HttpResponseError.detect(response);
};
