import React, { useMemo } from 'react';
import {
  List
} from '@mui/material';
import { isSignificantReport } from 'helpers/events';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useInferredEventsForReports } from 'repositories/inferredEvents/hooks';
import { ReportWithInferredEvents } from 'apis/rest/inferredEvents/types';
import SignificantReport from './significantReport';
import InsignificantReports from './insignificantReports';
import useStyles from './leg-styles';

interface LegInnerProps {
  reports: Report[];
  reportFilter: { blacklist: string[] };
}

interface Entry {
  key: number;
  significantReport?: ReportWithInferredEvents;
  insignificantReports?: Report[];
}

const isEventVisible = (event: string, filter: { blacklist: string[] }) => event && !filter.blacklist.includes(event);

const LegInner: React.FC<LegInnerProps> = ({ reports: assetReports, reportFilter }) => {
  const classes = useStyles();
  const reports = useInferredEventsForReports(assetReports);

  const entries = useMemo(() => {
    const entriesInner: Entry[] = [];
    let insignificantReportsInner: Report[] = [];
    reports?.forEach(report => {
      const hasEventInView = report.events.some(e => isEventVisible(e, reportFilter));
      // TODO: get better types for `reportFilter`
      const hasForm = report.form && reportFilter.forms;
      const isSignificant = (hasEventInView || hasForm) && isSignificantReport(report);

      if (isSignificant) {
        // Collapse all previously-seen insignificant events
        if (insignificantReportsInner.length > 0) {
          entriesInner.push({
            key: insignificantReportsInner[0].id,
            insignificantReports: insignificantReportsInner
          });
          insignificantReportsInner = [];
        }

        // Add a significant entry for this report
        entriesInner.push({
          key: report.id,
          significantReport: report
        });
      } else {
        insignificantReportsInner.push(report);
      }
    });

    if (insignificantReportsInner.length > 0) {
      entriesInner.push({
        key: insignificantReportsInner[0].id,
        insignificantReports: insignificantReportsInner
      });
    }

    return entriesInner;
  }, [reportFilter, reports]);

  if (reports?.length === 0) {
    return null;
  }

  return (
    <List className={classes.list}>
      {reports?.length > 0
        ? entries.map(entry => (
          entry.significantReport
            ? <SignificantReport key={entry.key} report={entry.significantReport} />
            : <InsignificantReports key={entry.key} reports={entry.insignificantReports} />
        ))
        : (
          <InsignificantReports reports={[]} />
        )}
    </List>
  );
};

export default LegInner;
