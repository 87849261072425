import { di } from '../di';

const consoleDep = di.depend('console');

export const noticeError = (error: Error | string, customAttributes?: Record<string, string | number | boolean | null>) => {
  const console = consoleDep.inject('NoticeError');
  console.warn(typeof error === 'string' ? error : error.message, customAttributes);
  console.error(error);
  window.newrelic?.noticeError(error, customAttributes);
};

export const setCustomAttribute = (name: string, value: string | number | boolean | null, persist?: boolean) => {
  window.newrelic?.setCustomAttribute(name, value, persist);
};
