import { type UseMutationOptions, type UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { serenityApiClient } from 'apis/client/serenityApiClient';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { beaconQueryKeys } from './queryKeys';
import type { BeaconUser, UpdateAssignedSeatsParams } from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;
type MutationOptions<MutationData, Variables> = Omit<
  UseMutationOptions<MutationData, HttpResponseError, Variables, void>,
  'queryKey' | 'queryFn'
>;

export const useGetNumberOfSeats = <T = number>(options?: Options<number, T>) => {
  const orgId = useOrganisationId();
  const queryKey = beaconQueryKeys.numSeats(orgId);
  return useQuery({
    queryKey,
    queryFn: () => serenityApiClient.organisationsBeaconSeatsGet(orgId),
    ...options,
  });
};

export const useGetAssignedSeats = <T = BeaconUser[]>(options?: Options<BeaconUser[], T>) => {
  const orgId = useOrganisationId();
  const queryKey = beaconQueryKeys.assignedSeats(orgId);
  return useQuery({
    queryKey,
    // @ts-ignore
    queryFn: () => serenityApiClient.organisationsBeaconSeatsAssignedGet(orgId),
    ...options,
  });
};

export const useUpdateNumberOfSeats = (options?: MutationOptions<void, number>) => {
  const orgId = useOrganisationId();
  return useMutation({
    mutationFn: async numSeats => {
      await serenityApiClient.organisationsBeaconSeatsPut(orgId, numSeats);
    },
    ...options,
  });
};

export const useUpdateAssignedSeats = (options?: MutationOptions<void, UpdateAssignedSeatsParams>) => {
  const orgId = useOrganisationId();
  return useMutation({
    mutationFn: async ({ toAdd, toDelete }) => {
      await serenityApiClient.organisationsBeaconSeatsAssignedDelete(orgId, toDelete);
      await serenityApiClient.organisationsBeaconSeatsAssignedPost(orgId, toAdd);
    },
    ...options,
  });
};
