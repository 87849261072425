import { SUGGESTED_ASSET_COLORS } from 'constants/colors';
import { HttpResponseError, serenityFetch } from 'helpers/api';

export type ApiAsset = Omit<AssetBasic, '__typename' | 'type'>;

// TODO: consider removal of __typename, type, and default colour
const parseAsset = (asset: ApiAsset): AssetBasic => ({
  ...asset,
  __typename: 'AssetBasic',
  type: 'asset',
  colour: asset.colour ?? SUGGESTED_ASSET_COLORS[asset.id % 16],
});

export const getAssets = async (organisationId: string): Promise<AssetBasic[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/assets`, null);
  HttpResponseError.detect(response);

  return (await response.json()).map(parseAsset);
};

export const getAsset = async (organisationId: string, assetId: number): Promise<AssetBasic> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/assets/${assetId}`, null);
  HttpResponseError.detect(response);
  return parseAsset(await response.json());
};

export const getAssetAssignedToDevice = async (organisationId: string, deviceId: number): Promise<AssetBasic> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/devices/${deviceId}/asset`, null);
  HttpResponseError.detect(response);
  return response.json();
};

export type CreateAssetBody = Pick<
  AssetBasic,
  | 'name'
  | 'category'
  | 'ownerId'
  | 'make'
  | 'model'
  | 'variant'
  | 'tailNumber'
  | 'callSign'
  | 'messagingHandle'
  | 'watchlistGroup'
>;
export const createAsset = async (organisationId: string, body: CreateAssetBody): Promise<AssetBasic> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/assets`, body);
  HttpResponseError.detect(response);
  return parseAsset(await response.json());
};

export type UpdateAssetBody = Pick<
  AssetBasic,
  | 'id'
  | 'name'
  | 'category'
  | 'make'
  | 'model'
  | 'variant'
  | 'tailNumber'
  | 'callSign'
  | 'messagingHandle'
  | 'watchlistGroup'
>;
export const updateAsset = async (organisationId: string, body: UpdateAssetBody): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/assets/${body.id}`, body);
  HttpResponseError.detect(response);
};

export type UpdateAssetCallsignBody = Pick<AssetBasic, 'id' | 'callSign'>;
export const updateAssetCallsign = async (organisationId: string, body: UpdateAssetCallsignBody): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/assets/${body.id}/call-sign`, body);
  HttpResponseError.detect(response);
};

export const updateOrganisationAssetProfile = async (
  organisationId: string,
  assetId: number,
  oap: OrganisationAssetProfile,
): Promise<boolean> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/assets/${assetId}/profile`, oap);
  HttpResponseError.detect(response);
  return true;
};

export const archiveAsset = async (organisationId: string, assetId: number, archived: boolean): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/assets/${assetId}/archive`, {
    archived,
  });
  HttpResponseError.detect(response);
};

export const unregisterAms = async (organisationId: string, assetId: number): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/assets/${assetId}/ams`, null);
  HttpResponseError.detect(response);
};

export const updateAmsSettings = async (
  organisationId: string,
  assetId: number,
  amsSettings: AMSSettings,
): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/assets/${assetId}/ams`, amsSettings);
  HttpResponseError.detect(response);
};

export const getAmsSettings = async (organisationId: string, assetId: number): Promise<AMSSettings> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/assets/${assetId}/ams`, null);
  return HttpResponseError.detect(response, true)?.json();
};

export const updateIncidentEscalationSettings = async (
  organisationId: string,
  assetId: number,
  escalationSettings: IncidentEscalationSettings,
): Promise<void> => {
  const response = await serenityFetch(
    'PUT',
    `/organisations/${organisationId}/assets/${assetId}/distress`,
    escalationSettings,
  );
  HttpResponseError.detect(response);
};

export const getIncidentEscalationSettings = async (
  organisationId: string,
  assetId: number,
): Promise<IncidentEscalationSettings> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/assets/${assetId}/distress`, null);
  HttpResponseError.detect(response);
  return response.json();
};
