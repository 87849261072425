export type SearchPatternType = 'parallelTrack' | 'creepingLine' | 'expandingBox' | 'sectorSearch' | 'rangeBearingLine';

export const ALL_SEARCH_PATTERNS: SearchPatternType[] = [
  'parallelTrack',
  'creepingLine',
  'expandingBox',
  'sectorSearch',
  'rangeBearingLine',
];
// TODO: remove when retiring `frontendSearchPatternsSSRBL` ff
export const BOX_SEARCH_PATTERNS: SearchPatternType[] = ['parallelTrack', 'creepingLine', 'expandingBox'];

type Direction = 'LEFT' | 'RIGHT';

export type SearchPattern =
  | ParallelTrackSearchPattern
  | CreepingLineSearchPattern
  | ExpandingBoxSearchPattern
  | SectorSearchPattern
  | RangeBearingLineSearchPattern;

interface BaseSearchPattern {
  id: string;
  name: string;
  notes: string;
  type: SearchPatternType;
  origin: {
    lng: number;
    lat: number;
  };
  // in degrees
  orientation: number;
  isLocked: boolean;
  isHidden: boolean;
}

export interface ParallelTrackSearchPattern extends BaseSearchPattern {
  firstTurnDirection: Direction;
  trackSpacingMetres: number;
  legCount: number;
  legLengthMetres: number;
}

export interface CreepingLineSearchPattern extends BaseSearchPattern {
  firstTurnDirection: Direction;
  trackSpacingMetres: number;
  legCount: number;
  legLengthMetres: number;
}

export interface ExpandingBoxSearchPattern extends BaseSearchPattern {
  turnDirection: Direction;
  trackSpacingMetres: number;
  legCount: number;
}

export interface SectorSearchPattern extends BaseSearchPattern {
  turnDirection: Direction;
  trackSpacingMetres: number;
}

export interface RangeBearingLineSearchPattern extends BaseSearchPattern {
  // ± degrees
  orientationArc: number;
  legLengthMetres: number;
  // ± metres
  legLengthRangeMetres: number;
}

const DEFAULT_BASE: Omit<BaseSearchPattern, 'type'> = {
  id: 'sp_default',
  name: '',
  notes: '',
  origin: { lng: 0, lat: 0 },
  orientation: 0,
  isLocked: false,
  isHidden: false,
};
const DEFAULT_PARALLEL_TRACK: ParallelTrackSearchPattern = {
  ...DEFAULT_BASE,
  type: 'parallelTrack',
  firstTurnDirection: 'LEFT',
  trackSpacingMetres: 1000,
  legCount: 8,
  legLengthMetres: 3500,
};
const DEFAULT_CREEPING_LINE: CreepingLineSearchPattern = {
  ...DEFAULT_BASE,
  type: 'creepingLine',
  firstTurnDirection: 'LEFT',
  trackSpacingMetres: 1000,
  legCount: 8,
  legLengthMetres: 3500,
};
const DEFAULT_EXPANDING_BOX: ExpandingBoxSearchPattern = {
  ...DEFAULT_BASE,
  type: 'expandingBox',
  turnDirection: 'LEFT',
  trackSpacingMetres: 1000,
  legCount: 11,
};
const DEFAULT_SECTOR_SEARCH: SectorSearchPattern = {
  ...DEFAULT_BASE,
  type: 'sectorSearch',
  turnDirection: 'LEFT',
  trackSpacingMetres: 1000,
};
const DEFAULT_RANGE_BEARING_LINE: RangeBearingLineSearchPattern = {
  ...DEFAULT_BASE,
  type: 'rangeBearingLine',
  orientationArc: 30,
  legLengthMetres: 1000,
  legLengthRangeMetres: 250,
};

export const isSearchPattern = (value: string): value is SearchPatternType =>
  (ALL_SEARCH_PATTERNS as string[]).includes(value);

export const isParallelTrack = (sp: SearchPattern): sp is ParallelTrackSearchPattern => sp.type === 'parallelTrack';
export const isCreepingLine = (sp: SearchPattern): sp is CreepingLineSearchPattern => sp.type === 'creepingLine';
export const isExpandingBox = (sp: SearchPattern): sp is ExpandingBoxSearchPattern => sp.type === 'expandingBox';
export const isSectorSearch = (sp: SearchPattern): sp is SectorSearchPattern => sp.type === 'sectorSearch';
export const isRangeBearingLine = (sp: SearchPattern): sp is RangeBearingLineSearchPattern =>
  sp.type === 'rangeBearingLine';

export const defaultSearchPattern = (type: SearchPatternType, name: string): SearchPattern => {
  let sp: SearchPattern;
  switch (type) {
    case 'parallelTrack':
      sp = DEFAULT_PARALLEL_TRACK;
      break;
    case 'creepingLine':
      sp = DEFAULT_CREEPING_LINE;
      break;
    case 'expandingBox':
      sp = DEFAULT_EXPANDING_BOX;
      break;
    case 'sectorSearch':
      sp = DEFAULT_SECTOR_SEARCH;
      break;
    case 'rangeBearingLine':
      sp = DEFAULT_RANGE_BEARING_LINE;
      break;
    default:
      throw new Error('unsupported search pattern type');
  }

  return {
    ...sp,
    name,
  };
};

const LIMITS = {
  minNameLength: 1,
  maxNameLength: 30,
  notesLength: 200,

  parallelTrack: {
    minLegCount: 2,
    maxLegCount: 100,
  },
  expandingBox: {
    minLegCount: 3,
    maxLegCount: 99,
  },
  rangeBearingLine: {
    minOrientationArc: 0,
    maxOrientationArc: 90,
    minLegLengthRange: 0,
    maxLegLengthRange: 50_000,
  },

  minSpacing: 0,
  maxSpacing: 10_000,
  minLegLength: 0,
  maxLegLength: 100_000,
};

type InvalidField = {
  field: string;
  reason: string;
};

export const getInvalidFields = (sp: SearchPattern): InvalidField[] => {
  const invalidFields: InvalidField[] = [];

  if ('trackSpacingMetres' in sp) {
    if (sp.trackSpacingMetres <= LIMITS.minSpacing) {
      invalidFields.push({ field: 'trackSpacingMetres', reason: 'tooSmall' });
    } else if (sp.trackSpacingMetres > LIMITS.maxSpacing) {
      invalidFields.push({ field: 'trackSpacingMetres', reason: 'tooLarge' });
    }
  }

  if ('legLengthMetres' in sp) {
    if (sp.legLengthMetres <= LIMITS.minLegLength) {
      invalidFields.push({ field: 'legLengthMetres', reason: 'tooSmall' });
    } else if (sp.legLengthMetres > LIMITS.maxLegLength) {
      invalidFields.push({ field: 'legLengthMetres', reason: 'tooLarge' });
    }
  }

  if (isParallelTrack(sp) || isCreepingLine(sp)) {
    if (sp.legCount < LIMITS.parallelTrack.minLegCount) {
      invalidFields.push({ field: 'legCount', reason: 'tooFew' });
    } else if (sp.legCount > LIMITS.parallelTrack.maxLegCount) {
      invalidFields.push({ field: 'legCount', reason: 'tooMany' });
    }
  }

  if (isExpandingBox(sp)) {
    if (sp.legCount < LIMITS.expandingBox.minLegCount) {
      invalidFields.push({ field: 'legCount', reason: 'tooFew' });
    } else if (sp.legCount > LIMITS.expandingBox.maxLegCount) {
      invalidFields.push({ field: 'legCount', reason: 'tooMany' });
    } else if (sp.legCount % 2 === 0) {
      invalidFields.push({ field: 'legCount', reason: 'notOdd' });
    }
  }

  if (isRangeBearingLine(sp)) {
    if (sp.orientationArc < LIMITS.rangeBearingLine.minOrientationArc) {
      invalidFields.push({ field: 'orientationArc', reason: 'tooSmall' });
    } else if (sp.orientationArc > LIMITS.rangeBearingLine.maxOrientationArc) {
      invalidFields.push({ field: 'orientationArc', reason: 'tooLarge' });
    }

    if (sp.legLengthRangeMetres < LIMITS.rangeBearingLine.minLegLengthRange) {
      invalidFields.push({ field: 'legLengthRangeMetres', reason: 'tooSmall' });
    } else if (sp.legLengthRangeMetres > LIMITS.rangeBearingLine.maxLegLengthRange) {
      invalidFields.push({ field: 'legLengthRangeMetres', reason: 'tooLarge' });
    }
  }

  if (sp.name.length < LIMITS.minNameLength) {
    invalidFields.push({ field: 'name', reason: 'tooShort' });
  } else if (sp.name.length > LIMITS.maxNameLength) {
    invalidFields.push({ field: 'name', reason: 'tooLong' });
  }

  if (sp.notes.length > LIMITS.notesLength) {
    invalidFields.push({ field: 'notes', reason: 'tooLong' });
  }

  if (sp.origin.lat > 90 || sp.origin.lat < -90) {
    invalidFields.push({ field: 'origin', reason: 'latOutOfBounds' });
  }
  if (sp.origin.lng > 180 || sp.origin.lng < -180) {
    invalidFields.push({ field: 'origin', reason: 'lngOutOfBounds' });
  }

  return invalidFields;
};

export const getLegCount = (sp: SearchPattern) => {
  if (isParallelTrack(sp) || isCreepingLine(sp)) {
    return Math.round(sp.legCount);
  }

  if (isExpandingBox(sp)) {
    return 2 * Math.ceil(sp.legCount / 2) - 1;
  }

  throw new Error(`${sp.type} is not supported`);
};
