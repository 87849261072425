import { type UseQueryOptions, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { zipObject } from 'lodash';
import { useCallback } from 'react';
import { tripQueryKeys } from './queryKeys';
import {
  type TripDeletionRequest,
  type UserTransitionToAdd,
  approveTrip,
  deleteTripsBetween,
  getBasicTripsForAssets,
  getSupplementaryData,
  getSupplementaryDataFields,
  getTrip,
  getTripSummaryForAssets,
  getTripsForAssets,
  getTripsForAssetsNoReports,
  updateSupplementaryDataForTrip,
  updateTrips,
} from './requests';
import type { TripBasic } from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetTripsForAssets = <T = Trip[]>(
  assets: number[],
  from?: number,
  until?: number,
  options: Options<Trip[], T> = {},
) => {
  const organisationId = useOrganisationId();
  const queryKey = tripQueryKeys.tripsForAssets(organisationId, assets, from, until);
  return useQuery({
    queryKey,
    queryFn: async () => {
      if (!from || !until || assets.length === 0) {
        return [];
      }
      return getTripsForAssets(organisationId, assets, from, until);
    },
    ...options,
    enabled: options.enabled !== false && !!(from && until),
  });
};

export const useGetTripsNoReportsForAssets = <T = Trip[]>(
  assets: number[],
  from?: number,
  until?: number,
  options: Options<Trip[], T> = {},
) => {
  const organisationId = useOrganisationId();
  const queryKey = tripQueryKeys.tripsForAssets(organisationId, assets, from, until);
  return useQuery({
    queryKey,
    queryFn: async () => {
      if (!from || !until || assets.length === 0) {
        return [];
      }
      return getTripsForAssetsNoReports(organisationId, assets, from, until);
    },
    ...options,
    enabled: options.enabled !== false && !!(from && until),
  });
};

export const useGetBasicTripsForAssets = <T = TripBasic[]>(
  assets: number[],
  from?: number,
  until?: number,
  options: Options<TripBasic[], T> = {},
) => {
  const organisationId = useOrganisationId();
  const queryKey = tripQueryKeys.tripsBasicForAssets(organisationId, assets, from, until);
  return useQuery({
    queryKey,
    queryFn: async () => {
      if (!from || !until || assets.length === 0) {
        return [];
      }
      return getBasicTripsForAssets(organisationId, assets, from, until);
    },
    ...options,
    enabled: options.enabled !== false && !!(from && until),
  });
};

export const useGetTripSummariesForAssets = <T = TripSummary[]>(
  assets: number[],
  from?: number,
  until?: number,
  options: Options<TripSummary[], T> = {},
) => {
  const organisationId = useOrganisationId();
  const queryKey = tripQueryKeys.tripSummariesForAssets(organisationId, assets, from, until);
  return useQuery({
    queryKey,
    queryFn: async () => {
      if (!from || !until || assets.length === 0) {
        return [];
      }
      return getTripSummaryForAssets(organisationId, assets, from, until);
    },
    ...options,
    enabled: options.enabled !== false && !!(from && until),
  });
};

export const useGetTrip = <T = Trip>(asset: number, tripId: string, options: Options<Trip, T> = {}) => {
  const organisationId = useOrganisationId();
  const queryKey = tripQueryKeys.singleTrip(organisationId, asset, tripId);
  return useQuery({
    queryKey,
    queryFn: async () => {
      return getTrip(organisationId, asset, tripId);
    },
    ...options,
    enabled: options.enabled !== false,
  });
};

export const useSubmitChangeRequest = () => {
  const organisationId = useOrganisationId();

  return useMutation({
    mutationKey: ['changeRequest'],
    mutationFn: (knownTransitions: UserTransitionToAdd[]) => updateTrips(organisationId, knownTransitions),
  });
};

export const useDeleteTripsBetween = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteTrips'],
    mutationFn: (deletionReq: TripDeletionRequest) => deleteTripsBetween(organisationId, deletionReq),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: tripQueryKeys.allKnownTransitions(organisationId) }),
  });
};

export const useSupplementaryDataForTrip = <T = SupplementaryData | null>(
  tripId: string,
  options: Options<SupplementaryData | null, T> = {},
) => {
  const organisationId = useOrganisationId();

  return useQuery({
    queryKey: tripQueryKeys.supplementaryDataForTrip(organisationId, tripId),
    queryFn: async () => getSupplementaryData(organisationId, tripId),
    ...options,
  });
};

export const useSupplementaryDataForTrips = (tripIds: string[]) => {
  const organisationId = useOrganisationId();
  return useQueries({
    queries: tripIds.map<UseQueryOptions<SupplementaryData | null, HttpResponseError>>(t => ({
      queryKey: tripQueryKeys.supplementaryDataForTrip(organisationId, t),
      queryFn: () => getSupplementaryData(organisationId, t),
    })),
  });
};

export const useSupplementaryDataFields = <T = SupplementaryDataFieldType[]>(
  options: Options<SupplementaryDataFieldType[], T> = {},
) => {
  const organisationId = useOrganisationId();
  const queryKey = tripQueryKeys.supplementaryDataFields(organisationId);

  return useQuery({
    queryKey,
    queryFn: () => getSupplementaryDataFields(organisationId),
    ...options,
  });
};

export const useUpdateSupplementaryData = (tripId: string) => {
  const organisationId = useOrganisationId();

  return useMutation({
    mutationKey: ['supplementaryData', tripId],
    mutationFn: (updateReq: SupplementaryDataEvent[]) =>
      updateSupplementaryDataForTrip(organisationId, tripId, updateReq),
  });
};

export const useApproveTrips = () => {
  const organisationId = useOrganisationId();

  return useMutation({
    mutationKey: ['tripApproval'],
    mutationFn: (tripIds: string[]) => Promise.all(tripIds.map(t => approveTrip(organisationId, t))),
  });
};

// NOTE: This is for one-off on-demand fetching of trips
export const useFetchTrips = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useCallback(
    async (assetIds: number[], from: number, until: number, staleTime = 0) =>
      queryClient.fetchQuery({
        queryKey: tripQueryKeys.tripsForAssets(organisationId, assetIds, from, until),
        queryFn: () => getTripsForAssets(organisationId, assetIds, from, until),
        staleTime,
      }),
    [queryClient, organisationId],
  );
};

export const useFetchSupplementaryDataForTrips = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useCallback(
    async (tripIds: string[], staleTime = 0) =>
      Promise.all(
        tripIds.map(tripId =>
          queryClient.fetchQuery({
            queryKey: tripQueryKeys.supplementaryDataForTrip(organisationId, tripId),
            queryFn: () => getSupplementaryData(organisationId, tripId),
            staleTime,
          }),
        ),
      ).then(results => zipObject(tripIds, results)),
    [queryClient, organisationId],
  );
};
