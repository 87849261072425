import { PathLayer, SolidPolygonLayer } from '@deck.gl/layers';
import { useEffect, useMemo, useState } from 'react';
import { memoize } from 'lodash';
import { hexToRGBArray } from 'helpers/color';
import { Coord, Polyline } from 'repositories/reports/spline';
import { MapSettings } from 'slices/map.slice';
import { addColoursToSpline } from 'utils/colormap';
import { EventType, generateSpline } from 'repositories/reports';
import { useReportsDataRepository } from 'repositories/reports/hooks';
import { useSelector } from 'react-redux';
import { selectFirefightingMode } from 'slices/settings/mapSettings.slice';
import AntTrailLayer from './antTrailLayer';
import HighlightedTrailLayer from './highlightedTrailLayer';
import { calculateDistanceTravelled } from '../modules/useDistanceTraveled';

interface TrailFeature extends PathFeature {
  totalPositions: number[]
}

const WHITE: ColorAlpha = [255, 255, 255, 255];

const triplicate = <T extends PathFeature,>(trails: T[]) => trails.flatMap(feature => [
  {
    ...feature,
    path: feature.path.map(([lon, lat, alt]) => ([lon - 360, lat, alt]))
  },
  feature,
  {
    ...feature,
    path: feature.path.map(([lon, lat, alt]) => ([lon + 360, lat, alt]))
  }
]);

const getAssetColor = memoize((color: string | null | undefined): ColorAlpha => (color ? hexToRGBArray(color) : [255, 105, 180, 255]));

const handleNewSplines = (currentFeatures: TrailFeature[], newAssets: AssetBasic[], splines: Record<number, Polyline[]>, triple: boolean, use3D: boolean) => {
  const assetIds = newAssets.map(a => a.id);
  const newTrails = currentFeatures.filter(t => !assetIds.includes(t.properties.assetId));

  const totalPositions = calculateDistanceTravelled(splines);

  const newAssetTrails = newAssets.flatMap(asset => {
    const assetColor = getAssetColor(asset.colour);

    return splines[asset.id]?.map((spline, i) => ({
      properties: {
        color: assetColor,
        assetId: asset.id
      },
      path: use3D ? spline : spline.map(s => [s[0], s[1], 0] as Coord),
      totalPositions: totalPositions[asset.id]?.[i] ?? []
    })) ?? [];
  });

  newTrails.push(...(triple ? triplicate(newAssetTrails) : newAssetTrails));

  return newTrails;
};

export const useAssetTrailData = (assets: AssetBasic[], legs: Leg[] | null, triple = false, use3d = false): TrailFeature[] => {
  const reports = useReportsDataRepository();
  const [assetTrailData, setAssetTrailData] = useState<TrailFeature[]>([]);

  useEffect(() => {
    const allTrailData = handleNewSplines([], assets, reports.getSplinesForMultipleLegs(assets.map(a => a.id), legs), triple, use3d);
    setAssetTrailData(allTrailData);

    const subscription = reports.subject
      .subscribe(r => {
        if (r.type === EventType.SET_ALL) {
          setAssetTrailData(handleNewSplines([], assets, reports.getSplinesForMultipleLegs(assets.map(a => a.id), legs), triple, use3d));
        } else {
          const relatedAssets = assets.filter(a => r.assetIds?.includes(a.id) || r.assetId === a.id);
          if (relatedAssets.length > 0) {
            setAssetTrailData(oldData => (
              handleNewSplines(oldData, relatedAssets, reports.getSplinesForMultipleLegs(relatedAssets.map(a => a.id), legs), triple, use3d)
            ));
          }
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [reports, assets, legs, triple, use3d]);

  return assetTrailData;
};

export const useSelectedTrailData = (
  colouringOption: MapSettings['assetTrailColouring'],
  asset?: AssetBasic,
  legs: Leg[] | null,
  use3d = false,
): TrailFeature[] => {
  const reports = useReportsDataRepository();
  const assetArray = useMemo(() => (asset ? [asset] : []), [asset]);
  const leg = legs?.find(l => l.assetId === asset?.id) ?? null;

  // don't triplicate - we rely on the fact that each report is represented once.
  const assetTrailData = useAssetTrailData(assetArray, legs, false, use3d);

  const [assetTrailDataWithColours, setAssetTrailDataWithColours] = useState<TrailFeature[]>([]);

  useEffect(() => {
    if (!asset || colouringOption === 'none') { setAssetTrailDataWithColours(triplicate(assetTrailData)); return; }

    const selectedAssetReports = reports.getAltitudeFilledReportsForAsset(asset.id, leg);
    const fallbackColor = hexToRGBArray(asset?.colour || '#000000', 1);
    const splineWithColors = addColoursToSpline(selectedAssetReports, assetTrailData, colouringOption, fallbackColor);
    setAssetTrailDataWithColours(triplicate(splineWithColors));
  }, [reports, asset, assetTrailData, colouringOption, leg]);

  return assetTrailDataWithColours;
};

export const useTrailLayers = (
  name: string,
  visible: boolean,
  data: TrailFeature[],
  trailWidth: number,
  animate: boolean,
  zoom: number,
  use3d = false,
  showCurtain = false,
  opacity = 1,
) => {
  const dropHighlightMode = useSelector(selectFirefightingMode);
  const trailInnerOpacity = dropHighlightMode ? 0.1 : opacity;
  const trailInnerWidth = dropHighlightMode ? 1 : trailWidth;
  const trailOuterOpacity = dropHighlightMode ? 0.2 : opacity;
  const trailOuterWidth = dropHighlightMode ? trailInnerWidth + 0.5 : trailWidth + 2;
  const polygonLayer = useMemo(
    () => {
      if (!showCurtain || !use3d) return null;
      return new SolidPolygonLayer<{ start: Coord, end?: Coord }>({
        id: `${name}-trails-layer-curtain`,
        data: data
          .flatMap(d => d.path.map((c, i, a) => ({ start: c, end: a.at(i + 1) }))),
        getPolygon: d => {
          if (!d.end) {
            return [];
          }
          return [...d.start, ...d.end, ...[d.end[0], d.end[1], 0], ...[d.start[0], d.start[1], 0], ...d.start];
        },
        getFillColor: [0, 0, 0, 255],
        _normalize: false,
        _full3d: true,
        parameters: { depthTest: true },
        opacity: trailInnerOpacity / 5,
        visible
      });
    },
    [showCurtain, use3d, name, data, trailInnerOpacity, visible]
  );

  const outlineLayer = useMemo(() => new AntTrailLayer<TrailFeature>({
    id: `${name}-trails-layer-outline`,
    data,
    pickable: false,
    getColor: WHITE,
    getWidth: trailOuterWidth,
    opacity: trailOuterOpacity,
    billboard: true,
    widthUnits: 'pixels',
    widthScale: 1,
    jointRounded: true,
    capRounded: true,
    // parameters: { depthTest: use3d && zoom > 12 },
    visible,
  }), [name, use3d, data, trailOuterWidth, trailOuterOpacity, zoom, visible]);

  const layer = useMemo(() => new AntTrailLayer<TrailFeature>({
    id: `${name}-trails-layer`,
    data,
    pickable: false,
    getColor: d => d.properties.color,
    getWidth: trailInnerWidth,
    getTotalPositions: d => d.totalPositions,
    opacity: trailInnerOpacity,
    widthUnits: 'pixels',
    widthScale: 1,
    billboard: true,
    jointRounded: true,
    capRounded: true,
    dashScale: 2 ** (15 - zoom) / 20,
    animationSpeed: (2 ** (15 - zoom)) / 1280,
    animate,
    // parameters: { depthTest: use3d && zoom > 12 },
    visible,
  }), [name, use3d, data, trailInnerWidth, trailInnerOpacity, zoom, animate, visible]);

  return [polygonLayer, outlineLayer, layer] as const;
};

export const useHighlightedTrailLayers = (
  visible: boolean,
  assets: AssetBasic[],
  trailWidth: number,
  use3d = false
) => {
  const trailHighlight = useSelector<ReduxState, TrailHighlight | null>(state => state.map.trailHighlight);
  const asset = useMemo(() => assets.find(a => a.id === trailHighlight?.assetId), [assets, trailHighlight]);
  const data = useMemo(() => {
    if (!asset || !trailHighlight) return [];

    const properties = {
      color: hexToRGBArray(asset.colour ?? '#fff', 1),
      assetId: asset.id,
    };
    const paths = generateSpline(trailHighlight.reports);
    return triplicate(paths.map(path => ({ properties, path: use3d ? path : path.map(c => [c[0], c[1], 0]) })));
  }, [asset, trailHighlight, use3d]);

  const layers = useMemo(() => [
    new HighlightedTrailLayer<PathFeature>({
      id: `trails-layer-highlight-${use3d ? '3d' : '2d'}`,
      data,
      pickable: false,
      animate: true,
      getColor: d => d.properties.color,
      getWidth: trailWidth + 2,
      opacity: 1,
      billboard: true,
      widthUnits: 'pixels',
      widthScale: 1,
      jointRounded: true,
      capRounded: true,
      // parameters: { depthTest: false },
      visible,
    }),
  ] as const, [use3d, data, trailWidth, visible]);

  return layers;
};
