import { type UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { inferredEventsQueryKeys } from './queryKeys';
import { getInferredEventsForAsset } from './requests';
import type { InferredEvent, InferredEventId } from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetInferredEventsForAsset = <T = InferredEvent[]>(
  assetId: number | undefined,
  from: DateTime,
  until: DateTime,
  options: Options<InferredEvent[], T> = {},
) => {
  const organisationId = useOrganisationId();
  const queryKey = inferredEventsQueryKeys.asset(organisationId, assetId, from, until);
  return useQuery<InferredEvent[], HttpResponseError, T>({
    queryKey,
    queryFn: () => getInferredEventsForAsset(organisationId, assetId, from, until),
    ...options,
  });
};

export const useGetInferredEventsByReportIdForAsset = (
  assetId: number | undefined,
  from: DateTime,
  until: DateTime,
  options: Options<InferredEvent[], Record<number, InferredEventId[]>> = {},
) => {
  const featureAssets = useFeatureAssets('events.inferred_events');
  const selectInferredEventsByReportId = useCallback(
    (data: InferredEvent[]) =>
      data
        .filter(e => featureAssets.hasAssetId(e.assetId))
        .reduce<Record<number, InferredEventId[]>>((acc, evt) => {
          if (!acc[evt.reportId]?.push(evt.eventId)) acc[evt.reportId] = [evt.eventId];
          return acc;
        }, {}),
    [featureAssets],
  );

  return useGetInferredEventsForAsset(assetId, from, until, {
    select: selectInferredEventsByReportId,
    ...options,
  });
};

// NOTE: This is for one-off on-demand fetching of inferred events
export const useFetchInferredEvents = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();
  const featureAssets = useFeatureAssets('events.inferred_events');

  return useCallback(
    async (assetIds: number[], from: number, until: number, staleTime = 0) => {
      const fromDt = DateTime.fromMillis(from);
      const untilDt = DateTime.fromMillis(until);

      const results = await Promise.allSettled(
        assetIds.map(assetId =>
          queryClient.fetchQuery({
            queryKey: inferredEventsQueryKeys.asset(organisationId, assetId, fromDt, untilDt),
            queryFn: () => getInferredEventsForAsset(organisationId, assetId, fromDt, untilDt),
            staleTime,
          }),
        ),
      );

      return results
        .filter(r => r.status === 'fulfilled')
        .flatMap(r => r.value)
        .filter(evt => featureAssets.hasAssetId(evt.assetId))
        .reduce<Record<number, InferredEventId[]>>((acc, evt) => {
          if (!acc[evt.reportId]?.push(evt.eventId)) acc[evt.reportId] = [evt.eventId];
          return acc;
        }, {});
    },
    [queryClient, organisationId, featureAssets],
  );
};
