import { createAsyncThunk } from '@reduxjs/toolkit';
import { assignItemToMap, unassignItemFromMap } from '../map.slice';
import { clearSelection, selectItem } from '../app.slice';
import type { TRootState } from 'reducers';

export const selectAssetAndSetUrl = createAsyncThunk(
  'map/selectAssetAndSetUrl',
  async ({ mapId, asset }: { mapId: string, asset: AssetWithDevice | AssetBasic | undefined }, { getState, dispatch }) => {



    const state = getState() as TRootState;
    const selectedAsset = state.app.selectedItem;

    console.log('maps', state.map.maps);

    // Get index of current mapId in maps array
    const mapIndex = state.map.maps[mapId].sortOrder;

    dispatch(selectItem(asset ?? null));
    dispatch(assignItemToMap({ mapId, item: asset ?? null }));

    // Update URL params
    const searchParams = new URLSearchParams(window.location.search);

    // Set asset param based on map index
    if (asset?.id && asset.id !== selectedAsset?.id) {
      if (mapId === 'default') {
        searchParams.set('asset', asset.id.toString());
      } else {
        // For non-default maps, use asset1, asset2 etc based on map index
        const paramName = `asset${mapIndex}`;
        searchParams.set(paramName, asset.id.toString());
      }
    } else {
      // Remove the appropriate param
      if (mapId === 'default') {
        searchParams.delete('asset');
      } else {
        const paramName = `asset${mapIndex}`;
        searchParams.delete(paramName);
      }
    }
    searchParams.sort();


    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }
);

export const clearAssetAndUrl = createAsyncThunk(
  'map/clearAssetAndUrl',
  async (_, { getState, dispatch }) => {
    const state = getState() as TRootState;
    const selectedMapId = state.map.selectedMapId;
    const mapIndex = state.map.maps[selectedMapId].sortOrder;

    dispatch(clearSelection());
    dispatch(unassignItemFromMap());

    // Update URL params
    const searchParams = new URLSearchParams(window.location.search);

    // Remove the appropriate param based on map index
    if (selectedMapId === 'default') {
      searchParams.delete('asset');
    } else {
      const paramName = `asset${mapIndex}`;
      searchParams.delete(paramName);
    }
    searchParams.sort();

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }
);
