import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bearing } from 'helpers/unitsOfMeasure';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import { DateTime } from 'luxon';

interface BearingInputProps extends Omit<TextFieldProps, 'type' | 'onChange' | 'onBlur'> {
  value: number;
  onChangeValue?: (val: number) => void;
  time: DateTime,
  location: { lng: number, lat: number };
}

export const BearingInput: React.FC<BearingInputProps> = ({ value, onChangeValue, time, location, ...rest }) => {
  const [previousValue, setPreviousValue] = useState<number | null>(null);
  const [pendingValue, setPendingValue] = useState<string>('');
  const bearingUnit = useUnitSettings().bearing;
  const timeMillis = useMemo(() => time.toUTC().toMillis(), [time]);

  useEffect(() => {
    const newValue = bearing.fromSI(value, timeMillis, { longitude: location.lng, latitude: location.lat }, bearingUnit);

    if (previousValue === null) {
      setPendingValue(newValue.toFixed(0));
      setPreviousValue(newValue);
      return;
    }

    if (pendingValue !== '' && Math.abs(previousValue - newValue) > 0.01) {
      setPendingValue(newValue.toFixed(0));
      setPreviousValue(newValue);
    }
  }, [bearingUnit, location.lat, location.lng, pendingValue, previousValue, value, timeMillis]);

  const handleOnChange = useCallback((evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const potentialNumber = evt.target.value.trim();
    setPendingValue(potentialNumber);

    if (potentialNumber === '') {
      return;
    }

    const num = Number(potentialNumber);
    if (!Number.isNaN(num)) {
      onChangeValue?.(bearing.toSI(num, timeMillis, { longitude: location.lng, latitude: location.lat }, bearingUnit));
      setPreviousValue(num);
    }
  }, [bearingUnit, location.lat, location.lng, onChangeValue, timeMillis]);

  const handleOnBlur = useCallback((evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const potentialNumber = evt.target.value.trim();
    if (potentialNumber === '') {
      setPendingValue(bearing.fromSI(value, timeMillis, { longitude: location.lng, latitude: location.lat }, bearingUnit).toFixed(0).toString());
      return;
    }

    const num = Number(potentialNumber);
    if (!Number.isNaN(num)) {
      setPendingValue(num.toFixed(0));
    }
  }, [bearingUnit, location.lat, location.lng, timeMillis, value]);

  return (
    <TextField
      {...rest}
      value={pendingValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      type="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">{bearing.label(bearingUnit, timeMillis)}</InputAdornment>,
      }}
      sx={{
        flex: 1,
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' }
      }}
    />
  );
};
