import { type UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useStaff } from 'hooks/session/useStaff';
import { temporalShareQueryKeys } from './queryKeys';
import {
  createGroupShares,
  createShares,
  deleteGroupShare,
  deleteShare,
  fetchGroupSharesFrom,
  fetchGroupSharesTo,
  fetchSharesFrom,
  fetchSharesSelf,
  fetchSharesTo,
  updateGroupShare,
  updateShare,
} from './requests';
import type {
  CreateGroupSharesBody,
  CreateSharesBody,
  DeleteGroupShareBody,
  DeleteShareBody,
  TemporalSharesResult,
  UpdateGroupShareBody,
  UpdateShareBody,
} from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

const FULFILLED = 'fulfilled';

export const useGetTemporalSharesFromList = <T = TemporalSharesResult>(options?: Options<TemporalSharesResult, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = temporalShareQueryKeys.fromLists(organisationId);
  const canEditShare = useStaff();
  return useQuery({
    queryKey,
    queryFn: async () => {
      const [shares, groupShares, selfShares] = await Promise.allSettled([
        fetchSharesFrom(organisationId),
        fetchGroupSharesFrom(organisationId),
        canEditShare ? fetchSharesSelf(organisationId) : [],
      ]);
      return {
        shares: shares.status === FULFILLED ? shares.value : [],
        groupShares: groupShares.status === FULFILLED ? groupShares.value : [],
        selfShares: selfShares.status === FULFILLED ? selfShares.value : [],
      };
    },
    ...options,
  });
};

export const useGetTemporalSharesToList = <T = TemporalSharesResult>(options?: Options<TemporalSharesResult, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = temporalShareQueryKeys.toLists(organisationId);
  const canEditShare = useStaff();

  return useQuery({
    queryKey,
    queryFn: async () => {
      const [shares, groupShares, selfShares] = await Promise.allSettled([
        fetchSharesTo(organisationId),
        fetchGroupSharesTo(organisationId),
        canEditShare ? fetchSharesSelf(organisationId) : [],
      ]);
      return {
        shares: shares.status === FULFILLED ? shares.value : [],
        groupShares: groupShares.status === FULFILLED ? groupShares.value : [],
        selfShares: selfShares.status === FULFILLED ? selfShares.value : [],
      };
    },
    ...options,
  });
};

export const useCreateShares = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, CreateSharesBody | CreateGroupSharesBody>({
    mutationFn: value => {
      if ('shareToGroupId' in value) return createGroupShares(organisationId, value);
      return createShares(organisationId, value);
    },
    mutationKey: ['createShare'],
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: temporalShareQueryKeys.fromLists(organisationId),
      }),
  });
};

export const useUpdateShare = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, UpdateShareBody | UpdateGroupShareBody>({
    mutationFn: value => {
      if ('groupId' in value) return updateGroupShare(organisationId, value);
      return updateShare(organisationId, value);
    },
    mutationKey: ['updateShare'],
    onSuccess: (_, value) => {
      if ('organisationId' in value && value.organisationId === organisationId) {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: temporalShareQueryKeys.fromLists(organisationId),
          }),
          queryClient.invalidateQueries({
            queryKey: temporalShareQueryKeys.toLists(organisationId),
          }),
        ]);
      }
      return queryClient.invalidateQueries({
        queryKey: temporalShareQueryKeys.fromLists(organisationId),
      });
    },
  });
};

export const useDeleteShare = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, DeleteShareBody | DeleteGroupShareBody>({
    mutationFn: value => {
      if ('groupId' in value) return deleteGroupShare(organisationId, value);
      return deleteShare(organisationId, value);
    },

    mutationKey: ['deleteShare'],
    onSuccess: (_, value) => {
      if ('organisationId' in value && value.organisationId === organisationId) {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: temporalShareQueryKeys.fromLists(organisationId),
          }),
          queryClient.invalidateQueries({
            queryKey: temporalShareQueryKeys.toLists(organisationId),
          }),
        ]);
      }
      return queryClient.invalidateQueries({
        queryKey: temporalShareQueryKeys.fromLists(organisationId),
      });
    },
  });
};
