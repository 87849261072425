import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { eventNotificationQueryKeys } from './queryKeys';
import * as requests from './requests';
import { getNotificationRulesByGeofenceId } from './requests';
import type * as types from './types';
import { EventNotificationRuleWithGroupName } from './types';

export const useListEventNotificationGroups = () => {
  const organisationId = useOrganisationId();
  const queryKeys = eventNotificationQueryKeys.all(organisationId);
  const query = useQuery({
    queryKey: queryKeys,
    queryFn: () => requests.listEventNotificationGroups(organisationId),
  });
  return { query, queryKeys };
};

export const useListNotificationRules = () => {
  const organisationId = useOrganisationId();
  const queryKey = eventNotificationQueryKeys.rules(organisationId);
  const allEventNotifications = useListEventNotificationGroups().query.data;
  return useQuery({
    queryKey,
    queryFn: async () => {
      const groups = allEventNotifications ?? (await requests.listEventNotificationGroups(organisationId));
      return (await Promise.all(groups.map(g => requests.getEventNotification(organisationId, g.id)))).flatMap(
        g => g.notificationRules,
      );
    },
  });
};

export const useGetEventNotificationGroup = (groupId: number) => {
  const organisationId = useOrganisationId();
  const queryKeys = eventNotificationQueryKeys.group(organisationId, groupId);
  const query = useQuery<types.EventNotificationGroup, HttpResponseError>({
    queryKey: queryKeys,
    queryFn: () => requests.getEventNotification(organisationId, groupId),
  });
  return { query, queryKeys };
};

export const useGetNotificationRulesByContact = (contactGroupId: number) => {
  const organisationId = useOrganisationId();
  const queryKeys = eventNotificationQueryKeys.byContactGroup(organisationId, contactGroupId);
  const query = useQuery<types.EventNotificationRule[], HttpResponseError>({
    queryKey: queryKeys,
    queryFn: () => requests.getNotificationRulesByContact(organisationId, contactGroupId),
  });
  return { query, queryKeys };
};

export const useGetNotificationRulesByGeofenceId = (geofenceId: number) => {
  const organisationId = useOrganisationId();
  const queryKeys = eventNotificationQueryKeys.byGeofenceId(organisationId, geofenceId);
  const query = useQuery<types.EventNotificationRuleWithGroupName[], HttpResponseError>({
    queryKey: queryKeys,
    queryFn: () => requests.getNotificationRulesByGeofenceId(organisationId, geofenceId),
  });
  return { query, queryKeys };
};

export const useMutateCreateEventNotificationGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<
    types.EventNotificationGroup,
    HttpResponseError,
    Pick<types.EventNotificationGroupRequest, 'name'>
  >({
    mutationKey: ['createEventNotification'],
    mutationFn: value => requests.createEventNotification(organisationId, value.name),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: eventNotificationQueryKeys.all(organisationId) }),
  });
};

export const useMutateCreateEventNotificationRule = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<types.EventNotificationRule, HttpResponseError, types.NotificationRuleRequest>({
    mutationKey: ['createEventNotificationRule'],
    mutationFn: value =>
      requests.createEventNotificationRule(organisationId, value.notificationRule.notificationGroupId, value),
    onSuccess: data => queryClient.invalidateQueries({ queryKey: eventNotificationQueryKeys.all(organisationId) }),
  });
};

export const useMutateUpdateEventNotificationGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, types.NotificationGroupRequest>({
    mutationKey: ['updateEventNotificationGroup'],
    mutationFn: value => requests.updateEventNotification(organisationId, value),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: eventNotificationQueryKeys.all(organisationId) }),
  });
};

export const useMutateUpdateEventNotificationRule = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, types.NotificationRuleRequest>({
    mutationKey: ['updateEventNotificationRule'],
    mutationFn: value =>
      requests.updateEventNotificationRule(organisationId, value.notificationRule.notificationGroupId, value),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: eventNotificationQueryKeys.all(organisationId) }),
  });
};

export const useMutateUpdateAssetGroupsForEventNotificationGroup = (onSuccess: () => void) => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { notificationGroupId: number; assetGroupIds: number[] }>({
    mutationKey: ['updateAssetGroupsForEventNotificationGroup'],
    mutationFn: value =>
      requests.updateAssetGroupsForEventNotificationGroup(
        organisationId,
        value.notificationGroupId,
        value.assetGroupIds,
      ),
    onSuccess: (data, request) => {
      queryClient.invalidateQueries({
        queryKey: eventNotificationQueryKeys.group(organisationId, request.notificationGroupId),
      });
      onSuccess();
    },
  });
};

export const useMutateUpdatePeopleGroupsForEventNotificationRule = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<
    void,
    HttpResponseError,
    { notificationGroupId: number; ruleId: number; peopleGroupIds: number[] }
  >({
    mutationKey: ['updatePeopleGroupsForEventNotificationRule'],
    mutationFn: value =>
      requests.updatePeopleGroupsForEventNotificationRule(
        organisationId,
        value.notificationGroupId,
        value.ruleId,
        value.peopleGroupIds,
      ),
    onSuccess: (data, request) =>
      queryClient.invalidateQueries({
        queryKey: eventNotificationQueryKeys.group(organisationId, request.notificationGroupId),
      }),
  });
};

export const useMutateDeleteEventNotificationGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { id: number; rowVersion: number }>({
    mutationKey: ['deleteEventNotificationGroup'],
    mutationFn: value => requests.deleteEventNotificationGroup(organisationId, value.id, value.rowVersion),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: eventNotificationQueryKeys.all(organisationId) }),
  });
};

export const useMutateDeleteEventNotificationRule = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<types.EventNotificationRule, 'id' | 'notificationGroupId'>>({
    mutationKey: ['deleteEventNotificationRule'],
    mutationFn: value => requests.deleteEventNotificationRule(organisationId, value.notificationGroupId, value.id),
    onSuccess: (_, value) =>
      queryClient.invalidateQueries({
        queryKey: eventNotificationQueryKeys.group(organisationId, value.notificationGroupId),
      }),
  });
};

export const useGetEventNotificationWarnings = (groupId: number) => {
  const organisationId = useOrganisationId();
  const queryKeys = eventNotificationQueryKeys.warnings(organisationId, groupId);
  const query = useQuery<types.EventNotificationWarnings, HttpResponseError>({
    queryKey: queryKeys,
    queryFn: () => requests.getEventNotificationWarnings(organisationId, groupId),
  });
  return { query, queryKeys };
};
