import auth, { getRefreshedToken, logout } from 'apis/auth';

const HOSTNAME = self?.location?.hostname;

export const getEnvironment = (): string => {
  // Prefer production behaviour if unsure. It's more likely to be consistent
  if (HOSTNAME !== 'localhost') return 'production';
  return 'development';
};

export const getApiUrl = (api: string, protocol = 'http'): string => {
  switch (getEnvironment()) {
    case 'production':
      if (protocol === 'ws') return `wss://${HOSTNAME}/api/${api}`;
      return `/api/${api}`;
    default:
      if (api === 'graphql' && protocol === 'ws') return 'ws://localhost:3000/api/graphql';
      if (protocol === 'ws') return `ws://localhost:3000/api/${api}`;
      return `/api/${api}`;
  }
};

export const getHeaders = (): HeadersInit => {
  const token = localStorage.getItem('bearerToken');
  if (!token) return {};
  return {
    authorization: `Bearer ${token}`,
    'X-TPC-Version': import.meta.env.PACKAGE_VERSION,
  };
};

export const checkStatus = (response: Response): Promise<Response> => (
  response.ok
    ? Promise.resolve(response)
    : Promise.reject(new Error(`${response.status} ${response.statusText}`))
);

// dev system needs an explicit credentials include for cookie based auth
export const fetchOptions = (getEnvironment() === 'development') ? { credentials: 'include' as RequestCredentials } : {};

export const BASE_URL = `${import.meta.env.VITE_SERENITY_REST_URL}`;
export const INSIGHTS_BASE_URL = `${import.meta.env.VITE_INSIGHTS_REST_URL}`;

const fetchApi = (baseUrl: string) => async <TBody>(method: string, path: string, body: TBody | null, authenticated = true): Promise<Response> => {
  const headers = new Headers({ 'X-TPC-Version': import.meta.env.PACKAGE_VERSION });

  if (authenticated) {
    const token = await getRefreshedToken();
    if (!token) throw new Error('Credentials are invalid');
    headers.set('Authorization', `Bearer ${token}`);
  }

  if (body) {
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
  }

  const response = await fetch(`${baseUrl}${path}`, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers,
  } as RequestInit);

  if (response.status === 401) {
    await logout();
  }

  return response;
};

export const serenityFetch = fetchApi(BASE_URL);
export const insightsFetch = fetchApi(INSIGHTS_BASE_URL);

export class HttpResponseError extends Error {
  response: Response;
  constructor(response: Response) {
    super(`${response.status}: ${response.statusText}`);
    this.response = response;
    Object.setPrototypeOf(this, HttpResponseError.prototype);
  }

  static detect(response: Response, accept404 = false): undefined | Response {
    if (accept404 && response.status === 404) {
      return undefined;
    }
    if (!response.ok) throw new HttpResponseError(response);
    return response;
  }
}
