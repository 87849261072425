export const goToTrackingSuspendedHelpArticle = () => {
  window.open('https://www.tracplus.com/knowledge/rockair-overview', '_blank');
};
export const goToFirmwareVersionOutdatedHelpArticle = (model: string) => {
  if (model.startsWith('RockSTAR')) {
    window.open('https://www.tracplus.com/knowledge/updating-firmware-on-a-rockstar', '_blank');
  } else if (model.startsWith('RockFLEET')) {
    window.open('https://www.tracplus.com/knowledge/updating-firmware-on-rockfleet', '_blank');
  } else if (model.startsWith('RockAIR') || model.startsWith('RockDASH')) {
    window.open('https://www.tracplus.com/knowledge/updating-firmware-on-a-rockair', '_blank');
  }
};
