import type {
  DrawCustomMode,
  DrawCustomModeThis,
  DrawFeature,
  MapMouseEvent,
  MapTouchEvent,
} from '@mapbox/mapbox-gl-draw';
import Draw from '@mapbox/mapbox-gl-draw';
import bearing from '@turf/bearing';
import distance from '@turf/distance';
import type { Feature, GeoJSON } from 'geojson';
import { normaliseBearing } from 'helpers/geo';
import {
  type SearchPattern,
  type SearchPatternType,
  isCreepingLine,
  isExpandingBox,
  isParallelTrack,
  isRangeBearingLine,
} from 'helpers/searchPatterns';
import type { LngLat } from 'mapbox-gl';

export enum Mode {
  Select = 0,
  Rotate = 1,
  Scale = 2,
  RblScaleRotate = 3,
  RblExtendArc = 4,
  RblExtendRange = 5,
}

export enum Handles {
  None = 0,
  All = 1,
  Opposite = 2,
}

interface LngLatLike {
  lng: number;
  lat: number;
}

export const Events = {
  SELECT: 'sp.select',
  UPDATE: 'sp.update',
};

export interface SelectEvent {
  searchPatternId: string | undefined;
}

export interface SearchPatternsDrawModeOptions {
  featureId: string | undefined;
  isLocked: boolean;
  canInteract: boolean;
}

interface SearchPatternsDrawModeState {
  featureId: string | undefined;
  feature: DrawFeature | null;

  mode: Mode;
  handles: Handles;

  canInteract: boolean;
  dragMoving: boolean;
  canDragMove: boolean;
  dragMoveLocation: LngLat | null;
  dragFeature: DrawFeature | null;
}

const DEFAULT_STATE: SearchPatternsDrawModeState = {
  featureId: undefined,
  feature: null,

  mode: Mode.Select,
  handles: Handles.None,

  canInteract: false,
  dragMoving: false,
  canDragMove: false,
  dragMoveLocation: null,
  dragFeature: null,
};

// @ts-ignore
const SearchPatternsDrawMode: DrawCustomMode<SearchPatternsDrawModeState, SearchPatternsDrawModeOptions> = {};

const getHandleMode = (type: SearchPatternType) => {
  switch (type) {
    case 'parallelTrack':
    case 'creepingLine':
      return Handles.Opposite;
    case 'expandingBox':
      return Handles.All;
    case 'sectorSearch':
    case 'rangeBearingLine':
      return Handles.None;
    default:
      throw new Error('unsupported search pattern');
  }
};
const createHandles = (geojson: Feature) => {
  if (!geojson.properties) throw new Error('invalid geojson');

  const firstAxis: 'x' | 'y' = geojson.properties.user_firstAxis;
  const secondAxis = firstAxis === 'x' ? 'y' : 'x';

  const suppPoints = Draw.lib.createSupplementaryPoints(geojson, { midpoints: true });
  suppPoints[1].properties!.axis = firstAxis;
  suppPoints[3].properties!.axis = secondAxis;
  suppPoints[5].properties!.axis = firstAxis;
  suppPoints[7].properties!.axis = secondAxis;

  const rotationOffset: number = geojson.properties.user_rotationOffset;
  const offset = firstAxis === 'x' ? rotationOffset : rotationOffset - 90;
  suppPoints[1].properties!.orientation = normaliseBearing(-90 + offset);
  suppPoints[3].properties!.orientation = normaliseBearing(offset);
  suppPoints[5].properties!.orientation = normaliseBearing(90 + offset);
  suppPoints[7].properties!.orientation = normaliseBearing(180 + offset);

  const handleBearings: number[][] = geojson.properties.user_handleBearings;
  suppPoints[0].properties!.rotationOffset = handleBearings[0];
  suppPoints[2].properties!.rotationOffset = handleBearings[1];
  suppPoints[4].properties!.rotationOffset = handleBearings[2];
  suppPoints[6].properties!.rotationOffset = handleBearings[3];

  return suppPoints;
};

const fireUpdate = (self: DrawCustomModeThis) => {
  self.map.fire(Draw.constants.events.UPDATE, {
    action: Draw.constants.updateActions.CHANGE_COORDINATES,
    features: self.getSelected().map(f => f.toGeoJSON()),
  });
};

const startDragging = (
  self: DrawCustomModeThis,
  state: SearchPatternsDrawModeState,
  location: LngLat,
  feature: DrawFeature,
) => {
  if (state.feature?.properties?.searchPattern?.isLocked || !state.canInteract) return;
  self.map.dragPan.disable();
  state.canDragMove = true;
  state.dragMoveLocation = location;
  state.dragFeature = feature;
};
const stopDragging = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState) => {
  if (state.feature?.properties?.searchPattern?.isLocked || !state.canInteract) return;
  if (state.dragMoving) {
    fireUpdate(self);
  }

  self.map.dragPan.enable();
  state.dragMoving = false;
  state.canDragMove = false;
  state.dragMoveLocation = null;
  state.dragFeature = null;
  state.mode = Mode.Select;
};

const onMidpoint = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent | MapTouchEvent) => {
  if (!e.featureTarget || !state.feature) return;
  startDragging(self, state, e.lngLat, e.featureTarget);
  state.mode = Mode.Scale;
};
const onVertex = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent | MapTouchEvent) => {
  if (!e.featureTarget) return;
  startDragging(self, state, e.lngLat, e.featureTarget);
  state.mode = Mode.Rotate;
};
const onFeature = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent | MapTouchEvent) => {
  startDragging(self, state, e.lngLat, e.featureTarget);
};
const onRblPoint = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent | MapTouchEvent) => {
  startDragging(self, state, e.lngLat, e.featureTarget);
  state.mode = Mode.RblScaleRotate;
};
const onRblHandleArc = (
  self: DrawCustomModeThis,
  state: SearchPatternsDrawModeState,
  e: MapMouseEvent | MapTouchEvent,
) => {
  startDragging(self, state, e.lngLat, e.featureTarget);
  state.mode = Mode.RblExtendArc;
};
const onRblHandleRange = (
  self: DrawCustomModeThis,
  state: SearchPatternsDrawModeState,
  e: MapMouseEvent | MapTouchEvent,
) => {
  startDragging(self, state, e.lngLat, e.featureTarget);
  state.mode = Mode.RblExtendRange;
};

const isOfUserMeta = (meta: string) => (e: MapMouseEvent | MapTouchEvent) => {
  if (!e.featureTarget?.properties) return false;
  return 'user_meta' in e.featureTarget.properties && e.featureTarget.properties.user_meta === meta;
};

const dragRotatePoint = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  const sp: SearchPattern | undefined = state.feature?.properties?.searchPattern;
  if (!sp) throw new Error('no search pattern on feature');

  let orientation = 0;
  if (sp.origin && state.dragFeature !== null) {
    const s = [sp.origin.lng, sp.origin.lat];
    const mousePosition = [e.lngLat.lng, e.lngLat.lat];
    const mouseBearing = bearing(s, mousePosition);

    const rotationOffset = state.dragFeature.properties?.rotationOffset ?? 0;

    orientation = mouseBearing - rotationOffset;
  }

  if (orientation > 180) orientation -= 360;
  if (orientation < -180) orientation += 360;

  self.map.fire(Events.UPDATE, { orientation } satisfies Partial<SearchPattern>);
};
const dragScalePoint = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (
    !state.dragFeature?.properties ||
    !('axis' in state.dragFeature.properties) ||
    !('orientation' in state.dragFeature.properties) ||
    !state.dragMoveLocation
  )
    throw new Error('invalid state');
  const sp: SearchPattern | undefined = state.feature?.properties?.searchPattern;
  if (!sp) throw new Error('no search pattern on feature');

  const { axis, orientation } = state.dragFeature.properties;

  const p1 = [state.dragMoveLocation.lng, state.dragMoveLocation.lat];
  const p2 = [e.lngLat.lng, e.lngLat.lat];
  const b = bearing(p1, p2) + orientation - sp.orientation;
  const t = Math.cos(b * (Math.PI / 180));
  const dist = distance(p1, p2, { units: 'meters' });
  const deltaMetres = dist * t;

  if (isParallelTrack(sp) || isCreepingLine(sp)) {
    if (axis === 'x') {
      self.map.fire(Events.UPDATE, {
        legCount: sp.legCount + deltaMetres / sp.trackSpacingMetres,
      });
    } else {
      self.map.fire(Events.UPDATE, {
        legLengthMetres: Math.round(sp.legLengthMetres + deltaMetres),
      });
    }
  } else if (isExpandingBox(sp)) {
    self.map.fire(Events.UPDATE, {
      legCount: sp.legCount + deltaMetres / sp.trackSpacingMetres,
    });
  }
};
const dragFeature = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  const delta = {
    lng: state.dragMoveLocation ? e.lngLat.lng - state.dragMoveLocation.lng : 0,
    lat: state.dragMoveLocation ? e.lngLat.lat - state.dragMoveLocation.lat : 0,
  };

  const origin: LngLatLike | undefined = state.feature?.properties?.searchPattern?.origin;
  if (!origin) return;

  self.map.fire(Events.UPDATE, {
    origin: {
      lng: origin.lng + delta.lng,
      lat: origin.lat + delta.lat,
    },
  } satisfies Partial<SearchPattern>);
};
const dragRblPoint = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (!state.dragMoveLocation) throw new Error('invalid state');
  const sp: SearchPattern | undefined = state.feature?.properties?.searchPattern;
  if (!sp || !isRangeBearingLine(sp)) throw new Error('no search pattern on feature');

  const origin = [sp.origin.lng, sp.origin.lat];
  const mouse = [e.lngLat.lng, e.lngLat.lat];
  const orientation = bearing(origin, mouse);
  const legLengthMetres = distance(origin, mouse, { units: 'meters' });
  self.map.fire(Events.UPDATE, {
    orientation,
    legLengthMetres,
  });
};
const dragRblHandleArc = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (!state.dragMoveLocation) throw new Error('invalid state');
  const sp: SearchPattern | undefined = state.feature?.properties?.searchPattern;
  if (!sp || !isRangeBearingLine(sp)) throw new Error('no search pattern on feature');

  const origin = [sp.origin.lng, sp.origin.lat];
  const mousePos = [e.lngLat.lng, e.lngLat.lat];
  let bearingToMouse = bearing(origin, mousePos) - sp.orientation;
  if (bearingToMouse < -180) bearingToMouse += 360;
  const orientationArc = Math.max(0, Math.min(90, bearingToMouse));
  self.map.fire(Events.UPDATE, { orientationArc });
};
const dragRblHandleRange = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (!state.dragMoveLocation) throw new Error('invalid state');
  const sp: SearchPattern | undefined = state.feature?.properties?.searchPattern;
  if (!sp || !isRangeBearingLine(sp)) throw new Error('no search pattern on feature');

  const handlePos = [state.dragMoveLocation.lng, state.dragMoveLocation.lat];
  const mousePos = [e.lngLat.lng, e.lngLat.lat];
  const bearingHandleToMouse = bearing(handlePos, mousePos) - sp.orientation;
  const t = Math.cos(bearingHandleToMouse * (Math.PI / 180));
  const dist = distance(handlePos, mousePos, { units: 'meters' });
  const legLengthRangeMetres = Math.max(0, dist * t + sp.legLengthRangeMetres);
  self.map.fire(Events.UPDATE, { legLengthRangeMetres });
};

const clickNoTarget = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (!state.canInteract) return;
  self.map.fire(Events.SELECT, {
    searchPatternId: undefined,
  } satisfies SelectEvent);
};
const clickActiveFeature = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (!state.canInteract) return;
  self.map.fire(Events.SELECT, {
    searchPatternId: undefined,
  } satisfies SelectEvent);
};
const clickInactiveFeature = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (!state.canInteract) return;
  const props = e.featureTarget?.properties;
  if (!props) return;

  self.map.fire(Events.SELECT, {
    searchPatternId: props.user_searchPatternId,
  } satisfies SelectEvent);
};

SearchPatternsDrawMode.onSetup = function (options: SearchPatternsDrawModeOptions): SearchPatternsDrawModeState {
  if (!options.featureId) {
    this.setSelected(undefined);
    return { ...DEFAULT_STATE, ...options };
  }

  const featureId = options.featureId.toString();
  const feature = this.getFeature(featureId);
  if (!feature.properties || !('searchPattern' in feature.properties)) {
    throw new Error('expected feature to have search pattern props');
  }

  const state = {
    ...DEFAULT_STATE,
    ...options,
    featureId,
    feature,
    handles: getHandleMode(feature.properties.searchPattern.type),
  };

  this.setSelected(featureId);
  this.map.doubleClickZoom.disable();

  this.setActionableState({
    combineFeatures: false,
    uncombineFeatures: false,
    trash: false,
  });

  return state;
};

SearchPatternsDrawMode.toDisplayFeatures = function (
  state: SearchPatternsDrawModeState,
  geojson: GeoJSON,
  display: (geojson: GeoJSON) => void,
): void {
  if (!('properties' in geojson) || !geojson.properties) return;

  const isHidden: boolean | undefined = geojson.properties.user_isHidden;
  if (isHidden) return;

  if (state.featureId && state.featureId === geojson.properties.id) {
    geojson.properties.active = Draw.constants.activeStates.ACTIVE;
    display(geojson);

    const sp = geojson.properties.user_searchPattern;
    if (sp.isLocked) return;

    switch (state.handles) {
      case Handles.All:
        createHandles(geojson).forEach(display);
        break;
      case Handles.Opposite:
        createHandles(geojson).slice(3, 6).forEach(display);
        break;
      case Handles.None:
      default:
        break;
    }
  } else if (state.featureId === geojson.properties.user_searchPatternId) {
    geojson.properties.active = Draw.constants.activeStates.ACTIVE;
    display(geojson);
  } else {
    geojson.properties.active = Draw.constants.activeStates.INACTIVE;
  }

  this.setActionableState({
    combineFeatures: false,
    uncombineFeatures: false,
    trash: false,
  });
};

SearchPatternsDrawMode.onDrag = function (state: SearchPatternsDrawModeState, e: MapMouseEvent): void {
  if (!state.canDragMove) return;
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  switch (state.mode) {
    case Mode.Rotate:
      dragRotatePoint(this, state, e);
      break;
    case Mode.Scale:
      dragScalePoint(this, state, e);
      break;
    case Mode.RblScaleRotate:
      dragRblPoint(this, state, e);
      break;
    case Mode.RblExtendArc:
      dragRblHandleArc(this, state, e);
      break;
    case Mode.RblExtendRange:
      dragRblHandleRange(this, state, e);
      break;
    case Mode.Select:
    default:
      dragFeature(this, state, e);
      break;
  }

  state.dragMoveLocation = e.lngLat;
};

SearchPatternsDrawMode.onClick = function (state: SearchPatternsDrawModeState, e: MapMouseEvent): void {
  if (Draw.lib.CommonSelectors.noTarget(e)) clickNoTarget(this, state, e);
  if (Draw.lib.CommonSelectors.isActiveFeature(e)) clickActiveFeature(this, state, e);
  if (Draw.lib.CommonSelectors.isInactiveFeature(e)) clickInactiveFeature(this, state, e);
  stopDragging(this, state);
};

SearchPatternsDrawMode.onMouseOut = function (state: SearchPatternsDrawModeState): void {
  if (state.dragMoving) {
    fireUpdate(this);
  }
};

const onMouseDown = (
  self: DrawCustomModeThis,
  state: SearchPatternsDrawModeState,
  e: MapMouseEvent | MapTouchEvent,
) => {
  if (Draw.lib.CommonSelectors.isOfMetaType(Draw.constants.meta.VERTEX)(e) || isOfUserMeta('sector_handle')(e))
    onVertex(self, state, e);
  else if (Draw.lib.CommonSelectors.isOfMetaType(Draw.constants.meta.MIDPOINT)(e)) onMidpoint(self, state, e);
  else if (isOfUserMeta('rbl_point')(e)) onRblPoint(self, state, e);
  else if (isOfUserMeta('rbl_handle_arc')(e)) onRblHandleArc(self, state, e);
  else if (isOfUserMeta('rbl_handle_range')(e)) onRblHandleRange(self, state, e);
  else if (Draw.lib.CommonSelectors.isActiveFeature(e)) onFeature(self, state, e);
};
SearchPatternsDrawMode.onMouseDown = function (state: SearchPatternsDrawModeState, e: MapMouseEvent): void {
  onMouseDown(this, state, e);
};
SearchPatternsDrawMode.onTouchStart = function (state: SearchPatternsDrawModeState, e: MapTouchEvent): void {
  onMouseDown(this, state, e);
};

SearchPatternsDrawMode.onMouseUp = function (state: SearchPatternsDrawModeState): void {
  stopDragging(this, state);
};
SearchPatternsDrawMode.onTouchEnd = function (state: SearchPatternsDrawModeState): void {
  stopDragging(this, state);
};

SearchPatternsDrawMode.onStop = function (): void {
  this.map.doubleClickZoom.enable();
  this.clearSelectedCoordinates();
};

export default SearchPatternsDrawMode;
