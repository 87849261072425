import { HttpResponseError, serenityFetch } from 'helpers/api';

export const getIceContactGroups = async (organisationId: string): Promise<ContactGroup[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/people/groups/ice`, null);
  HttpResponseError.detect(response);
  return (await response.json()).iceGroups;
};

export interface HealthResult {
  defaultIceGroupExists: boolean;
  defaultIceGroupIsHealthy: boolean;
}
export const getIceContactGroupsHealth = async (organisationId: string): Promise<HealthResult> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/people/groups/ice/health`, null);
  HttpResponseError.detect(response);
  return response.json();
};

export const addIceContactGroup = async (
  organisationId: string,
  body: Pick<ContactGroup, 'name'>,
): Promise<ContactGroup> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/people/groups/ice`, body);
  HttpResponseError.detect(response);
  return (await response.json()).iceGroup;
};

export const updateIceContactGroup = async (
  organisationId: string,
  body: Pick<ContactGroup, 'id' | 'name' | 'isDefault'>,
): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/people/groups/ice/${body.id}`, body);
  HttpResponseError.detect(response);
};

export interface GroupAssignDevicesRequestBody {
  id: number;
  deviceVersion: number;
  deviceIds: number[];
}
export const updateIceContactGroupAssignDevices = async (
  organisationId: string,
  body: GroupAssignDevicesRequestBody,
): Promise<void> => {
  const response = await serenityFetch(
    'POST',
    `/organisations/${organisationId}/people/groups/ice/${body.id}/devices`,
    body,
  );
  HttpResponseError.detect(response);
};

export interface GroupAssignPeopleRequestBody {
  id: number;
  peopleVersion: number;
  peopleForGroup: {
    personId: number;
    order: number;
  }[];
}
export const updateIceContactGroupAssignPeople = async (
  organisationId: string,
  body: GroupAssignPeopleRequestBody,
): Promise<void> => {
  const response = await serenityFetch(
    'POST',
    `/organisations/${organisationId}/people/groups/ice/${body.id}/people`,
    body,
  );
  HttpResponseError.detect(response);
};

export const deleteIceContactGroup = async (organisationId: string, body: Pick<ContactGroup, 'id'>): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/people/groups/ice/${body.id}`, null);
  HttpResponseError.detect(response);
};
