import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { DEFAULT_MAP_LAYOUT, VIEWS_PER_LAYOUT } from 'constants/maplayouts';
import { TRAILS_OPTIONS } from 'constants/trailsoptions';
import { MapBaseLayerIds } from 'constants/map';
import mixpanel from 'mixpanel-browser';
import storage from 'redux-persist/lib/storage';
import { loggedOut, resetEverything, setOrganisationId } from 'slices/session/session.slice';
import { createMigrate, persistReducer } from 'redux-persist';
import { selectLeg } from './app.slice';

/**
 * This slice is for Map and Layer state only.  Map Settings
 * exist in the settings reducer under the 'map' key.
* */

type RGB = [number, number, number];
export type DropsOption = 'showDropTrails' | 'showDropIcons';
export type ContainmentLinesOption = 'none' | 'selectedAsset' | 'allAssets';

export interface MapSettings {
  id: string
  sortOrder: number
  baseLayerId: MapBaseLayerIds
  baseLayerLibrary: string
  animateToSelection: boolean
  animateTrails: boolean
  animateSelectedTrailOnly: boolean
  permanentLabels: boolean
  kmlLabels: boolean
  highlightSelectedObject: boolean
  unselectedItemOpacity: number
  defaultPulseColor: RGB
  followPulseColor: RGB
  assetClustering: boolean
  trailsOption: (typeof TRAILS_OPTIONS)[keyof (typeof TRAILS_OPTIONS)]
  dropsOption: DropsOption
  splineTrails: boolean
  splineTension: number
  splineSegmentCount: number
  trailWidth: number
  trailUnderlineWidth: number
  hideInactiveAssets: boolean
  inactiveSinceHours: number
  reportDots: boolean
  hideReportDotsAtZoom: number
  assetTrailColouring: 'none' | 'speed' | 'altitude' | 'battery' | 'transport' | 'latency',
  windTrails: boolean
  windVelocity: boolean
  adsbEnabled: boolean
  geofencesEnabled: boolean
  threeDEnabled: boolean
  showTrailCurtain: boolean
  markersEnabled: boolean
  containmentLinesOption: ContainmentLinesOption
  currentTrailsOnly: boolean
}

export interface MapState {
  maps: Record<string, MapSettings>
  selectedLegs: Record<string, Leg | null>
  measurementMarkers: Record<string, {
    currentMeasurementMarker: { lat: number, lng: number } | null
    measurementMarkers: Record<string, { lat: number, lng: number }>
  }>
  selectedAssets: Record<string, AssetBasic | null> // TODO: check
  customLayerKml: Record<string, any> // TODO
  selectedMapId: string
  selectedMapLayout: keyof typeof VIEWS_PER_LAYOUT
  isClosable: boolean
  hiddenAssetGroups: string[]
  hiddenAssets: AssetBasic[]
  hiddenInactiveAssets: AssetBasic[]
  trailHighlight: TrailHighlight | null
  assetsAreBeingFollowedOnMaps: Record<string, boolean>,
}

const defaultId = 'default';
const defaultMap: MapSettings = {
  id: defaultId,
  sortOrder: 0,
  baseLayerId: MapBaseLayerIds.MapboxSatelliteStreets,
  baseLayerLibrary: 'reactmapgl',
  animateToSelection: false,
  animateTrails: false,
  animateSelectedTrailOnly: true,
  permanentLabels: false,
  kmlLabels: true,
  highlightSelectedObject: true,
  unselectedItemOpacity: 0.75,
  defaultPulseColor: [0, 0, 0], // default pulse is black
  followPulseColor: [0, 255, 0], // follow pulse is green
  assetClustering: false,
  trailsOption: TRAILS_OPTIONS.allTrailsIcons,
  dropsOption: 'showDropIcons',
  windTrails: false,
  windVelocity: false,
  adsbEnabled: false,

  // To spline, or not to spline... and _how_ to spline...
  splineTrails: true,
  splineTension: 0.5,
  splineSegmentCount: 25,

  trailWidth: 2,
  trailUnderlineWidth: 4,
  hideInactiveAssets: false,
  inactiveSinceHours: 1,
  reportDots: true,
  hideReportDotsAtZoom: 7.5,
  assetTrailColouring: 'none',
  geofencesEnabled: false,
  threeDEnabled: false,
  showTrailCurtain: false,
  markersEnabled: true,
  containmentLinesOption: 'none',
  currentTrailsOnly: false,
};

const initialState: Readonly<MapState> = {
  maps: { default: defaultMap },
  selectedLegs: { default: null },
  measurementMarkers: { default: { currentMeasurementMarker: null, measurementMarkers: {} } },
  selectedAssets: { default: null },
  customLayerKml: { default: null },
  selectedMapId: defaultId,
  selectedMapLayout: DEFAULT_MAP_LAYOUT,
  isClosable: false,
  hiddenAssetGroups: [],
  hiddenAssets: [],
  hiddenInactiveAssets: [],
  trailHighlight: null,
  assetsAreBeingFollowedOnMaps: { default: false },
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setAssetFollow: (state, action: PayloadAction<{ mapId: string, isFollowed: boolean }>) => {
      state.assetsAreBeingFollowedOnMaps[action.payload.mapId] = action.payload.isFollowed;
    },
    closeMap: (state, action: PayloadAction<string>) => {
      const currentLayoutCount = VIEWS_PER_LAYOUT[state.selectedMapLayout];
      if (currentLayoutCount < 2) return; // Can't close last map

      const newLayoutCount = currentLayoutCount - 1;
      const newMapLayout = (Object.keys(VIEWS_PER_LAYOUT) as (keyof typeof VIEWS_PER_LAYOUT)[]).find(key => VIEWS_PER_LAYOUT[key] === newLayoutCount);

      const remainingMaps = Object.values(state.maps)
        .filter(m => m.id !== action.payload)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .reduce<Record<MapSettings['id'], MapSettings>>((acc, m, i) => {
          acc[m.id] = { ...m, sortOrder: i };
          return acc;
        }, {});

      state.selectedMapLayout = newMapLayout!;
      state.selectedMapId = defaultId;
      state.maps = remainingMaps;
      state.isClosable = newLayoutCount > 1;
    },
    updateMapConfig: (state, action: PayloadAction<{ mapId: string, config: Partial<MapSettings> }>) => {
      const config = state.maps[action.payload.mapId];
      if (!config) return;
      state.maps[action.payload.mapId] = { ...config, ...action.payload.config };
    },
    setBaseLayer: (state, action: PayloadAction<{ mapId: string, baseLayer: { id: MapBaseLayerIds, name: string, library: string } }>) => {
      mixpanel.track('Set map base layer', { baseLayerName: action.payload.baseLayer.name });
      const map = state.maps[action.payload.mapId];
      if (!map) return;
      map.baseLayerId = action.payload.baseLayer.id;
      map.baseLayerLibrary = action.payload.baseLayer.library;
    },
    updateMapLayout: (state, action: PayloadAction<{ layout: keyof typeof VIEWS_PER_LAYOUT, defaultMapSettings: MapSettings, callback?: (mapIds: string[]) => void }>) => {
      const { layout, defaultMapSettings, callback } = action.payload;
      const currentLayoutCount = VIEWS_PER_LAYOUT[state.selectedMapLayout];
      const newLayoutCount = VIEWS_PER_LAYOUT[layout];

      for (let i = Object.keys(state.maps).length; i < newLayoutCount; i++) {
        const newMapConfigId = uuid();
        state.maps[newMapConfigId] = {
          ...defaultMapSettings,
          id: newMapConfigId,
          sortOrder: i,
          baseLayerId: MapBaseLayerIds.MapboxSatelliteStreets,
          baseLayerLibrary: 'reactmapgl',
        };
        state.measurementMarkers[newMapConfigId] = { currentMeasurementMarker: null, measurementMarkers: {} };
        state.selectedAssets[newMapConfigId] = state.selectedAssets.default ? { ...state.selectedAssets.default } : null;
        state.selectedLegs[newMapConfigId] = state.selectedLegs.default ? { ...state.selectedLegs.default } : null;
      }

      callback?.(Object.keys(state.maps));

      const resetMapSelection = (newLayoutCount < currentLayoutCount) && ((state.maps[state.selectedMapId].sortOrder + 1) > newLayoutCount);

      if (newLayoutCount < currentLayoutCount) {

        //remove each asset over the new layout count from url params
        const currentSearchParams = new URLSearchParams(window.location.search);
        for (let i = newLayoutCount; i < currentLayoutCount; i++) {
          const paramName = i === 0 ? 'asset' : `asset${i}`;
          currentSearchParams.delete(paramName);
        }
        window.history.replaceState({}, '', `${window.location.pathname}?${currentSearchParams.toString()}`);

        // Remove maps that are no longer needed
        const remainingMaps = Object.entries(state.maps).reduce((acc, [mapId, map]) => {
          if (map.sortOrder < newLayoutCount) {
            acc[mapId] = map;
          } else {
            // Clean up associated state
            delete state.measurementMarkers[mapId];
            delete state.selectedAssets[mapId];
            delete state.selectedLegs[mapId];
            delete state.assetsAreBeingFollowedOnMaps[mapId];
          }
          return acc;
        }, {} as typeof state.maps);
        state.maps = remainingMaps;

        // Remove assets from URL params for maps being closed
        const searchParams = new URLSearchParams(window.location.search);
        Object.values(state.maps).forEach(map => {
          if (map.sortOrder >= newLayoutCount) {
            const paramName = map.sortOrder === 0 ? 'asset' : `asset${map.sortOrder}`;
            searchParams.delete(paramName);
          }
        });
        searchParams.sort();
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
      } else if (newLayoutCount > currentLayoutCount) {
        // Add assets to URL params for new maps
        const searchParams = new URLSearchParams(window.location.search);
        Object.values(state.maps).forEach(map => {
          if (map.sortOrder < newLayoutCount) {
            const paramName = map.sortOrder === 0 ? 'asset' : `asset${map.sortOrder}`;
            if (state.selectedAssets[map.id]?.id) {
              searchParams.set(paramName, state.selectedAssets[map.id]?.id.toString() ?? '');
            }
          }
        });
        searchParams.sort();
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
      }

      state.selectedMapLayout = layout;
      state.selectedMapId = resetMapSelection ? defaultId : state.selectedMapId;
      state.isClosable = newLayoutCount > 1;
    },
    selectMap: (state, action: PayloadAction<string>) => {
      state.selectedMapId = action.payload;
    },
    assignItemToMap: (state, action: PayloadAction<{ mapId: string, item: AssetBasic | null }>) => {
      const { mapId, item } = action.payload;

      if (state.measurementMarkers[mapId]) {
        state.measurementMarkers[mapId].currentMeasurementMarker = item && state.measurementMarkers[mapId].measurementMarkers ? {
          lat: state.measurementMarkers[mapId].measurementMarkers[item.id]?.lat,
          lng: state.measurementMarkers[mapId].measurementMarkers[item.id]?.lng
        } : null;
      }

      state.selectedAssets[mapId] = item;
      state.selectedLegs[mapId] = null;
      state.assetsAreBeingFollowedOnMaps[mapId] = false;
    },
    unassignItemFromMap: state => {
      if (!state.selectedMapId) return;
      state.selectedAssets[state.selectedMapId] = null;
      state.selectedLegs[state.selectedMapId] = null;
    },
    assignMarkerToAsset: (state, action: PayloadAction<{ mapId: string, assetId: string, lat: number, lng: number }>) => {
      const { mapId, assetId, lat, lng } = action.payload;
      const mapMarkers = state.measurementMarkers[mapId];
      if (mapMarkers) {
        mapMarkers.measurementMarkers[assetId] = { lat, lng };
        mapMarkers.currentMeasurementMarker = { lat, lng };
      }
    },
    setTrailsOption: (state, action: PayloadAction<{ mapId: string, trailsOption: (typeof TRAILS_OPTIONS)[keyof (typeof TRAILS_OPTIONS)] }>) => {
      const { mapId, trailsOption } = action.payload;
      if (!mapId) return;
      state.maps[mapId].trailsOption = trailsOption;
    },
    hideAssetsGroup: (state, action: PayloadAction<string>) => {
      state.hiddenAssetGroups.push(action.payload);
      state.hiddenAssetGroups = [...new Set(state.hiddenAssetGroups)];
    },
    hideAssetGroups: (state, action: PayloadAction<string[]>) => {
      state.hiddenAssetGroups = [...new Set([...state.hiddenAssetGroups, ...action.payload])];
    },
    removeFromHiddenAssetGroups: (state, action: PayloadAction<string>) => {
      state.hiddenAssetGroups = state.hiddenAssetGroups.filter(group => group !== action.payload);
    },
    hideAssetsOnMap: (state, action: PayloadAction<AssetBasic[]>) => {
      state.hiddenAssets = [...new Set([...state.hiddenAssets, ...action.payload])];
    },
    showAssetsOnMap: (state, action: PayloadAction<AssetBasic[]>) => {
      const assetsToShow = action.payload.map(a => a.id);
      state.hiddenAssets = state.hiddenAssets.filter(a => !assetsToShow.includes(a.id));
    },
    showAllAssetsOnMap: state => {
      state.hiddenAssetGroups = [];
      state.hiddenAssets = [];
    },
    updateHiddenInactiveAssets: (state, action: PayloadAction<AssetBasic[]>) => {
      state.hiddenInactiveAssets = action.payload;
    },
    setTrailHighlight: (state, action: PayloadAction<{ highlightTrail: TrailHighlight | null }>) => {
      state.trailHighlight = action.payload.highlightTrail;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(setOrganisationId, state => {
        Object.keys(state.selectedAssets).forEach(mapId => {
          state.selectedAssets[mapId] = null;
          state.selectedLegs[mapId] = null;
        });

        // Clear query params related to assets
        const url = new URL(window.location.href);
        ['asset', 'asset1', 'asset2', 'asset3'].forEach(param => {
          url.searchParams.delete(param);
        });
        window.history.replaceState({}, '', url);
      })
      .addCase(loggedOut, () => {
        const url = new URL(window.location.href);
        ['asset', 'asset1', 'asset2', 'asset3'].forEach(param => {
          url.searchParams.delete(param);
        });
        window.history.replaceState({}, '', url);
        return initialState;
      })
      .addCase(resetEverything, () => {
        const url = new URL(window.location.href);
        ['asset', 'asset1', 'asset2', 'asset3'].forEach(param => {
          url.searchParams.delete(param);
        });
        window.history.replaceState({}, '', url);
        return initialState;
      })
      .addCase(selectLeg, (state, action: PayloadAction<{ leg: Leg | null }>) => {
        if (state.selectedMapId) {
          state.selectedLegs[state.selectedMapId] = action.payload.leg;
        }
      });
  },
  selectors: {
    getSelectedLeg: (state: MapState) => state.selectedLegs[state.selectedMapId],
    getSelectedMapId: (state: MapState) => state.selectedMapId,
    getMaps: (state: MapState) => state.maps,
  },
});

export const getAllMapIds = createSelector(mapSlice.selectors.getMaps, (maps) => Object.keys(maps));

export const {
  setAssetFollow,
  closeMap,
  updateMapConfig,
  setBaseLayer,
  updateMapLayout,
  selectMap,
  assignItemToMap,
  unassignItemFromMap,
  assignMarkerToAsset,
  setTrailsOption,
  hideAssetsGroup,
  hideAssetGroups,
  removeFromHiddenAssetGroups,
  hideAssetsOnMap,
  showAssetsOnMap,
  showAllAssetsOnMap,
  updateHiddenInactiveAssets,
  setTrailHighlight,
} = mapSlice.actions;

export const { getSelectedLeg, getSelectedMapId } = mapSlice.selectors;

export const mapMigrations: Record<number, (state: any) => any> = {
  5: () => initialState,
  6: (state: any) => ({
    ...state,
    maps: Object.keys(state.maps).reduce((acc, mapId) => ({
      ...acc,
      [mapId]: {
        ...state.maps[mapId],
        lastNumberOfDaysReported: 1
      }
    }), {})
  }),
  7: (state: any) => ({
    ...state,
    assetsAreBeingFollowedOnMaps: { default: false }
  }),
  8: (state: any) => ({
    ...state,
    maps: Object.keys(state.maps).reduce((acc, mapId) => ({
      ...acc,
      [mapId]: {
        ...state.maps[mapId],
        assetClustering: false,
      }
    }), {})
  }),
  9: (state: any) => ({
    ...state,
    maps: Object.keys(state.maps).reduce((acc, mapId) => ({
      ...acc,
      [mapId]: {
        ...state.maps[mapId],
        reportDots: true,
        hideReportDotsAtZoom: 7.5,
      }
    }), {})
  }),
  10: (state: any) => ({
    ...state,
    selectedLegs: Object.keys(state.selectedLegs).reduce((acc, mapId) => ({
      ...acc,
      [mapId]: null
    }), {})
  }),
  11: (state: any) => ({
    ...state,
    maps: Object.keys(state.maps).reduce<Record<string, MapSettings>>((acc, mapId) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { layers, ...map } = state.maps[mapId];
      acc[mapId] = map;
      return acc;
    }, {}),
  }),
  12: (state: any) => ({
    ...state,
    assetTrailColouring: state.assetTrailColouring ?? 'none',
  }),
  13: (state: any) => state,
  14: (state: any) => {
    const convert = (id: string) => {
      if (id === 'reactmapgl-here-hybrid') return MapBaseLayerIds.MapboxSatelliteStreets;
      if (id === 'reactmapgl-here-terrain') return MapBaseLayerIds.MapboxOutdoors;
      if (id === 'reactmapgl-here-dark') return MapBaseLayerIds.MapboxDark;
      return id;
    };
    return {
      ...state,
      maps: Object.keys(state.maps).reduce((acc, mapId) => {
        const map = state.maps[mapId];
        return {
          ...acc,
          [mapId]: {
            ...map,
            baseLayerId: convert(map.baseLayerId),
          },
        };
      }, {}),
    };
  },
  15: (state: any) => ({
    ...state,
    maps: Object.keys(state.maps).reduce((acc, mapId) => ({
      ...acc,
      [mapId]: {
        ...state.maps[mapId],
        markersEnabled: true,
      }
    }), {})
  }),
  16: (state: any) => ({
    ...state,
    maps: Object.keys(state.maps).reduce((acc, mapId) => ({
      ...acc,
      [mapId]: {
        ...state.maps[mapId],
        containmentLinesOption: 'none',
      }
    }), {})
  }),
  17: (state: any) => ({
    ...state,
    maps: Object.keys(state.maps).reduce((acc, mapId) => ({
      ...acc,
      [mapId]: {
        ...state.maps[mapId],
        showCurrentTrailsOnly: false,
      }
    }), {})
  }),
};

export default persistReducer({
  key: 'map',
  version: 17,
  whitelist: ['maps', 'viewports', 'selectedMapLayout', 'hiddenAssetGroups', 'hiddenAssets'],
  storage,
  migrate: createMigrate(mapMigrations, { debug: import.meta.env.DEV }),
}, mapSlice.reducer);
