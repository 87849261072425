import { labelToDisplayLabel } from 'helpers/events';
import { capitalizeFirstLetter } from 'helpers/text';
import moment from 'moment';
import { filterMetadata } from 'workers/fetchReports/utils';

/**
 * This function extracts recipients from the package field for a message.
 * I know this his horrific, but it's the best we can do with what trackstar provides.
 * @param p message package field, contains recipient SMS/email address OR a 'User:'
 */
const extractRecipientFromPackage = (p: string): string | undefined => {
  // Only match when the package contains RecipientAddresses
  if (!p.search(/RecipientAddresses/)) return undefined;
  // Extract recipient list from inside array brackets
  const match = p.match(/:\[(.*)];/);
  // Split string of recipients into an array to remove any that are 3 char or fewer (often '+64' shows up as a recipient)
  return match?.length
    ? match[1]
        .split(', ')
        .filter(r => r.length > 3)
        .join(', ')
    : undefined;
};

const messageTimestampToUnix = (value: string, format = 'MM/DD/YYYY HH:mm:ss'): number => {
  const m = moment.utc(value, format);
  if (m.isValid()) return m.unix();
  throw new Error(`The message timestamp '${value}' could not be parsed`);
};

const extractOwnerIdFromPackage = (tsPackage: string): string => {
  if (!tsPackage) return '';
  if (tsPackage.substring(0, 5) === 'User:') {
    // example package to match: "User:demosupport; Capability:CP_SND_TEXT; etc. etc."
    return tsPackage.substring(5)?.split(';')[0];
  }
  return '';
};

const messageRegex = /^(?<sender>.+?): '(\((?<senderUser>.+?)\) )*(?<message>.+)'$/m;

const extractSenderNameFromText = (tsText: string): { name: string | undefined; message: string } => {
  const { sender, senderUser, message } = messageRegex.exec(tsText)?.groups || {
    sender: undefined,
    senderUser: undefined,
    message: undefined,
  };
  return {
    name: sender === 'System' ? 'TracPlus User' : senderUser ? `${senderUser} (${sender})` : sender,
    message: message || tsText.toString(),
  };
};

/**
 * Convert a TracStar API representation of a message into a format
 * more convenient for internal use.
 * @param tsMessage TrackStar API message output.
 * @return A model of message.
 */
export const mapTSMessage = (tsMessage: TSMessage): Message => {
  const { name, message } = extractSenderNameFromText(tsMessage.text);
  return {
    id: tsMessage.id,
    conversationId: `conv-${tsMessage.terminalId}`,
    sender: {
      id: tsMessage.terminalId,
      type: tsMessage.isInbound ? 'asset' : 'user',
      ownerId: extractOwnerIdFromPackage(tsMessage.package) || '', // tsMessage.ownerId is always empty from the SOAP API, pull this value from the package instead
      name,
    },
    package: tsMessage.package,
    recipient: extractRecipientFromPackage(tsMessage.package),
    status: '',
    timestamp: messageTimestampToUnix(tsMessage.time),
    content: message,
    isInbound: tsMessage.isInbound,
  };
};

/**
 * Convert a TracStar API representation of a conversation into format
 * more convenient for internal use.
 *
 * @param tsConversation TrackStar API conversation output.
 * @returns A model of a conversation.
 */
export const mapTSConversation = (tsConversation: TSConversation): Conversation => {
  const { name, message } = extractSenderNameFromText(tsConversation.latestMessage);
  return {
    id: `conv-${tsConversation.terminalId}`,
    realId: `conv-${tsConversation.terminalId}`,
    deviceId: tsConversation.terminalId,
    latestMessage: {
      id: tsConversation.latestId.toString(),
      conversationId: `conv-${tsConversation.terminalId}`,
      sender: {
        id: tsConversation.terminalId.toString(), // TODO: this might not actually be the sender?
        type: 'asset', // TODO: might not actually be an asset
        ownerId: tsConversation.ownerId,
        name,
      },
      status: 'NOT IMPLEMENTED',
      timestamp: messageTimestampToUnix(tsConversation.latestTimestamp),
      content: message,
      isInbound: tsConversation.isLatestInbound,
    },
    participants: [
      {
        id: tsConversation.terminalId.toString(), // TODO: if we make this a list of all participants, this will be incorrect
        type: 'asset', // TODO: might not actually be an asset
        ownerId: tsConversation.ownerId,
        ownerName: tsConversation.ownerName,
        deviceId: tsConversation.terminalId,
        tpSerial: tsConversation.tpSerial.toString(),
        manufacturerSerial: tsConversation.manufacturerSerial.toString(),
        imei: tsConversation.imei.toString(),
        isStaff: false, // TODO: set this properly when we can get it from trackstar/firebase
      },
    ],
    readCursor: 'NOT IMPLEMENTED',
  };
};

export const mapTrackstarReportToTPCReport = (r: TSReport): U1Report => ({
  id: r.id,
  time: r.receiveddt,
  transmitted: r.transmitteddt,
  logged: r.loggeddt,
  position: {
    timeOfFix: r.receiveddt,
    latitude: Number.parseFloat(r.latitude),
    longitude: Number.parseFloat(r.longitude),
    altitude: r.height,
    course: r.course,
    speed: r.speed,
    event:
      r.evtype === 'EVT_STANDARD' || r.evtype === 'EVT_SCHEDULED' || r.evtype === 'EVT_INFLIGHT'
        ? undefined
        : ({
            id: r.evtype,
            label: labelToDisplayLabel(r.evtype),
          } as Event),
    accuracy: {
      isValid:
        r.isValid !== false &&
        Number.parseFloat(r.latitude) > -90 &&
        Number.parseFloat(r.longitude) < 180 &&
        Number.parseFloat(r.latitude) < 90 &&
        Number.parseFloat(r.longitude) > -180,
      isDerived: r.isderived,
      pdop: r.dop,
      quality: r.fixQuality,
    },
  },
  event:
    r.evtype === 'EVT_STANDARD' || r.evtype === 'EVT_SCHEDULED' || r.evtype === 'EVT_INFLIGHT'
      ? undefined
      : ({
          id: r.evtype,
          label: labelToDisplayLabel(r.evtype),
        } as Event),
  metadata: r.package ? filterMetadata(r.package) : undefined,
  deviceId: r.terminalId,
  gateway: capitalizeFirstLetter(r.gateway.split('.')[0]),
});

/**
 * A permission to perform specific operations on a shared asset.
 */
interface sharePermissionsType {
  [key: string]: string;
}
export const sharePermissions: sharePermissionsType = {
  v: 'View Live Trails',
  r: 'View Historical Trails',
  f: 'View Forms',
  // m: 'View Media', // permission to view photos from 'Context Camera' app which we used to integrate with
  c: 'Configure Device',
  s: 'Send and View Text Messages',
  // p: 'Poll Current Position', // permission to send MT message to request current position on-demand, not widely supported (NAL, Satamatics)
};

export const categories: Category[] = [
  {
    id: 'Person',
    label: 'Person',
    image: 'person',
    group: 'Land',
  },
  {
    id: 'Worker',
    label: 'Worker',
    image: 'person',
    group: 'Land',
  },
  {
    id: 'Executive Man',
    label: 'Executive Man',
    image: 'person',
    group: 'Land',
  },
  {
    id: 'Executive Woman',
    label: 'Executive Woman',
    image: 'person',
    group: 'Land',
  },
  {
    id: 'Bicycle',
    label: 'Bicycle',
    image: 'bicycle',
    group: 'Land',
  },
  {
    id: 'Truck',
    label: 'Truck',
    image: 'truck',
    group: 'Land',
  },
  {
    id: 'Bus',
    label: 'Bus',
    image: 'bus',
    group: 'Land',
  },
  {
    id: 'Ambulance',
    label: 'Ambulance',
    image: 'ambulance',
    group: 'Land',
  },
  {
    id: 'Car',
    label: 'Car',
    image: 'car',
    group: 'Land',
  },
  {
    id: 'Fire Truck',
    label: 'Fire Truck',
    image: 'firetruck',
    group: 'Land',
  },
  {
    id: 'Police Car',
    label: 'Police Car',
    image: 'policecar',
    group: 'Land',
  },
  {
    id: 'Minibus',
    label: 'Minibus',
    image: 'bus',
    group: 'Land',
  },
  {
    id: 'Mobile Home',
    label: 'Mobile Home',
    image: 'bus',
    group: 'Land',
  },
  {
    id: 'Motorcycle',
    label: 'Motorcycle',
    image: 'motorcycle',
    group: 'Land',
  },
  {
    id: 'Tractor',
    label: 'Tractor',
    image: 'tractor',
    group: 'Land',
  },
  {
    id: 'Yacht',
    label: 'Yacht',
    image: 'yacht',
    group: 'Sea',
  },
  {
    id: 'Tug',
    label: 'Tug',
    image: 'tug',
    group: 'Sea',
  },
  {
    id: 'Container Ship',
    label: 'Container Ship',
    image: 'containership',
    group: 'Sea',
  },
  {
    id: 'Powerboat',
    label: 'Powerboat',
    image: 'powerboat',
    group: 'Sea',
  },
  {
    id: 'Container',
    label: 'Container',
    image: 'container',
    group: 'Other',
  },
  {
    id: 'Aircraft',
    label: 'Aircraft',
    image: 'aircraft',
    group: 'Air',
  },
  {
    id: 'Airship',
    label: 'Airship',
    image: 'airship',
    group: 'Air',
  },
  {
    id: 'Helicopter',
    label: 'Helicopter',
    image: 'helicopter',
    group: 'Air',
  },
  {
    id: 'Drone',
    label: 'Drone',
    image: 'drone',
    group: 'Air',
  },
  {
    id: 'Balloon',
    label: 'Balloon',
    image: 'balloon',
    group: 'Air',
  },
  {
    id: 'Rocket',
    label: 'Rocket',
    image: 'rocket',
    group: 'Air',
  },
  {
    id: 'Unknown',
    label: 'Unknown',
    image: 'unknown',
    group: 'Other',
  },
  {
    id: 'System',
    label: 'System',
    image: 'unknown',
    group: 'Other',
  },
];

interface VisualKey {
  id: string;
  attribution?: string;
}

export const visualKey = (category: string | undefined, model: string | undefined): VisualKey => {
  switch (model?.toString().trim()) {
    case 'AT-802':
    case '802':
    case 'AT802':
    case 'AT 802':
    case 'AT-802A':
    case 'AT802A':
    case 'AT8T':
    case 'AT802 TestStand':
    case '802A':
    case 'AT-602':
    case 'AT602':
    case 'AT-502':
    case 'AT-504':
    case 'AT 504':
    case 'Air Tractor AT-502':
    case 'AT502':
    case 'AT-502A':
    case 'AT-502B':
    case 'AT 502B':
    case '502':
    case '502B':
    case '502 XP':
    case 'AT-400':
    case 'AT-402':
    case 'AT402':
    case 'AT-402B':
    case 'AT 402B':
    case '402b':
    case '402':
    case 'AT301':
    case 'AT-302':
      return { id: 'at802', attribution: 'Daniel VA, CC BY-SA 4.0, via Wikimedia Commons' };
    case 'AT-802 Fire Boss':
    case 'FireBoss AT-802-67F':
    case 'FireBoss AT-802':
    case 'AT802A- FIREBOSS':
    case 'AT-802F':
    case 'AT-802AF':
      return { id: 'at802f', attribution: 'Bidgee, CC BY-SA 3.0 AU, via Wikimedia Commons' };
    case 'B200 Super King Air':
    case 'Beech 200 Super King Air':
    case 'B350 Super King Air':
    case 'Super King Air B350':
    case 'B300 Super King Air':
    case 'Kingair 200c':
    case '200 King Air 250':
    case 'King Air 200':
    case 'BE20 King Air':
    case 'C90 King Air':
    case 'B90 King Air':
    case 'Beechcraft':
    case 'B350':
    case 'C90':
    case '200':
    case 'BE200':
    case 'B300C':
      return { id: 'b200', attribution: 'Rafael Luiz Canossa, CC BY-SA 2.0, via Wikimedia Commons' };
    case '747':
    case 'B741':
    case 'B742':
    case 'B743':
    case 'B744':
    case 'B747':
    case 'B74R':
    case 'B74S':
    case 'B74D':
    case 'B748':
      return { id: 'b747', attribution: 'ShacharLA, CC BY-SA 3.0, via Wikimedia Commons' };
    case 'OV-10 Bronco':
    case 'OV10':
    case 'Bronco':
      return { id: 'bronco', attribution: 'Falcon® Photography, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'L-100-30':
    case 'C-130':
    case 'C130':
    case 'LC-130':
    case 'C130 E/H':
      return { id: 'c130', attribution: 'Bob Adams, CC BY-SA 2.0, via Wikimedia Commons' };
    case '210':
    case '210N':
    case '210J':
    case 'Cessna 206':
    case '206-L4':
    case '206 H':
    case 'U206F':
    case 'Cessna 205':
    case 'Cessna 182':
    case 'R182':
    case '185 Skywagon':
    case 'C-182':
    case 'C182':
    case 'C182 RG':
    case '182':
    case 'Cessna 172':
    case 'CESSNA172':
    case '172 Skyhawk':
    case '172':
    case '172N':
    case '172S':
    case '172-P':
    case 'R172K':
    case 'C172':
    case 'Cessna 210':
    case '180':
    case '185':
    case '152':
    case 'T206H':
    case 'A185F':
      return { id: 'cessna206', attribution: 'Adrian Pingstone, Public Domain, via Wikimedia Commons' };
    case 'Cessna 208B Grand Caravan':
    case 'Cessna 208 Grand Caravan':
    case '208 Caravan':
    case 'Caravan':
    case '208 Grand Caravan':
    case 'Grand Caravan':
    case 'C208':
    case 'C208B':
    case 'C208 Caravan':
    case '208B Grand Caravan':
    case '208':
    case 'Skywagon 207':
      return { id: 'cessna208', attribution: 'Kambui, CC BY 2.0, via Wikimedia Commons' };
    case 'Cessna 208 Amphibian Caravan':
      return { id: 'cessna208a', attribution: 'Acroterion, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'GA8 Airvan':
    case 'GA8':
    case 'Airvan':
      return { id: 'ga8', attribution: 'Bidgee, CC BY-SA 3.0, via Wikimedia Commons' };
    case 'Quest Kodiak':
    case 'Kodiak':
      return { id: 'kodiak', attribution: 'Mark Wagner, CC BY 3.0, via Wikimedia Commons' };
    case '750':
    case 'Cresco':
    case '0800-800':
    case 'Cresco II':
    case 'PAC 750XL':
    case '750XL':
    case 'NZ Aerospace FU24-950':
    case 'PAC 750':
    case 'FU-24 Fletcher':
    case 'FU-24':
    case 'Fletcher FU-24':
      return { id: 'pa750', attribution: 'FlugKerl2, CC BY-SA 3.0, via Wikimedia Commons' };
    case 'T-6 Texan II':
    case 'AT-6':
      return { id: 't6', attribution: 'Airwolfhound, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'S-2':
      return { id: 's2tracker', attribution: 'Valder137, CC BY 2.0, via Wikimedia Commons' };
    case 'H125':
    case 'H-125':
    case 'H-125 Ecureuil':
    case 'AS350':
    case 'AS 350':
    case 'AS-350 Ecureuil':
    case 'AS350B3':
    case 'Aerospatiale AS-350 Ecureuil':
    case 'AS-350 SuperStar':
    case '350':
    case 'AS350BA':
    case 'AS350 BA':
    case 'AS350 B2':
    case 'AS-350 B2':
    case 'AS350B2':
    case 'AS350-B3':
    case 'AS350 B3':
    case 'A350 B3':
    case 'AS 350 B3 e':
    case 'AS 350B3e':
    case 'AS350 B3e':
    case 'AS350D':
    case 'AS355':
    case 'AS-355 Ecureuil 2':
    case 'Twin Squirrel':
    case 'Astar':
    case 'Squirrel':
    case 'B3':
    case 'B3E':
    case 'Firebird 220':
    case '8492':
      return { id: 'as350', attribution: 'Tony Hisgett, CC BY 2.0, via Wikimedia Commons' };
    case '206':
    case '206B':
    case '206BIII':
    case '206B III':
    case '206B3':
    case 'B206':
    case 'B206III':
    case 'Bell 206 Jetranger':
    case 'BELL 206 JET RANGER':
    case 'JetRanger':
    case 'Jet Ranger':
    case '206L':
    case '206/L':
    case '206L3':
    case '206-L3':
    case 'B206L-3':
    case 'B206 B3':
    case 'Bell 206L Longranger':
    case 'LongRanger':
    case 'Long Ranger 4':
      return { id: 'b206', attribution: 'Tony Hisgett, CC BY 2.0, via Wikimedia Commons' };
    case 'R44':
    case 'R-44':
    case '44':
    case 'R44 Raven':
    case 'R-44 Raven':
    case 'Robinson R-44 Raven':
    case 'R44II':
    case 'R44 Raven II':
      return { id: 'r44', attribution: 'Marek Ślusarczyk, CC BY 2.5, via Wikimedia Commons' };
    case '117':
    case 'BK117':
    case 'BK117 B2':
    case 'BK117C2':
    case 'BK117D2':
    case 'BK-117':
    case 'MBB-BK117':
    case 'EC145':
    case 'EC-145':
    case 'H145':
    case '135':
    case 'EC135':
    case 'EC-135':
    case 'EC135P2+':
      return { id: 'ec145', attribution: 'Zufallspotter, CC BY-SA 4.0, via Wikimedia Commons' };
    case '204':
    case '205':
    case '212':
    case '212HP':
    case '214':
    case 'B-205':
    case 'B-205 A1':
    case 'B-212':
    case 'UH':
    case 'UH1B':
    case 'UH1F':
    case 'UH-1':
    case 'UH-1D':
    case 'UH-1H':
    case 'UH-1H Iroquois':
    case 'Huey':
      return { id: 'b212', attribution: 'Bidgee, CC BY-SA 3.0, via Wikimedia Commons' };
    case '412':
    case '412EP':
    case 'B412':
    case 'BELL 412 EP':
      return { id: 'b412', attribution: 'Christian Valle, CC BY-SA 4.0, via Wikimedia Commons' };
    case '139':
    case 'AW139':
    case 'AW 139':
    case 'AW-139':
    case 'AB-139':
      return { id: 'aw139', attribution: 'Tim Felce, CC BY-SA 2.0, via Wikimedia Commons' };
    case '407':
    case '407GX':
    case '407GXP':
    case 'Bell 407':
      return { id: 'b407', attribution: 'Md Shaifuzzaman Ayon, CC BY-SA 4.0, via Wikimedia Commons' };
    case 'C':
    case '500':
    case '500D':
    case '500E':
    case '530FF':
    case 'MD500':
    case 'MD530':
    case 'MD530FF':
      return { id: 'md500', attribution: 'Htm, CC BY 4.0, via Wikimedia Commons' };
    case '369':
    case '369D':
    case '369e':
    case '369FF':
    case '500c':
    case 'MD369':
    case 'MD369c':
    case 'ND369':
      return { id: 'md369', attribution: 'Alan Wilson, CC BY-SA 2.0, via Wikimedia Commons' };
    case '500N':
    case '520N':
    case '520 Notar':
    case '520 Notar_D':
    case 'MD520':
    case 'MD520N':
    case 'Hughes 520 N':
    case 'Notar':
      return { id: 'md520n', attribution: 'Aleksandr Markin, CC BY-SA 2.0, via Wikimedia Commons' };
    case '600':
    case 'MD600':
    case 'MD 600 N':
      return { id: 'md600n', attribution: 'Aeroprints, CC BY-SA 3.0, via Wikimedia Commons' };
    case '22':
    case 'R22':
    case 'R-22':
      return { id: 'r22', attribution: 'Bob Adams, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'S64':
    case 'S-64':
    case 'CH54':
      return { id: 's64', attribution: 'Bidgee, CC BY-SA 3.0, via Wikimedia Commons' };
    case 'S70':
    case 'S70i':
    case 'S-70i':
    case 'BH60':
    case 'EH-60':
    case 'UH60':
    case 'UH60A':
    case 'UH-60':
    case 'UH-60A':
    case 'UH-60 Blackhawk':
    case 'UH-60A Black Hawk':
    case 'UH 60 Black Hawk':
    case 'Blackhawk':
    case 'Black Hawk':
    case 'Firehawk':
      return { id: 's70', attribution: 'Jakub Hałun, CC BY-SA 4.0, via Wikimedia Commons' };
    case '234':
    case 'B234':
    case '47':
    case '47G':
    case 'CH47':
    case 'CH47D':
    case 'CH-47':
    case 'CH-47D':
    case 'Chinook':
      return { id: 'ch47', attribution: 'Bidgee, CC BY-SA 3.0, via Wikimedia Commons' };
    case 'Canadair CL-215T':
      return { id: 'cl415', attribution: 'Jerry Gunner, CC BY 2.0, via Wikimedia Commons' };
    case '727':
      return { id: 'b727', attribution: 'Aero Icarus, CC BY-SA 2.0, via Wikimedia Commons' };
    case '31':
    case '31A':
    case '35':
    case '35A':
    case '35-649':
    case '36':
    case '45':
    case '60':
    case 'LJ45':
    case 'Learjet 35':
    case 'Learjet 45':
      return { id: 'learjet', attribution: 'Anna Zvereva, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'Cessna 525B CitationJet CJ3':
    case 'CJ2':
    case 'Citation V':
    case 'Citation Sovereign':
    case 'Citation':
    case 'Citation Mustang':
    case 'S650 Citation':
    case 'S550 Citation':
    case 'Citation 680 Sovereign':
    case 'S560 Citation':
    case 'CJ1':
    case '525':
      return { id: 'cessnacitation', attribution: 'Acroterion, CC BY-SA 4.0, via Wikimedia Commons' };
    case 'DHC-8':
    case 'Dash 8-300':
    case 'DASH 8- Q300':
    case 'Dash 8 Q300':
    case 'DHC - 300':
    case 'Dash 8':
    case 'AT76':
      return { id: 'dash8300', attribution: 'Aeroprints, CC BY-SA 3.0, via Wikimedia Commons' };
    case 'Dash 8 Q400':
      return { id: 'dash8', attribution: 'Lord of the Wings, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'Avro RJ85':
    case 'RJ85':
    case '146-200':
    case 'RJ100':
    case '(RJ100)':
      return { id: 'b146', attribution: 'YSSYguy, CC BY-SA 4.0, via Wikimedia Commons' };
    case 'A318':
    case 'A319':
    case 'A320':
    case 'A321':
      return { id: 'a320', attribution: 'Laurent ERRERA, CC BY-SA 2.0, via Wikimedia Commons' };
    case '737':
    case '737 - 3H4':
    case 'B737-300':
    case 'B731':
    case 'B732':
    case 'B733':
    case 'B734':
    case 'B735':
    case 'B736':
    case 'B737':
    case 'B738':
    case 'B739':
    case 'B73G':
    case 'B37M':
    case 'B38M':
    case 'B39M':
    case 'B3XM':
      return { id: 'b737', attribution: 'Ad Meskens, CC BY-SA 4.0, via Wikimedia Commons' };
    case 'DC-10':
    case 'DC10':
      return { id: 'dc10', attribution: 'Lisa Cox, CC 0, via Wikimedia Commons' };
    case 'Piper PA-31 Navajo':
    case 'PA-31 Navajo':
    case 'PA-31':
    case 'PA-31-325':
    case 'PA-31-350':
    case 'PA31':
    case 'PA31-350':
      return { id: 'pa31', attribution: 'Eddie Maloney, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'DHC-6 Twin Otter':
      return { id: 'dhc6', attribution: 'Aero Icarus, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'S61':
    case 'S61N':
      return { id: 's61', attribution: 'Tony Hisgett, CC BY 2.0, via Wikimedia Commons' };
    case '429':
    case 'B429':
    case '427':
    case '430':
    case 'B430':
      return { id: 'b429', attribution: 'Mike Burdett, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'AS120':
    case 'EC120':
    case 'EC120B':
    case 'EC 120 B':
    case 'EC-120 Colibri':
    case 'H120':
    case 'EC130':
    case 'EC 130 T2':
    case 'EC-130 T2':
    case 'T2':
    case 'H130':
      return { id: 'ec130', attribution: 'Md Shaifuzzaman Ayon, CC BY-SA 4.0, via Wikimedia Commons' };
    case 'Mi8':
    case 'Mi17':
    case 'Mi-17':
      return { id: 'mi17', attribution: 'Pilot 777, CC BY-SA 4.0, via Wikimedia Commons' };
    case '109':
    case 'A109S':
    case 'AW109':
    case 'AW109S':
    case 'AW109SP':
    case 'Agusta A109 Grand':
    case 'A119':
    case 'AW119':
    case 'A119MKII':
    case 'MKII':
      return { id: 'aw109', attribution: 'Bob Adams, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'K-Max':
      return { id: 'kmax', attribution: 'André Völzke, CC BY-SA 3.0, via Wikimedia Commons' };
    case 'AS332':
      return { id: 'as332', attribution: 'Anna Zvereva, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'KA32':
    case 'KA-32':
      return { id: 'ka32', attribution: 'Pavel Adzhigildaev, CC BY-SA 3.0, via Wikimedia Commons' };
    case '337':
    case '337G Skymaster':
    case 'Cessna 337 Skymaster':
      return { id: 'cessna337', attribution: 'Oyoyoy, CC BY-SA 4.0, via Wikimedia Commons' };
    case '580':
      return { id: 'convair580', attribution: 'Alasdair McLellan, CC BY-SA 3.0, via Wikimedia Commons' };
    case 'DC-3':
    case 'DC3':
      return { id: 'dc3', attribution: 'Bernard Spragg, CC0, via Wikimedia Commons' };
    case 'Sirius':
      return { id: 'sirius', attribution: 'Alan Wilson, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'Challenger 604': // temp following that have a similar shape
    case 'G150':
    case '400':
    case 'EMB-100':
    case '550 P':
    case 'B400XT':
      return { id: 'learjet', attribution: 'Anna Zvereva, CC BY-SA 2.0, via Wikimedia Commons' };
    case '850': // temp following that have a similar shape
    case 'MD87':
    case 'Embraer 135':
    case 'EMB-145':
    case 'DC-87':
      return { id: 'b727', attribution: 'Aero Icarus, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'CH46E': // Approximate (Tandem rotor)
    case 'CH-46E':
    case 'CH107':
      return { id: 'ch47', attribution: 'Bidgee, CC BY-SA 3.0, via Wikimedia Commons' };
    case '50': // Approximate (Longish Turbo prop)
    case 'F27 Friendship':
      return { id: 'dash8300', attribution: 'Aeroprints, CC BY-SA 3.0, via Wikimedia Commons' };
    case '72': // Approximate (Long Turbo prop)
      return { id: 'dash8', attribution: 'Lord of the Wings, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'Jetstream 32': // Approximate (Twin Engine Low Wing)
    case 'Diamond DA42 Twinstar':
    case 'Cessna 310':
    case '310':
    case '340':
    case '340A':
    case '340B+':
    case 'C441':
    case 'Cessna 402':
    case 'Cessna 404':
    case '404':
    case 'F406 Caravan II':
    case 'Caravan II':
    case 'Cessna Conquest':
    case '421B':
    case '421 Golden Eagle':
    case '441 Conquest':
    case 'B1900':
    case 'Seminole':
    case 'PA44':
    case 'Embraer 120':
    case 'Cheyenne':
    case 'PA-23 Aztec':
      return { id: 'pa31', attribution: 'Eddie Maloney, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'Dornier 228': // Approximate (Twin Engine High Wing)
    case 'C-212':
    case 'BN2 Islander':
    case 'BN2':
    case 'P68':
    case 'P68.C':
    case 'P68C':
    case 'Vulcanair P68':
    case 'Partenavia P.68':
    case 'p68 Observer':
    case 'P68 OBSERVER 2':
    case 'MU-2B':
    case 'AC95':
    case '690':
    case '690A':
    case 'Aero Commander':
    case 'DHC-4 Caribou':
    case 'Britten Norman Islander BN2A':
    case 'BN-2B-26 Islander':
      return { id: 'dhc6', attribution: 'Aero Icarus, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'AW189': // Approximate shape (similar model aw139)
      return { id: 'aw139', attribution: 'Tim Felce, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'BO105': // Approximate shape (similar model EC145)
    case 'Bo-105':
    case 'BO 105 SL':
    case 'Bölkow 105':
    case 'Bolkow BO 105 CBS-5':
    case 'AW159':
      return { id: 'ec145', attribution: 'Zufallspotter, CC BY-SA 4.0, via Wikimedia Commons' };
    case 'S76': // Approximate shape (similar model AW109)
    case 'S76D': // Approximate shape (similar model AW109)
    case 'AS365':
    case 'AS365N3':
      return { id: 'aw109', attribution: 'Bob Adams, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'AW169': // Approximate shape (similar model AW139)
    case 'AW 169':
    case 'H160':
    case 'AW101':
      return { id: 'aw139', attribution: 'Tim Felce, CC BY-SA 2.0, via Wikimedia Commons' };
    case 'S62': // Approximate shape (similar model S61)
      return { id: 's61', attribution: 'Tony Hisgett, CC BY 2.0, via Wikimedia Commons' };
    case '222': // Approximate shape (similar model Bell 429)
    case '230':
      return { id: 'b429', attribution: 'Mike Burdett, CC BY-SA 2.0, via Wikimedia Commons' };
    case '505': // Approximate shape (similar model Bell 429)
      return { id: 'b206', attribution: 'Tony Hisgett, CC BY 2.0, via Wikimedia Commons' };
    case 'NH90': // Approximate shape (similar blades AS 332)
    case 'S92':
    case 'SOKOL':
      return { id: 'as332', attribution: 'Anna Zvereva, CC BY-SA 2.0, via Wikimedia Commons' };
    case '214ST': // Approximate shape (longer than Bell 212)
      return { id: 'b212', attribution: 'Bidgee, CC BY-SA 3.0, via Wikimedia Commons' };
    case 'R66': // Approximate shape (longer than R44)
      return { id: 'r44', attribution: 'Marek Ślusarczyk, CC BY 2.5, via Wikimedia Commons' };
    default:
      switch (category?.toString().replaceAll(' ', '').toLowerCase()) {
        case 'aircraft':
          return { id: 'aircraft', attribution: 'Bill Larkins, CC BY-SA 2.0, via Wikimedia Commons' };
        case 'airship':
          return { id: 'airship' };
        case 'balloon':
          return { id: 'balloon' };
        case 'ambulance':
          return { id: 'ambulance' };
        case 'bus':
          return { id: 'bus' };
        case 'campervan':
          return { id: 'campervan' };
        case 'policecar':
          return { id: 'policecar' };
        case 'minibus':
          return { id: 'minibus' };
        case 'mobilehome':
          return { id: 'mobilehome' };
        case 'tractor':
          return { id: 'tractor' };
        case 'truck':
          return { id: 'truck' };
        case 'motorcycle':
          return { id: 'motorcycle' };
        case 'car':
          return { id: 'car' };
        case 'helicopter':
          return { id: 'helicopter', attribution: 'Rafael Luiz Canossa, CC BY-SA 2.0, via Wikimedia Commons' };
        case 'bicycle-1':
        case 'bicycle-2':
        case 'bicycle':
          return { id: 'bicycle' };
        case 'man':
        case 'person':
        case 'woman':
        case 'executiveman':
        case 'executivewoman':
        case 'worker':
          return { id: 'person' };
        case 'container':
          return { id: 'container' };
        case 'containership':
          return { id: 'containership' };
        case 'yacht':
          return { id: 'yacht' };
        case 'powerboat':
          return { id: 'powerboat' };
        case 'tug':
          return { id: 'tug' };
        case 'inflatable':
          return { id: 'inflatable' };
        case 'firetruck':
          return { id: 'firetruck' };
        case 'rocket':
          return { id: 'rocket' };
        case 'heartbeat':
          return { id: 'heartbeat' };
        case 'drone':
          return { id: 'drone', attribution: 'Potushaft, CC BY-SA 4.0, via Wikimedia Commons' };
        case 'unknown':
          return { id: 'unknown' };
        case 'a0':
          return { id: 'aircraft' };
        case 'a1':
          return { id: 'cessna206' };
        case 'a2':
          return { id: 'dash8' };
        case 'a3':
          return { id: 'b737' };
        case 'a4':
        case 'a5':
          return { id: 'b747' };
        case 'a7':
          return { id: 'helicopter' };
        case 'b2':
          return { id: 'airship' };
        default:
          return { id: 'other' };
      }
  }
};

export const mapU1ReportToReport = (report: U1Report): Report => ({
  id: report.id,
  assetId: 0,
  deviceId: report.deviceId,
  received: messageTimestampToUnix(report.time, 'YYYY-MM-DDTHH:mm:ss'),
  transmitted: messageTimestampToUnix(report.transmitted, 'YYYY-MM-DDTHH:mm:ss'),
  logged: messageTimestampToUnix(report.transmitted, 'YYYY-MM-DDTHH:mm:ss'),
  altitude: report.position.altitude,
  longitude: report.position.longitude,
  latitude: report.position.latitude,
  isValid: report.position.accuracy.isValid,
  dop: report.position.accuracy.pdop,
  course: report.position.course,
  speed: report.position.speed || 0,
  gateway: report.gateway,
  package: report.metadata?.toString() || '',
  isDerived: report.position.accuracy.isDerived || false,
  events: report.event?.id.split('|') || ['EVT_STANDARD'],
  battery: 0,
});
