import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { inviteQueryKeys } from './queryKeys';
import { cancelInvite, getInvites, inviteUser } from './requests';

export const useGetInvites = () => {
  const organisationId = useOrganisationId();
  const queryKey = inviteQueryKeys.list(organisationId);
  const query = useQuery({
    queryKey,
    queryFn: () => getInvites(organisationId),
  });
  return { query, queryKey };
};

export const useInviteUser = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (value: Pick<Invite, 'email' | 'name' | 'role'>) =>
      inviteUser(organisationId, value.name, value.email, value.role),
    mutationKey: ['inviteUser'],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: inviteQueryKeys.all(organisationId) }),
  });
};

export const useCancelInvite = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (value: Pick<Invite, 'inviteId'>) => cancelInvite(value.inviteId),
    mutationKey: ['cancelInvite'],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: inviteQueryKeys.all(organisationId) }),
  });
};
