import { useMemo } from 'react';

export interface Dependencies {
  [key: string]: (...args: never[]) => unknown
}

type Bindings<D extends Dependencies> = {
  [K in keyof D]?: D[K]
}

export interface Dependency<T> {
  // Injects the dependency
  inject: T
}

export class DependencyInjectionContainer<D extends Dependencies> {
  private bindings: Bindings<D> = {};

  // Registers a resolver function for a dependency which is lazily evaluated each time the dependency is injected
  bind<K extends keyof D>(id: K, resolver: D[K]): void {
    this.bindings[id] = resolver;
  }

  // Registers a resolver function for a dependency which is lazily evaluated only the first time the dependency is injected
  bindSingleton<K extends keyof D>(id: K, resolver: D[K]): void {
    let value: unknown;
    const b = () => {
      value ??= resolver();
      return value;
    };
    this.bindings[id] = b as D[K];
  }

  // Returns a dependency
  depend<K extends keyof D>(id: K): Dependency<D[K]> {
    const inject = (...params: never[]) => {
      const resolver = this.bindings[id];
      if (!resolver) throw new Error(`DI no binding exists for ${id.toString()}`);
      return resolver(...params);
    };
    return { inject: inject as D[K] };
  }
}

export const createUseDependency = <D extends Dependencies>(container: DependencyInjectionContainer<D>) => (
  <K extends keyof D, T>(id: K, callback: (dependency: Dependency<D[K]>) => T) => (
    // biome-ignore lint/correctness/useExhaustiveDependencies: Callback is only called on mount
    useMemo<T>(
      () => callback(container.depend(id)),
      [id],
    )
  )
);
