import { HttpResponseError, serenityFetch } from 'helpers/api';
import type { GroupAssignPeopleRequestBody } from '../iceContactGroups/requests';

export const getPeopleGroup = async (organisationId: string, peopleGroupTypeId: number): Promise<ContactGroup[]> => {
  const response = await serenityFetch(
    'GET',
    `/organisations/${organisationId}/people/groups/${peopleGroupTypeId}`,
    null,
  );
  HttpResponseError.detect(response);
  return (await response.json()).groups;
};

export const deletePeopleGroup = async (
  organisationId: string,
  peopleGroupTypeId: number,
  peopleGroupId: number,
): Promise<void> => {
  const response = await serenityFetch(
    'DELETE',
    `/organisations/${organisationId}/people/groups/${peopleGroupTypeId}/${peopleGroupId}`,
    null,
  );
  HttpResponseError.detect(response);
};

export const addPeopleGroup = async (
  organisationId: string,
  peopleGroupTypeId: number,
  body: Pick<ContactGroup, 'name'>,
): Promise<ContactGroup> => {
  const response = await serenityFetch(
    'POST',
    `/organisations/${organisationId}/people/groups/${peopleGroupTypeId}`,
    body,
  );
  HttpResponseError.detect(response);
  return (await response.json()).group;
};

export const addPeopleToPeopleGroup = async (
  organisationId: string,
  peopleGroupTypeId: number,
  body: GroupAssignPeopleRequestBody,
): Promise<void> => {
  const response = await serenityFetch(
    'POST',
    `/organisations/${organisationId}/people/groups/${peopleGroupTypeId}/${body.id}/people`,
    body,
  );
  HttpResponseError.detect(response);
};

export const updatePeopleGroup = async (
  organisationId: string,
  peopleGroupTypeId: number,
  body: Pick<ContactGroup, 'id' | 'name' | 'isDefault'>,
): Promise<void> => {
  const response = await serenityFetch(
    'PUT',
    `/organisations/${organisationId}/people/groups/${peopleGroupTypeId}/${body.id}`,
    body,
  );
  HttpResponseError.detect(response);
};
