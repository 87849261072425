import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUnselectedSearchPatterns } from 'slices/searchPatterns.slice';
import { Layer, Source } from 'react-map-gl';
import { calculateFeatures } from '../draw/helpers';

export const SEARCH_PATTERN_SOURCE_ID = 'sp-layers';
export const SEARCH_PATTERN_LAYER_IDS = ['sp-path', 'sp-origin', 'sp-fill'];

export const SearchPatternLayers: React.FC = () => {
  const searchPatterns = useSelector(selectUnselectedSearchPatterns);

  const data = useMemo(() => ({
    type: 'FeatureCollection',
    features: searchPatterns
      .filter(sp => !sp.isHidden)
      .flatMap(sp => calculateFeatures(sp).features),
  }), [searchPatterns]);

  return (
    <Source id={SEARCH_PATTERN_SOURCE_ID} type="geojson" data={data}>
      <Layer
        id="sp-fill-stroke"
        type="line"
        filter={['==', '$type', 'Polygon']}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': '#3bb2d0',
          'line-opacity': 0.5,
          'line-width': 2
        }}
      />
      <Layer
        id="sp-fill"
        type="fill"
        filter={['==', '$type', 'Polygon']}
        paint={{
          'fill-color': '#3bb2d0',
          'fill-opacity': 0.15
        }}
      />
      <Layer
        id="sp-arrow"
        type="line"
        filter={['all',
          ['==', '$type', 'LineString'],
          ['==', 'meta', 'arrow'],
        ]}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': '#00e34a',
          'line-width': 2,
        }}
      />
      <Layer
        id="sp-rbl-end"
        type="line"
        filter={['all',
          ['==', '$type', 'LineString'],
          ['==', 'meta', 'rbl_head'],
        ]}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': '#00e34a',
          'line-width': 3,
        }}
      />
      <Layer
        id="sp-origin"
        type="circle"
        filter={['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'origin'],
        ]}
        paint={{
          'circle-radius': 6,
          'circle-color': '#00e34a'
        }}
      />
      <Layer
        id="sp-path"
        type="line"
        filter={['all',
          ['==', '$type', 'LineString'],
          ['==', 'meta', 'path'],
        ]}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': '#00e34a',
          'line-width': 3,
        }}
      />
    </Source>
  );
};
