// All the available map layouts
export const MAPLAYOUTS = [
  'map_1x1', // single pane
  'map_2x1', // 2 horizontal panes
  'map_1x2', // 2 panes side by side
  'map_2+1', // 2 small panes at top, 1 large pane at the bottom
  'map_1+2', // 1 large pane at top, 2 small panels at bottom
  'map_2x2', // 4 small panes
];

// this controls the layout on screen for each of the 4 map panes
// 0s are not rendered
export const GRIDSIZES = {
  map_1x1: [12, 0, 0, 0],
  map_2x1: [12, 12, 0, 0],
  map_1x2: [6, 6, 0, 0],
  map_2x2: [6, 6, 6, 6],
  'map_1+2': [12, 6, 6, 0],
  'map_2+1': [6, 6, 12, 0],
};

// assign a numerical value to each layout so we can compare
// changing views
export const VIEWS_PER_LAYOUT = {
  map_1x1: 1,
  map_2x1: 2,
  map_1x2: 2,
  map_2x2: 4,
  'map_1+2': 3,
  'map_2+1': 3,
};

export const DEFAULT_MAP_LAYOUT = 'map_1x1';
