/* eslint-disable no-underscore-dangle */
import { Box } from '@mui/material';
import { useAssetGroupBy } from 'hooks/settings/useAssetGroupBy';
import { useAssetSortBy } from 'hooks/settings/useAssetSortBy';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getSelectedItem, selectItem } from 'slices/app.slice';
import { assignItemToMap, getAllMapIds, getSelectedMapId, setAssetFollow, updateMapLayout } from 'slices/map.slice';
import { selectAssetAndSetUrl } from 'slices/map/mapThunks';
import { AppDispatch } from 'store/types';
import GroupedQueryResults from './groupedResults';
import useStyles from './results-styles';
import { VIEWS_PER_LAYOUT } from 'constants/maplayouts';
import { useReportsDataRepository } from 'repositories/reports/hooks';
import { useSetViewport } from 'contexts/viewport/useViewport';
import { SetViewportContext } from 'contexts/viewport/setViewportContext';

interface OmniboxResultsProps {
  data: { assets: AssetBasic[] }
}

const OmniboxResults = ({
  data,
}: OmniboxResultsProps): JSX.Element => {
  const classes = useStyles();
  const selectedSortBy = useAssetSortBy();
  const selectedGroupBy = useAssetGroupBy();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedMapId = useSelector(getSelectedMapId);
  const selectedAsset = useSelector(getSelectedItem);
  const defaultMapSettings = useSelector((state: ReduxState) => state.map.maps.default);
  const selectedMapLayout = useSelector((state: ReduxState) => state.map.selectedMapLayout);
  const allMapIds = useSelector(getAllMapIds);
  const reports = useReportsDataRepository();
  const setViewport = useContext(SetViewportContext);


  const selectAsset = useCallback((asset: AssetBasic) => {
    dispatch(selectAssetAndSetUrl({ mapId: selectedMapId, asset }));
    // TODO: performance - this use effect is selecting assets when switching maps, this is also happening in mapContainer-view
  }, [dispatch, selectedMapId, selectedAsset]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run on mount
  useEffect(() => {
    const setDataFromParams = async () => {
      const assetIdDefault = searchParams.get('asset');
      const assetId1 = searchParams.get('asset1');
      const assetId2 = searchParams.get('asset2');
      const assetId3 = searchParams.get('asset3');

      // Find all valid assets from params
      const assets = [
        data.assets.find(a => a.id === Number(assetIdDefault)),
        data.assets.find(a => a.id === Number(assetId1)),
        data.assets.find(a => a.id === Number(assetId2)),
        data.assets.find(a => a.id === Number(assetId3))
      ].filter((a): a is AssetBasic => !!a);

      // Remove any asset params that weren't found
      setSearchParams((params) => {
        const paramKeys = ['asset', 'asset1', 'asset2', 'asset3'];
        paramKeys.forEach((key, index) => {
          if (!assets[index] && params.has(key)) {
            params.delete(key);
          }
        });
        return params;
      });

      if (assets.length > 0) {
        // Calculate required layout based on number of assets
        const layoutMap = {
          1: 'map_1x1',
          2: 'map_2x1',
          3: 'map_2+1',
          4: 'map_2x2'
        } as const;

        const layout = layoutMap[assets.length as keyof typeof layoutMap];

        let mapIds = allMapIds;

        // Only update layout if different from current
        if (VIEWS_PER_LAYOUT[selectedMapLayout] !== assets.length) {
          mapIds = await new Promise<string[]>(resolve => {
            dispatch(updateMapLayout({
              layout,
              defaultMapSettings,
              callback: resolve
            }));
          });
        }

        // Set selected item only once, prioritizing default map first
        const assetToSelect = assets[0];

        if (assetToSelect) {
          dispatch(selectItem(assetToSelect));
        }

        // Then assign assets to each map
        assets.forEach((asset, index) => {
          const mapId = mapIds[index];
          dispatch(assignItemToMap({ mapId, item: asset }));
          const latestPosition = reports.getLatestPosition(asset.id);
          if (latestPosition) {
            setViewport(mapId, { latitude: latestPosition.latitude, longitude: latestPosition.longitude, zoom: 15 });
          }
        });
      }
    };

    setDataFromParams();
  }, []);

  const assets = useCallback(() => data.assets, [data])();

  return (
    <Box id="omniboxResults" className={classes.container}>
      <Box className={classes.listWrapper} data-test="assetContainer">
        {data.assets && (
          <GroupedQueryResults
            results={assets}
            groupBy={selectedGroupBy.assets}
            sortBy={selectedSortBy.assets}
            selectAsset={selectAsset}
          />
        )}
      </Box>
    </Box>
  );
};

export default OmniboxResults;
