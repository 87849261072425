
export function paginateArray<T>(array: T[], page: number, pageSize: number): T[] {
  const startIndex = page * pageSize;
  const endIndex = (page + 1) * pageSize;
  if (startIndex > array.length || startIndex < 0 || pageSize > array.length) {
    return array;
  }

  return (endIndex > array.length) ? array.slice(startIndex) : array.slice(startIndex, endIndex);
}
