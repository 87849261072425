import {
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type { ProvisioningProcessorRow, ProvisioningUploadResponse } from '@tracplus/serenity-client';
import { serenityApiClient } from 'apis/client/serenityApiClient';
import type { HttpResponseError } from 'helpers/api';
import { provisioningQueryKeys } from './queryKeys';
import { uploadProvisioningCSV } from './requests';
import type { ActivateProvisioningForDeviceParams, UploadProvisioningCSVParams } from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;
type MutationOptions<MutationData, Variables> = Omit<
  UseMutationOptions<MutationData, HttpResponseError, Variables, void>,
  'queryKey' | 'queryFn'
>;

export const useGetAllProvisioningRows = <T = ProvisioningProcessorRow[]>(
  options?: Options<ProvisioningProcessorRow[], T>,
) => {
  const queryKey = provisioningQueryKeys.allRows;
  return useQuery({
    queryKey,
    queryFn: async () => (await serenityApiClient.provisioning()).rows ?? [],
    ...options,
  });
};

export const useUploadProvisioningCSV = (
  options?: MutationOptions<ProvisioningUploadResponse, UploadProvisioningCSVParams>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['uploadProvisioningCSV'],
    mutationFn: ({ file }: UploadProvisioningCSVParams) => uploadProvisioningCSV(file),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: provisioningQueryKeys.allRows }),
    ...options,
  });
};

export const useActivateProvisioningForDevice = (
  options?: MutationOptions<void, ActivateProvisioningForDeviceParams>,
) =>
  useMutation<void, HttpResponseError, ActivateProvisioningForDeviceParams, void>({
    mutationFn: async ({ deviceId }: ActivateProvisioningForDeviceParams) => {
      await serenityApiClient.provisioningActivate(deviceId);
    },
    ...options,
  });

export const useActivateProvisioningForAll = (options?: MutationOptions<void, void>) =>
  useMutation<void, HttpResponseError, void, void>({
    mutationFn: async () => {
      await serenityApiClient.provisioningActivateAll();
    },
    ...options,
  });
