import { Box, Stack, SvgIcon, type Theme, Tooltip, Typography } from '@mui/material';
import type { SxProps } from '@mui/system/styleFunctionSx';
import useFeatureFlag from 'hooks/useFeatureFlag';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleAnalysisbox } from 'slices/ui.slice';
import { useAppDispatch, useAppSelector } from 'store/types';
import { useTranslations } from 'use-intl';
import Analysisbox from './analysisbox/analysisbox-view';
import { AnalysisBoxTimeline } from './timeline/AnalysisBoxTimeline';

// style objects
const analysisboxWrapperStyle: SxProps<Theme> = {
  bottom: 0,
  width: '100%',
  height: 400,
  zIndex: 2,
  backgroundColor: 'background.overlay',
} as const;

const analysisboxStyle: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: 400,
  boxShadow: 'rgba(0, 0, 0, 0.1) -12px -12px 12px -12px',
  zIndex: 4,
  padding: '0 20px',
} as const;

const resizerStyle: SxProps<Theme> = {
  height: 7,
  width: '100%',
  cursor: 'ns-resize',
  padding: '4px 0 0',
  position: 'absolute',
  zIndex: '100',
  transition: 'all 0.15s',
  backgroundColor: 'background.overlay',
  '&:hover': {
    backgroundColor: 'primary.blue',
  },
} as const;

const collapseButtonWrapperStyle: SxProps<Theme> = {
  margin: '0 auto',
  padding: '0 100px 18px 100px',
  position: 'relative',
  backgroundColor: 'background.overlay',
} as const;

const collapseButtonStyle: SxProps<Theme> = {
  position: 'absolute',
  height: 30,
  margin: '-11px auto 0 auto',
  paddingTop: '6px',
  paddingBottom: '4px',
  paddingRight: '16px',
  paddingLeft: '16px',
  top: -20,
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  outline: '1px solid rgba(255,255,255,.2)',
  cursor: 'pointer',
  zIndex: -1,
  transition: 'all 0.05s',
  transform: 'translateY(7px) translateX(-75px)',
  '&:hover': {
    transform: 'translateY(1px) translateX(-75px)',
  },
} as const;

interface AnalysisboxContainerProps {
  selectedAsset: AssetBasic | AssetWithDevice | null;
}

const AnalysisboxContainer = ({ selectedAsset }: AnalysisboxContainerProps) => {
  const t = useTranslations('analysisbox');

  const analysisboxOpen = useAppSelector(state => state.ui.analysisboxOpen);
  const highContrastControls = useSelector<ReduxState, ReduxState['settings']['map']['highContrastControls']>(
    state => state.settings.map.highContrastControls,
  );

  const dispatch = useAppDispatch();

  const timeline = useFeatureFlag('frontendAnalysisBoxTimeline');
  const [isDragging, setIsDragging] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(analysisboxOpen ? 400 : 0);
  const [initialHeight, setInitialHeight] = useState(analysisboxOpen ? 400 : 0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const newHeight = drawerHeight + (window.innerHeight - e.clientY - initialHeight);
      if (newHeight > 182 && newHeight < window.innerHeight - 56) {
        setDrawerHeight(newHeight);
      }
    },
    [drawerHeight, initialHeight],
  );

  const handleMouseDown = useCallback((): void => {
    const handleMouseUp = (): void => {
      setIsDragging(false);
      document.removeEventListener('mouseup', handleMouseUp, true);
      document.removeEventListener('mousemove', handleMouseMove, true);
    };
    setIsDragging(true);
    setInitialHeight(wrapperRef.current ? wrapperRef.current.offsetHeight : 400);
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  }, [handleMouseMove]);

  const handleCloseButtonClick = useCallback(() => {
    dispatch(toggleAnalysisbox());
    mixpanel.track('Toggle Analysis Box', {
      open: !(analysisboxOpen && selectedAsset),
    });
    if (analysisboxOpen && selectedAsset) {
      setDrawerHeight(35);
    } else {
      setDrawerHeight(400);
    }
  }, [analysisboxOpen, selectedAsset, dispatch]);

  useEffect(() => {
    if (!analysisboxOpen) {
      setDrawerHeight(0);
    } else {
      setDrawerHeight(400);
    }
  }, [analysisboxOpen]);

  if (!timeline && !selectedAsset?.deviceId) {
    return null;
  }

  return (
    <Box
      ref={wrapperRef}
      aria-label="analysisboxWrapper"
      sx={{
        ...analysisboxWrapperStyle,
        height: drawerHeight,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        aria-label="resizer"
        sx={{ ...resizerStyle, ...(isDragging && { backgroundColor: 'primary.blue' }) }}
        onMouseDown={handleMouseDown}
      />
      <Box aria-label="analysisbox" sx={analysisboxStyle} flex={1}>
        <Box aria-label="collapseButtonWrapper" sx={collapseButtonWrapperStyle}>
          <Tooltip
            title={analysisboxOpen ? t('collapsePanel') : t('expandPanel')}
            placement="top"
            PopperProps={{
              popperOptions: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      enabled: true,
                      offset: [0, 10],
                    },
                  },
                ],
              },
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              aria-label="collapseButton"
              sx={{
                ...collapseButtonStyle,
                backgroundColor: highContrastControls ? 'mapControls.bgHighContrast' : 'rgba(0,0,0,.4)',
              }}
              onClick={handleCloseButtonClick}
              role="button"
            >
              <Typography whiteSpace="nowrap" color="common.whiteConstant">
                {timeline
                  ? t(analysisboxOpen ? 'timeline.hideTimeline' : 'timeline.showTimeline')
                  : t(analysisboxOpen ? 'hideReports' : 'showReports')}
              </Typography>
              <SvgIcon
                aria-label="icon"
                sx={{ transition: 'all 0.05s', transform: analysisboxOpen ? 'rotate(270deg)' : 'rotate(90deg)' }}
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <polygon fill="#fff" points="10,13.4 4.6,8 10,2.6 11.4,4 7.4,8 11.4,12" />
              </SvgIcon>
            </Stack>
          </Tooltip>
        </Box>
        {analysisboxOpen && (
          <div
            style={{ height: '100%', display: 'flex', flex: 1, flexDirection: 'column' }}
            aria-label={'timelineWrapper'}
          >
            {timeline && <AnalysisBoxTimeline selectedAsset={selectedAsset} drawerHeight={drawerHeight} />}
            {!timeline && selectedAsset?.deviceId && <Analysisbox drawerHeight={drawerHeight} />}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default AnalysisboxContainer;
