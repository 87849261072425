export const createConsole = (name: string): Console => {
  const c = {
    log: console.log.bind(console, `[LOG ${name}]`),
    debug: console.debug.bind(console, '%c%s', 'color: MediumSeaGreen', `[DEBUG ${name}]`),
    info: console.info.bind(console, '%c%s', 'color: DodgerBlue', `[INFO ${name}]`),
    warn: console.warn.bind(console, '%c%s', 'color: Orange', `[WARN] ${name}`),
    error: console.error.bind(console, '%c%s', 'color: Tomato', `[ERROR ${name}]`),
    trace: console.trace.bind(console, '%c%s', 'color: MediumSeaGreen', `[TRACE ${name}]`),
  };
  Object.setPrototypeOf(c, console);

  return c as Console;
};
