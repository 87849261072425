import { styled } from '@mui/system';
import { Box, Fab, SpeedDial, SpeedDialAction } from '@mui/material';
import { excludeForwardProps } from 'utils/style';

export const AccreditationWrapper = styled(Box, {
  shouldForwardProp: excludeForwardProps(['highContrastControls']),
})<{ highContrastControls: boolean }>(({ theme, highContrastControls = false }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: highContrastControls ? theme.palette.mapControls.bgHighContrast : theme.palette.mapControls.bg,
  color: highContrastControls ? theme.palette.mapControls.fgHighContrast : theme.palette.mapControls.fg,
  fontWeight: '500',
  height: 30,
  paddingLeft: 15,
  paddingRight: 15,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
}));

export const MapControlsSpeedDail = styled(SpeedDial, {
  shouldForwardProp: excludeForwardProps(['highContrastControls']),
})<{ highContrastControls: boolean }>(({ theme, highContrastControls }) => ({
  width: 30,
  height: 30,
  position: 'absolute',
  marginRight: 0,
  '&.MuiSpeedDial-directionUp .MuiSpeedDial-actions': {
    paddingBottom: '35px',
  },
  '& > button': {
    minWidth: 30,
    minHeight: 30,
    width: 30,
    height: 30,
    margin: 0,
    borderRadius: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: highContrastControls ? theme.palette.mapControls.bgHighContrast : theme.palette.mapControls.bg,
    color: theme.palette.mapControls.fg,
    boxShadow: 'none',
    transition: 'all 0.15s',
    opacity: 0,
    '&:hover, &:focus': {
      boxShadow: 'none',
      backgroundColor: highContrastControls ? theme.palette.mapControls.bgHighContrast : theme.palette.mapControls.bg,
    },
  },
  ...(highContrastControls ? {
    '& button': {
      '& svg': {
        '& path, polygon, circle': {
          fill: theme.palette.mapControls.fgHighContrast,
        },
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.mapControls.bgDisabledHighContrast,
        '& svg': {
          '& path, polygon, circle': {
            fill: theme.palette.mapControls.fgDisabledHighContrast,
          },
        },
      },
    },
  } : undefined),
}));

export const MapControlsSpeedDailAction = styled(SpeedDialAction, {
  shouldForwardProp: excludeForwardProps(['active', 'highContrastControls']),
})<{ active?: boolean, highContrastControls: boolean }>(({ theme, active, highContrastControls }) => ({
  minWidth: 30,
  minHeight: 30,
  width: 30,
  height: 30,
  margin: 0,
  '&.MuiSpeedDialAction-fab': {
    margin: 0,
    backgroundColor: highContrastControls ? theme.palette.mapControls.bgHighContrast : theme.palette.mapControls.bg,
    color: theme.palette.mapControls.fg,
    '&:hover': {
      backgroundColor: highContrastControls ? theme.palette.mapControls.bgActive : theme.palette.mapControls.bgHover
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.mapControls.bgActive
    }
  },
  borderRadius: 0,
  '&.Mui-disabled': {
    backgroundColor: theme.palette.mapControls.bgDisabled,
    '& svg': {
      '& path, polygon, circle, rect': {
        fill: theme.palette.mapControls.fgDisabled
      }
    }
  },
  '& svg': {
    '& path, polygon, circle, rect': {
      fill: theme.palette.mapControls.fgActive
    }
  },
  boxShadow: 'none',
  transition: 'all 0.15s',
  '&:first-child': {
    borderRadius: '0 0 8px 8px'
  },
  '&:last-child': {
    borderRadius: '8px 8px 0 0 '
  },
  ...(active ? {
    '&.MuiSpeedDialAction-fab': {
      backgroundColor: theme.palette.mapControls.bgActive,
      '& svg': {
        '& path, polygon, circle, rect': {
          fill: theme.palette.mapControls.fgActive,
        },
      },
    },
  } : undefined),
}));

export const MapControlsButtonWrapper = styled('button', {
  shouldForwardProp: excludeForwardProps(['highContrastControls']),
})<{ highContrastControls: boolean }>(({ theme, highContrastControls }) => ({
  zIndex: 1080,
  top: 0,
  right: 0,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  flexDireaction: 'column',
  justifyItems: 'center',
  alignItems: 'center',
  width: 30,
  height: 30,
  border: 'none',
  borderRadius: 0,
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  outline: 'none',
  backgroundColor: highContrastControls ? theme.palette.mapControls.bgHighContrast : theme.palette.mapControls.bg,
  '& svg': {
    '& path, polygon, circle, rect': {
      fill: highContrastControls ? theme.palette.mapControls.fgHighContrast : theme.palette.mapControls.fg,
    },
  },
  cursor: 'pointer',
  transition: 'all 0.15s',
  '&:hover, &:focus': {
    backgroundColor: highContrastControls ? theme.palette.mapControls.bgActive : theme.palette.mapControls.bgHover,
  },
}));

export const ZoomButton = styled('button', {
  shouldForwardProp: excludeForwardProps(['highContrastControls', 'zoom']),
})<{ highContrastControls: boolean, zoom: 'in' | 'out' }>(({ theme, highContrastControls, zoom }) => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  flexDireaction: 'column',
  justifyItems: 'center',
  alignItems: 'center',
  width: 30,
  height: 30,
  border: 'none',
  borderRadius: 0,
  outline: 'none',
  backgroundColor: highContrastControls ? theme.palette.mapControls.bgHighContrast : theme.palette.mapControls.bg,
  '& svg': {
    '& path, polygon, circle, rect': {
      fill: highContrastControls ? theme.palette.mapControls.fgHighContrast : theme.palette.mapControls.fg
    }
  },
  cursor: 'pointer',
  transition: 'all 0.15s',
  borderTopLeftRadius: zoom === 'in' ? 8 : undefined,
  borderBottomLeftRadius: zoom === 'in' ? 8 : undefined,
  borderTopRightRadius: zoom === 'out' ? 8 : undefined,
  borderBottomRightRadius: zoom === 'out' ? 8 : undefined,
  '&:hover, &:focus': {
    backgroundColor: highContrastControls ? theme.palette.mapControls.bgActive : theme.palette.mapControls.bgHover
  },
}));

export const IconFab = styled(Fab, {
  shouldForwardProp: excludeForwardProps(['highContrastControls']),
})<{ highContrastControls: boolean }>(({ theme, highContrastControls }) => ({
  width: 30,
  height: 30,
  minHeight: 30,
  borderRadius: 0,
  backgroundColor: highContrastControls ? theme.palette.mapControls.bgHighContrast : theme.palette.mapControls.bg,
  '& svg': {
    '& path, polygon, circle, rect': {
      fill: highContrastControls ? theme.palette.mapControls.fgHighContrast : theme.palette.mapControls.fg,
    },
  },
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: highContrastControls ? theme.palette.mapControls.bgActive : theme.palette.mapControls.bgHover,
  },
}));
