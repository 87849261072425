import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { dropQueryKeys } from './queryKeys';
import { getDropsForAssets, getDropsForAssetsV1 } from './requests';
import type { DropGroupV1, DropSummary } from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetDropsForAssetsV1 = <T = DropGroupV1[]>(
  assets: number[],
  from?: number,
  until?: number,
  options: Options<DropGroupV1[], T> = {},
) => {
  const organisationId = useOrganisationId();
  const queryKey = dropQueryKeys.dropsV1ForAssets(organisationId, assets, from, until);
  return useQuery({
    queryKey,
    queryFn: async () => {
      if (!from || !until || assets.length === 0) {
        return [];
      }
      return getDropsForAssetsV1(organisationId, assets, from, until);
    },
    enabled: options.enabled !== false && !!(from && until),
    ...options,
  });
};

export const useGetDropsForAssets = <T = DropSummary | undefined>(
  assets: number[],
  from?: number,
  until?: number,
  options: Options<DropSummary | undefined, T> = {},
) => {
  const organisationId = useOrganisationId();
  const queryKey = dropQueryKeys.dropsForAssets(organisationId, assets, from, until);
  return useQuery({
    queryKey,
    queryFn: async () => {
      if (!from || !until || assets.length === 0) {
        return undefined;
      }
      return getDropsForAssets(organisationId, assets, from, until);
    },
    enabled: options.enabled !== false && !!(from && until),
    ...options,
  });
};
