import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { TPButton } from 'components/shared/button/TPButton';
import {
  addSearchPattern,
  closeCreateSearchPatternDialog,
  selectCreateSearchPatternDialogOpen
} from 'slices/searchPatterns.slice';
import {
  ALL_SEARCH_PATTERNS,
  BOX_SEARCH_PATTERNS,
  defaultSearchPattern,
  isSearchPattern,
  SearchPatternType,
} from 'helpers/searchPatterns';
import useFeatureFlag from 'hooks/useFeatureFlag';

export const CreateSearchPatternDialog: React.FC = () => {
  const t = useTranslations('dialogs.searchPatterns');
  const dispatch = useDispatch();
  const [type, setType] = useState<SearchPatternType | ''>('');
  const open = useSelector(selectCreateSearchPatternDialogOpen);
  const enableAllPatterns = useFeatureFlag('frontendSearchPatternsSSRBL');

  const onClose = () => dispatch(closeCreateSearchPatternDialog());
  const onChange = (evt: SelectChangeEvent) => {
    if (isSearchPattern(evt.target.value)) {
      setType(evt.target.value);
    }
  };
  const onSubmit = () => {
    if (type === '') return;
    const sp = defaultSearchPattern(type, t('defaultName', { type }));
    dispatch(addSearchPattern(sp));
    onClose();
  };

  const onExit = () => setType('');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      TransitionProps={{ onExit }}
    >
      <TPDialogTitle>{t('create.title')}</TPDialogTitle>
      <DialogContent>
        <Stack pt={3}>
          <FormControl fullWidth>
            <InputLabel id="search-patterns-select-type-label">{t('type')}</InputLabel>
            <Select
              labelId="search-patterns-select-type-label"
              label={t('type')}
              value={type}
              onChange={onChange}
              variant="outlined"
            >
              {/* TODO: replace with only `ALL_SEARCH_PATTERNS` when retiring `frontendSearchPatternsSSRBL` ff */}
              {(enableAllPatterns ? ALL_SEARCH_PATTERNS : BOX_SEARCH_PATTERNS).map(p => (
                <MenuItem key={p} value={p}>{t(p)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <TPDialogActions>
        <TPButton onClick={onClose} variant="outlined">{t('cancel')}</TPButton>
        <TPButton onClick={onSubmit} disabled={!isSearchPattern(type)}>{t('add')}</TPButton>
      </TPDialogActions>
    </Dialog>
  );
};
