import type {
  LocaleSettingRequestBody,
  OmniboxSettingRequestBody,
  UiSettingRequestBody,
  UnitSettingRequestBody,
} from 'apis/rest/settings/types';
import { HttpResponseError, serenityFetch } from 'helpers/api';

export const updateFirestoreUnitSettings = async (unitSettings: UnitSettingRequestBody): Promise<void> => {
  const response = await serenityFetch('PUT', '/user/settings/units', unitSettings);
  HttpResponseError.detect(response);
};

export const createFirestoreUnitSettings = async (unitSettings: UnitSettingRequestBody): Promise<void> => {
  const response = await serenityFetch('POST', '/user/settings/units', unitSettings);
  HttpResponseError.detect(response);
};

export const updateFirestoreUiSettings = async (uiSettings: UiSettingRequestBody): Promise<void> => {
  const response = await serenityFetch('PUT', '/user/settings/ui', uiSettings);
  HttpResponseError.detect(response);
};

export const createFirestoreUiSettings = async (uiSettings: UiSettingRequestBody): Promise<void> => {
  const response = await serenityFetch('POST', '/user/settings/ui', uiSettings);
  HttpResponseError.detect(response);
};

export const updateFirestoreLocaleSettings = async (localeSettings: LocaleSettingRequestBody): Promise<void> => {
  const response = await serenityFetch('PUT', '/user/settings/locale', localeSettings);
  HttpResponseError.detect(response);
};

export const createFirestoreLocaleSettings = async (localeSettings: LocaleSettingRequestBody): Promise<void> => {
  const response = await serenityFetch('POST', '/user/settings/locale', localeSettings);
  HttpResponseError.detect(response);
};

export const updateFirestoreOmniboxSettings = async (omniboxSettings: OmniboxSettingRequestBody): Promise<void> => {
  const response = await serenityFetch('PUT', '/user/settings/omnibox', omniboxSettings);
  HttpResponseError.detect(response);
};

export const createFirestoreOmniboxSettings = async (omniboxSettings: OmniboxSettingRequestBody): Promise<void> => {
  const response = await serenityFetch('POST', '/user/settings/omnibox', omniboxSettings);
  HttpResponseError.detect(response);
};
