import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { groupsQueryKeys } from './queryKeys';
import { fetchGroups } from './requests';
import type { Group } from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetGroupsList = <T = Group[]>(options?: Options<Group[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = groupsQueryKeys.lists(organisationId);

  return useQuery({
    queryKey,
    queryFn: () => fetchGroups(organisationId),
    ...options,
  });
};
