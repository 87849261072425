import { useMutation } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { sessionQueryKeys } from './sessionQueryKeys';
import { endSession, pauseSession, resumeSession, startSession } from './sessionRequests';

export const useStartSession = () => {
  const organisationId = useOrganisationId();
  return useMutation({
    mutationFn: () => startSession(organisationId),
    mutationKey: sessionQueryKeys.start(),
  });
};

export const useEndSession = () => {
  const organisationId = useOrganisationId();
  return useMutation({
    mutationFn: () => endSession(organisationId),
    mutationKey: sessionQueryKeys.end(),
  });
};

export const usePauseSession = () => {
  const organisationId = useOrganisationId();
  return useMutation({
    mutationFn: () => pauseSession(organisationId),
    mutationKey: sessionQueryKeys.pause(),
  });
};

export const useResumeSession = () => {
  const organisationId = useOrganisationId();
  return useMutation({
    mutationFn: () => resumeSession(organisationId),
    mutationKey: sessionQueryKeys.resume(),
  });
};
