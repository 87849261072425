import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { featureAssetsQueryKeys } from './queryKeys';
import { fetchAllAssetModules } from './requests';
import type { AssetModule } from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetAssetModules = <T = AssetModule[]>(options?: Options<AssetModule[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = featureAssetsQueryKeys.all(organisationId);

  return useQuery({
    queryKey,
    queryFn: () => fetchAllAssetModules(organisationId),
    ...options,
  });
};
