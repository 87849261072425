import { HttpResponseError, serenityFetch } from 'helpers/api';
import type { FeatureDetails, OrganisationFeature, OrganisationFeatureWrapper } from './types';

export const fetchAllFeaturesWithDetails = async (): Promise<FeatureDetails[]> => {
  const response = await serenityFetch('GET', '/features', null);
  HttpResponseError.detect(response);
  return (await response.json()).featureDetails;
};

export const fetchSelectedOrganisationFeatures = async (organisationId: string): Promise<OrganisationFeature[]> => {
  if (!organisationId) {
    return [];
  }
  const response = await serenityFetch('GET', `/organisations/${organisationId}/features/`, null);
  HttpResponseError.detect(response);
  return (await response.json()).features;
};

export const setSelectedOrganisationFeatures = async (
  organisationId: string,
  body: OrganisationFeatureWrapper,
): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/features/`, body);
  HttpResponseError.detect(response);
};

export const setOrganisationFeatureExpiry = async (organisationId: string, featureKey: string): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/features/${featureKey}/trial`, null);
  HttpResponseError.detect(response);
};

export const fetchMarketSectorFeatures = async (marketSectorId: string): Promise<OrganisationFeature[]> => {
  const response = await serenityFetch('GET', `/market-sector/${marketSectorId}/features`, null);
  HttpResponseError.detect(response);
  return (await response.json()).features;
};

export const setMarketSectorFeatures = async (
  marketSectorId: string,
  body: OrganisationFeatureWrapper,
): Promise<void> => {
  const response = await serenityFetch('PUT', `/market-sector/${marketSectorId}/features`, body);
  HttpResponseError.detect(response);
};
