import { getRefreshedToken } from 'apis/auth';
import { wrap } from 'comlink';
import type { FetchReports } from 'workers/fetchReports';

// NOTE: must use relative path to worker
const worker = new Worker(new URL('../../../workers/fetchReports', import.meta.url), {
  name: 'fetchReports',
  type: 'module',
});

const proxy = wrap<FetchReports>(worker);

type Fn<Params extends unknown[], Result> = (...args: Params) => Result;

const withAuthToken =
  <Options extends Record<string, unknown>, Result = unknown>(fn: Fn<[string, Options], Promise<Result>>) =>
  async (options: Options): Promise<Result> => {
    const authToken = await getRefreshedToken();
    return fn(authToken, options);
  };

export const getReports = withAuthToken(proxy.getReports);
export const getReportsHistoric = withAuthToken(proxy.getReportsHistoric);
export const getReportsLatest = withAuthToken(proxy.getReportsLatest);
export const getReportsSummaryForAsset = withAuthToken(proxy.getReportsSummaryForAsset);
export const getReportsForDevices = withAuthToken(proxy.getReportsForDevices);
