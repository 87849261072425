import React, { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { memoize } from 'lodash';
import EventIcon from 'components/shared/icons/eventIcons';
import { hexToRGBArray } from 'helpers/color';
import { getMostSignificantEvent, isPriorityReport, isSignificantReport } from 'helpers/events';
import { InferredEventId } from 'apis/rest/inferredEvents/types';
import { eventFilterSelector } from 'selectors/events';
import { useInferredEventsForReports } from 'repositories/inferredEvents/hooks';
import ColouredIconLayer from './colouredIconLayer';

const ICON_SIZE = 128;

interface DataItem {
  reportId: number
  icon: {
    id: string
    url: string
    height: number
    width: number
    anchorX: number
    anchorY: number
    mask: boolean
  },
  position: [number, number, number]
  object: {
    hi: boolean
  }
}

interface ValidReport extends Report {
  isValid: true
  events: [string, ...string[]]
  inferredEvents: InferredEventId[] | null
}

const triplicate = ((items: DataItem[]) => items.flatMap(item => [
  { ...item, position: [item.position[0] - 360, item.position[1]] },
  item,
  { ...item, position: [item.position[0] + 360, item.position[1]] }
]));

const getIconUrl = memoize((event: string) => `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<EventIcon type={event} size={64} />))}`);

const getReportIcon = memoize((report: ValidReport, use3d: boolean): DataItem => {
  const mostSignificantEvent = getMostSignificantEvent(report).eventId;
  const iconUrl = getIconUrl(mostSignificantEvent);

  return {
    reportId: report.id,
    icon: {
      id: mostSignificantEvent,
      url: iconUrl,
      height: ICON_SIZE,
      width: ICON_SIZE,
      anchorX: ICON_SIZE / 2,
      anchorY: ICON_SIZE / 2,
      mask: false
    },
    position: [report.longitude, report.latitude, use3d ? report.altitude : 0],
    object: {
      hi: true
    }
  };
}, (r, use3d) => `${r.id}:${use3d}`);

const useEventLayers = (
  visible: boolean,
  asset: AssetBasic | undefined,
  hiddenAssets: AssetBasic[],
  assetReports: Report[],
  hideEvents: {
    event: string,
    enabled: boolean,
  }[],
  use3d = false
): [ColouredIconLayer<DataItem>, ColouredIconLayer<DataItem>] => {
  const eventFilter = useSelector(eventFilterSelector);
  const highlightedReportId = useSelector<ReduxState, number | null>(state => state.reports.highlightedReportId);
  const color = useMemo(() => hexToRGBArray(asset?.colour ?? '#fff'), [asset?.colour]);

  const reports = useInferredEventsForReports(assetReports);

  const [data, priorityData] = useMemo(() => {
    if (!asset || !reports) return [];
    if (hiddenAssets.find(a => a.id === asset.id)) return [];

    const eventIcons = reports
      .filter((r): r is ValidReport => r.isValid && isSignificantReport(r) && r.events.every(ev => !eventFilter?.blacklist?.includes(ev)))
      .filter(r => hideEvents.every(hide => (hide.enabled ? !r.events.some(ev => ev.includes(hide.event)) : true)))
      .map(r => getReportIcon(r, use3d));

    const priorityEventIcons = assetReports
      .filter((r): r is ValidReport => r.isValid && isPriorityReport(r) && r.events.every(ev => !eventFilter?.blacklist?.includes(ev)))
      .filter(r => hideEvents.every(hide => (hide.enabled ? !r.events.some(ev => ev.includes(hide.event)) : true)))
      .map(r => getReportIcon(r, use3d));

    return [triplicate(eventIcons), triplicate(priorityEventIcons)];
  }, [asset, hiddenAssets, reports, assetReports, eventFilter?.blacklist, hideEvents, use3d]);

  const layer = useMemo(() => new ColouredIconLayer<DataItem>({
    id: `events-layer-${use3d ? '3d' : '2d'}`,
    data,
    sizeScale: 0.75,
    getColor: color,
    getAngle: 0,
    getSize: d => (d.reportId === highlightedReportId ? 48 : 28),
    parameters: { depthTest: false },
    transitions: { getSize: 225 },
    pickable: false,
    visible,
  }), [use3d, data, color, visible, highlightedReportId]);

  const priorityLayer = useMemo(() => new ColouredIconLayer<DataItem>({
    id: `priority-events-layer-${use3d ? '3d' : '2d'}`,
    data: priorityData,
    sizeScale: 0.75,
    getColor: color,
    getAngle: 0,
    getSize: d => (d.reportId === highlightedReportId ? 48 : 28),
    parameters: { depthTest: false },
    transitions: { getSize: 225 },
    pickable: false,
    visible,
  }), [use3d, priorityData, color, visible, highlightedReportId]);

  return [layer, priorityLayer];
};

export default useEventLayers;
