import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type SearchPattern, getInvalidFields, getLegCount } from 'helpers/searchPatterns';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { v4 as uuidV4 } from 'uuid';

const SAFE_KEYS_TO_UPDATE = [
  'name',
  'notes',
  'origin',
  'orientation',
  'isLocked',
  'isHidden',
  'firstTurnDirection',
  'trackSpacingMetres',
  'legCount',
  'legLengthMetres',
  'turnDirection',
  'orientationArc',
  'legLengthRangeMetres',
];

interface SearchPatternsState {
  createDialogOpen: boolean;
  deleteDialog: {
    open: boolean;
    searchPattern: Pick<SearchPattern, 'id' | 'name'> | undefined;
  };

  overlay: {
    searchPattern: SearchPattern | undefined;
    invalidFields: { field: string; reason: string }[];
  };

  nextOrigin: { lng: number; lat: number } | null;

  searchPatterns: SearchPattern[];
  selected: SearchPattern | undefined;
}

const initialState: SearchPatternsState = {
  createDialogOpen: false,
  deleteDialog: {
    open: false,
    searchPattern: undefined,
  },
  overlay: {
    searchPattern: undefined,
    invalidFields: [],
  },
  nextOrigin: null,
  searchPatterns: [],
  selected: undefined,
};

const toDisplay = (input: SearchPattern) => {
  const sp = { ...input } as SearchPattern;
  sp.orientation = Math.round(sp.orientation);

  if ('legCount' in sp) {
    sp.legCount = getLegCount(sp);
  }

  if ('orientationArc' in sp) {
    sp.orientationArc = Math.round(sp.orientationArc);
  }

  return sp;
};

export const searchPatternsSlice = createSlice({
  name: 'searchPatterns',
  initialState,
  reducers: {
    openCreateSearchPatternDialog: (state, action: PayloadAction<{ latitude: number; longitude: number }>) => {
      state.createDialogOpen = true;
      state.nextOrigin = { lng: action.payload.longitude, lat: action.payload.latitude };
    },
    closeCreateSearchPatternDialog: state => {
      state.createDialogOpen = false;
    },
    openDeleteSearchPatternDialog: (state, action: PayloadAction<Pick<SearchPattern, 'id' | 'name'>>) => {
      state.deleteDialog.open = true;
      state.deleteDialog.searchPattern = action.payload;
    },
    closeDeleteSearchPatternDialog: state => {
      state.deleteDialog.open = false;
    },
    addSearchPattern: (state, action: PayloadAction<Omit<SearchPattern, 'id' | 'origin'>>) => {
      const sp = {
        ...action.payload,
        id: uuidV4(),
        origin: state.nextOrigin ?? { lng: 0, lat: 0 },
      } as SearchPattern;
      state.searchPatterns.push(sp);
      state.selected = sp;
      state.overlay.searchPattern = toDisplay(sp);
      state.overlay.invalidFields = getInvalidFields(sp);
    },
    removeSearchPattern: (state, action: PayloadAction<Pick<SearchPattern, 'id'>>) => {
      state.searchPatterns = state.searchPatterns.filter(sp => sp.id !== action.payload.id);
      if (state.selected?.id === action.payload.id) {
        state.selected = undefined;
        state.overlay.searchPattern = undefined;
        state.overlay.invalidFields = [];
      }
    },
    setSelectedSearchPattern: (state, action: PayloadAction<Partial<Pick<SearchPattern, 'id'>>>) => {
      if (!action.payload?.id) {
        console.debug(`unselected search pattern: ${state.selected?.id}`);
        state.selected = undefined;
        state.overlay.searchPattern = undefined;
        state.overlay.invalidFields = [];
        return;
      }

      const sp = state.searchPatterns.find(s => s.id === action.payload?.id);
      if (!sp) throw new Error(`failed to find search pattern with id ${action.payload.id}`);

      state.selected = sp;
      state.overlay.searchPattern = toDisplay(sp);
      state.overlay.invalidFields = getInvalidFields(state.overlay.searchPattern);
      console.debug(`selected search pattern: ${sp.id}`);
    },
    updateSelectedSearchPattern: (state, action: PayloadAction<Partial<SearchPattern>>) => {
      if (!state.selected?.id) throw new Error('no selected search pattern to update');

      const sp = state.searchPatterns.find(s => s.id === state.selected?.id);
      if (!sp) throw new Error(`could not find search pattern with id ${state.selected.id}`);

      const tmp = { ...sp };
      for (const k of SAFE_KEYS_TO_UPDATE) {
        if (k in action.payload) {
          // @ts-ignore
          tmp[k] = action.payload[k];
        }
      }

      if (getInvalidFields(tmp).length > 0) {
        return;
      }

      for (const k of SAFE_KEYS_TO_UPDATE) {
        if (k in action.payload) {
          // @ts-ignore
          sp[k] = action.payload[k];
        }
      }

      state.selected = sp;
      state.overlay.searchPattern = toDisplay(sp);
    },
    updateSearchPatternOverlay: (state, action: PayloadAction<Partial<SearchPattern>>) => {
      if (!state.selected || !state.overlay.searchPattern) throw new Error('no selected search pattern to update');
      for (const k of Object.keys(action.payload)) {
        // @ts-ignore
        state.overlay.searchPattern[k] = action.payload[k];
      }

      const invalidFields = getInvalidFields(state.overlay.searchPattern);
      state.overlay.invalidFields = invalidFields;

      const sp = state.searchPatterns.find(s => s.id === state.overlay.searchPattern?.id);
      if (!sp) throw new Error(`could not find search pattern with id ${state.overlay.searchPattern?.id}`);

      for (const k of Object.keys(action.payload)) {
        if (!invalidFields.some(f => f.field === k)) {
          // @ts-ignore
          sp[k] = action.payload[k];
        }
      }

      state.selected = sp;
    },
    toggleSearchPatternVisibility: (state, action: PayloadAction<Pick<SearchPattern, 'id'>>) => {
      const sp = state.searchPatterns.find(s => s.id === action.payload.id);
      if (!sp) throw new Error(`could not find search pattern with id ${action.payload}`);

      // sp will be hidden so unselect it
      if (!sp.isHidden && state.selected?.id === sp.id) {
        state.selected = undefined;
        state.overlay.searchPattern = undefined;
      }

      sp.isHidden = !sp.isHidden;
    },
    resetSearchPatterns: state => {
      state.createDialogOpen = false;
      state.deleteDialog = {
        open: false,
        searchPattern: undefined,
      };
      state.overlay = {
        searchPattern: undefined,
        invalidFields: [],
      };
      state.nextOrigin = null;
      state.selected = undefined;
      state.searchPatterns = state.searchPatterns.filter(sp => getInvalidFields(sp).length === 0);
    },
  },
  selectors: {
    selectCreateSearchPatternDialogOpen: state => state.createDialogOpen,
    selectDeleteSearchPatternDialogState: state => state.deleteDialog,
    selectSearchPatterns: state => state.searchPatterns,
    selectSelectedSearchPattern: state => state.selected,
    selectUnselectedSearchPatterns: state => {
      if (!state.selected?.id) return state.searchPatterns;
      return state.searchPatterns.filter(sp => sp.id !== state.selected?.id);
    },
    selectSearchPatternOverlayState: state => state.overlay,
  },
});

export const {
  openCreateSearchPatternDialog,
  closeCreateSearchPatternDialog,
  openDeleteSearchPatternDialog,
  closeDeleteSearchPatternDialog,
  addSearchPattern,
  removeSearchPattern,
  setSelectedSearchPattern,
  updateSelectedSearchPattern,
  updateSearchPatternOverlay,
  toggleSearchPatternVisibility,
  resetSearchPatterns,
} = searchPatternsSlice.actions;
export const {
  selectCreateSearchPatternDialogOpen,
  selectDeleteSearchPatternDialogState,
  selectSearchPatterns,
  selectSelectedSearchPattern,
  selectUnselectedSearchPatterns,
  selectSearchPatternOverlayState,
} = searchPatternsSlice.selectors;

export default persistReducer(
  {
    key: 'searchPatterns',
    whitelist: ['searchPatterns'],
    storage,
  },
  searchPatternsSlice.reducer,
);
