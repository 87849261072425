export type Rock7ConfigSpec = Record<string, Rock7ConfigBlockSpec>;
export type Rock7ConfigBlockSpec = Record<string, string[] | 'boolean' | [number, number]>;

export type Rock7Config = Record<string, Rock7ConfigBlock>;
export type Rock7ConfigBlock = Record<string, string | boolean | number | undefined>;

export type UpdateRock7ConfigRequest = {
  deviceId: number;
  config: Rock7Config;
};

export const createDefaultConfig = (spec: Rock7ConfigBlockSpec): Rock7ConfigBlock =>
  Object.keys(spec).reduce<Rock7ConfigBlock>((acc, key) => {
    const opts = spec[key];
    if (opts === 'boolean') {
      acc[key] = undefined;
    } else if (opts.length === 2 && !Number.isNaN(Number(opts[0])) && !Number.isNaN(Number(opts[0]))) {
      acc[key] = '';
    } else {
      acc[key] = 'unset';
    }
    return acc;
  }, {});

export const isConfigValid = (spec: Rock7ConfigBlockSpec, config: Rock7ConfigBlock): string[] | true => {
  const invalidFields = Object.entries(spec)
    .map(([key, type]) => {
      const configKeys = Object.keys(config);
      if (!configKeys.includes(key)) {
        return null;
      }

      const booleanInvalid =
        type === 'boolean' && (config[key] !== true || config[key] !== false || config[key] !== undefined);
      if (booleanInvalid) {
        return key;
      }

      const numberInvalid =
        type.length === 2 &&
        !Number.isNaN(Number(type[0])) &&
        !Number.isNaN(Number(type[1])) &&
        (config[key] !== '' || Number.isNaN(Number(config[key])));
      return numberInvalid ? key : null;
    })
    .filter(v => v !== null);
  return invalidFields.length > 0 ? (invalidFields as string[]) : true;
};

export const isConfigEmpty = (spec: Rock7ConfigBlockSpec, config: Rock7ConfigBlock): boolean => {
  const defaultConfig = createDefaultConfig(spec);
  return Object.entries(defaultConfig).every(
    ([key, defaultValue]) => Object.keys(config).includes(key) && config[key] === defaultValue,
  );
};
