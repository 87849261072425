import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Grid, Box, Typography, CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import TracplusIcon from 'components/shared/icons/tracplusIcon';
import MicrosoftLoginButton from 'components/shared/microsoftLoginButton';
import GoogleLoginButton from 'components/shared/googleLoginButton';
import LangSelector from 'components/shared/languageSelector';
import useTranslation from 'hooks/useTranslation';
import { loginWithSSO, getRedirectResultLogin } from 'apis/auth';
import CheckboxInput from 'components/shared/forms/inputs/bool';
import { NoHeaderNoFooterLoadingPage } from 'components/pages/loading';
import { legacyLoginAction, loginAction } from 'slices/session/thunks';
import { useAppDispatch } from 'store/types';
import { useSelector } from 'react-redux';
import {
  setSSOSignupCallbackSuccess,
  setSSOLoginCallbackFailure,
  setRememberMe,
  loggedIn as loggedInSelector,
  loginError as loginErrorSelector,
  rememberMe as rememberMeSelector,
  loggingIn as loggingInSelector,
} from 'slices/session/session.slice';
import LoginInput from '../loginInput';
import SubmitButton from '../submitButton';
import useStyles from './login-styles';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation('pages.login');
  const dispatch = useAppDispatch();

  const handleLogin = (emailA, passwordA, rememberMeA) => dispatch(loginAction({ email: emailA, password: passwordA, rememberMe: rememberMeA }));
  const handleLegacyLogin = (usercode, passwordA, rememberMeA) => dispatch(legacyLoginAction({ usercode, password: passwordA, rememberMe: rememberMeA }));
  const handleSubmitSSOSuccess = token => dispatch(setSSOSignupCallbackSuccess(token));
  const handleSubmitSSOFailure = err => dispatch(setSSOLoginCallbackFailure(err));
  const hanldeSetRememberMe = value => dispatch(setRememberMe(value));

  const loggedIn = useSelector(loggedInSelector);
  const loginError = useSelector(loginErrorSelector);
  const rememberMe = useSelector(rememberMeSelector);
  const loggingIn = useSelector(loggingInSelector);

  React.useEffect(() => {
    if (window.location.search.includes('redirect')) {
      setLoading(true);
      getRedirectResultLogin()
        .then(r => {
          if (r !== null) {
            setSubmitted(true);
            handleSubmitSSOSuccess(r);
          } else {
            handleSubmitSSOFailure(Error('User is null. Try logging in again or logging out.'));
            setLoading(false);
          }
        })
        .catch(e => {
          setLoading(false);
          handleSubmitSSOFailure(e);
        });
    } else if (window.location.search.includes('loading')) {
      setLoading(true);
    }
    setError(loginError);
  }, [loginError]);

  if (loggedIn) return <Navigate to="/" replace />;

  const handleInputChange = setField => e => {
    setField(e.target.value);
    setError(null);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    if (email.includes('@')) {
      handleLogin(email, password, rememberMe);
    } else {
      // assume legacy usercode login attempt
      handleLegacyLogin(email, password, rememberMe).then(() => {
        navigate('/invite-viewonly');
      });
    }
  };

  const handleSSOSubmit = (e, provider) => {
    e.preventDefault();
    setSubmitted(true);
    loginWithSSO(provider);
    navigate({
      pathname: '/login',
      search: '?redirect'
    }, { replace: true });
  };

  return (
    <Box className={classes.container}>
      <Helmet>
        <title>{t('pageTitle')} - TracPlus Cloud</title>
      </Helmet>
      <Grid className={classes.gridWrapper} container spacing={0}>
        <Grid item xs={12} sm={5}>
          {loading ? (
            <NoHeaderNoFooterLoadingPage />
          ) : (
            <Box className={classes.formWrapper}>
              <Box className={classes.loginForm}>
                <Box className={classes.iconWrapper}>
                  <TracplusIcon />
                </Box>
                <Typography variant="h1" className={classes.title}>{t('title')}</Typography>
                <Typography variant="body1" className={classes.subtitle}>{t('paragraph')}</Typography>

                <LoginInput
                  type="email"
                  placeholderText={t('emailPlaceholder')}
                  value={email}
                  onChange={handleInputChange(setEmail)}
                  hasError={submitted && !email}
                  onEnterKeypress={handleSubmit}
                />

                <LoginInput
                  type="password"
                  placeholderText={t('passwordPlaceholder')}
                  helperText={t('forgottenPassword')}
                  value={password}
                  onChange={handleInputChange(setPassword)}
                  hasError={submitted && !password}
                  onEnterKeypress={handleSubmit}
                />

                <SubmitButton
                  text={loggingIn ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <CircularProgress size={30} />
                    </Box>
                  ) : t('submitButton')}
                  onSubmit={handleSubmit}
                  style={loggingIn ? { backgroundColor: '#818285' } : {}}
                />

                <MicrosoftLoginButton onClick={handleSSOSubmit} />

                <GoogleLoginButton onClick={handleSSOSubmit} />

                {error && (
                  <Typography className={classes.errorMessage} data-test-id="login-error-message">{t(error)}</Typography>
                )}

                <CheckboxInput
                  label={t('rememberMe')}
                  value={rememberMe}
                  onChange={(id, value) => hanldeSetRememberMe(value)}
                />

                <LangSelector />
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={7}>
          <Box className={classes.imageOfTheWeek} style={{ backgroundImage: 'url(/img/login-background.webp)' }}>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default LoginPage;
