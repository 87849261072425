import type WebMercatorViewport from '@math.gl/web-mercator';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { KdTree } from 'repositories/reports/kdTree';
import type { CursorPosition } from '../../contexts/cursor/cursorContext';
import useDebounce from '../../hooks/useDebounce';
import { getAircraftWithin } from './adsbexchange';
import { adsbQuerykeys } from './queryKeys';
import type { AdsbAircraft, AdsbAircraftKdTreeItem } from './types';

const distance = (a: AdsbAircraftKdTreeItem, b: AdsbAircraftKdTreeItem) =>
  (a.latitude - b.latitude) ** 2 + (a.longitude - b.longitude) ** 2;

const generateKdTree = (aircraft: AdsbAircraft[]) =>
  new KdTree(
    aircraft.map(a => ({
      hex: a.hex,
      latitude: a.lat,
      longitude: a.lon,
    })),
    distance,
    ['latitude', 'longitude'],
  );

export const useAdsbAircraft = (mapId: string, lat: number, lng: number, enabled: boolean) => {
  const queryKey = adsbQuerykeys.forMap(mapId);
  const position = useMemo(() => ({ lat, lng }), [lat, lng]);
  const debouncedPosition = useDebounce(position, 1000);

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const aircraft = await getAircraftWithin(debouncedPosition.lat, debouncedPosition.lng);
      const kdTree = generateKdTree(aircraft);
      return [aircraft, kdTree];
    },
    refetchInterval: 5000,
    enabled,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Refetch on change of position when enabled
  useEffect(() => {
    if (enabled) {
      query.refetch();
    }
  }, [debouncedPosition, enabled]);
  return query;
};

// does not do the refetching
export const useAdsbAircraftDataForMap = (mapId: string) => {
  const queryClient = useQueryClient();
  const queryKey = adsbQuerykeys.forMap(mapId);
  return queryClient.getQueryData(queryKey) as [AdsbAircraft[], KdTree] | undefined;
};

export const useNearestAdsbAircraft = (
  mapId: string,
  wmViewport: WebMercatorViewport,
  cursorPosition: CursorPosition | undefined,
  enabled: boolean,
) => {
  const adsbQuery = useAdsbAircraft(mapId, wmViewport.latitude, wmViewport.longitude, enabled);

  return useMemo(() => {
    if (!cursorPosition || adsbQuery.isLoading || !adsbQuery.data || !enabled) {
      return undefined;
    }
    const adsbAircraft = adsbQuery.data[0] as AdsbAircraft[];
    const adsbKdTree = adsbQuery.data[1] as KdTree;
    const hex = adsbKdTree?.nearest(cursorPosition, 1)?.at(0)?.at(0)?.hex;
    const ac = adsbAircraft?.find(a => a.hex === hex);
    if (!ac?.lon || !ac?.lat) {
      return undefined;
    }
    const projected = wmViewport.project([ac.lon, ac.lat]);
    if (projected[0] > 0 && projected[0] < wmViewport.width && projected[1] > 0 && projected[1] < wmViewport.height) {
      if (Math.hypot(cursorPosition.x - projected[0], cursorPosition.y - projected[1]) < 64) {
        return ac;
      }
    }
    return undefined;
  }, [cursorPosition, adsbQuery.isLoading, adsbQuery.data, enabled, wmViewport]);
};
