import { type UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import { organisationFeaturesQueryKeys } from './queryKeys';
import {
  fetchAllFeaturesWithDetails,
  fetchMarketSectorFeatures,
  fetchSelectedOrganisationFeatures,
  setMarketSectorFeatures,
  setOrganisationFeatureExpiry,
  setSelectedOrganisationFeatures,
} from './requests';
import type {
  FeatureDetails,
  OrganisationFeature,
  SetOrganisationFeatureExpiryVariables,
  SetOrganisationSelectedFeaturesVariables,
} from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetAllFeaturesList = <T = FeatureDetails[]>(options?: Options<FeatureDetails[], T>) => {
  const queryKey = organisationFeaturesQueryKeys.allDetails();
  const query = useQuery({
    queryKey,
    queryFn: () => fetchAllFeaturesWithDetails(),
    staleTime: 60 * 60 * 1000, // 1 hour
    ...options,
  });
  return { query, queryKey };
};

export const useGetOrganisationFeaturesList = <T = OrganisationFeature[]>(
  organisationId: string,
  options?: Options<OrganisationFeature[], T>,
) => {
  const queryKey = organisationFeaturesQueryKeys.org(organisationId);
  const query = useQuery({
    queryKey,
    queryFn: () => fetchSelectedOrganisationFeatures(organisationId),
    ...options,
  });
  return { query, queryKey };
};

export const useSetOrganisationSelectedFeatures = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ organisationId, selectedFeatures }: SetOrganisationSelectedFeaturesVariables) =>
      setSelectedOrganisationFeatures(organisationId, selectedFeatures),
    onSettled: (_data, _error, variables) =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: organisationFeaturesQueryKeys.org(variables.organisationId) }),
      ]),
  });
};

export const useSetOrganisationFeatureExpiry = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ organisationId, featureKey }: SetOrganisationFeatureExpiryVariables) =>
      setOrganisationFeatureExpiry(organisationId, featureKey),
    onSuccess: (_data, variables) =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: organisationFeaturesQueryKeys.org(variables.organisationId) }),
      ]),
  });
};

export const useGetMarketSectorFeaturesList = <T = OrganisationFeature[]>(
  marketSectorId: string,
  options?: Options<OrganisationFeature[], T>,
) => {
  const queryKey = organisationFeaturesQueryKeys.sector(marketSectorId);
  const query = useQuery({
    queryKey,
    queryFn: () => fetchMarketSectorFeatures(marketSectorId),
    ...options,
  });
  return query;
};

export const useSetMarketSectorSelectedFeatures = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ organisationId, selectedFeatures }: SetOrganisationSelectedFeaturesVariables) =>
      setMarketSectorFeatures(organisationId, selectedFeatures),
    onSettled: (_data, _error, variables) =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: organisationFeaturesQueryKeys.sector(variables.organisationId) }),
      ]),
  });
};
