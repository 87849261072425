import { HttpResponseError, serenityFetch } from 'helpers/api';
import type { AssetModule, AssetModulesResponse } from './types';

export const fetchAllAssetModules = async (organisationId: string): Promise<AssetModule[]> => {
  if (!organisationId) throw new Error('Organisation ID must be provided');

  const response = await serenityFetch('GET', `/v2/organisations/${organisationId}/features`, null);
  HttpResponseError.detect(response);

  return ((await response.json()) as AssetModulesResponse).bundles;
};
