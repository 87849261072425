import { ButtonBase, styled, Tooltip } from '@mui/material'
import { useTranslations } from 'use-intl';
import TvIcon from '@mui/icons-material/Tv';
import { useAppDispatch } from 'store/useAppDispatch';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { updateSetting } from 'slices/settings.slice';
import { updateLocalUiSettingsField, updateUiSettingField } from 'slices/settings/uiSettings.slice';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import { toggleAnalysisbox, toggleContextbox, toggleOmnibox } from 'slices/ui.slice';
import { useAppSelector } from 'store/useAppSelector';

const DisplayModeButton = styled(ButtonBase)(({ theme, value }) => `
  color: ${theme.palette.common.textConstant};
  padding: ${theme.spacing(0, 3)};
  border-left: ${theme.border.default};
  background-color: ${value ? theme.palette.common.lightBlue : 'transparent'};
  &:hover {
    background-color: ${theme.palette.common.lightBlue};
  },
`);

const toggleFullscreen = (value: boolean) => {
  const elemAsAny = document.documentElement as any
  const docAsAny = document as any
  if (value && !elemAsAny.fullscreenElement) {
    if (elemAsAny.requestFullscreen) {
      elemAsAny.requestFullscreen();
    } else if (elemAsAny.mozRequestFullScreen) {
      elemAsAny.mozRequestFullScreen();
    } else if (elemAsAny.webkitRequestFullscreen) {
      elemAsAny.webkitRequestFullscreen();
    } else if (elemAsAny.msRequestFullscreen) {
      elemAsAny.msRequestFullscreen();
    }
  } else if (!value && document.fullscreenElement) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (docAsAny.mozCancelFullScreen) {
      docAsAny.mozCancelFullScreen();
    } else if (docAsAny.webkitExitFullscreen) {
      docAsAny.webkitExitFullscreen();
    } else if (docAsAny.msExitFullscreen) {
      docAsAny.msExitFullscreen();
    }
  }
}

export const DisplayModeToggleButton = () => {
  const t = useTranslations('statusbar.displayMode')
  const appDispatch = useAppDispatch();
  const isUsercodeUser = useIsUsercodeLogin();
  const isActive = useUiSettings().displayMode ?? false
  const isOmniboxExpanded = useAppSelector(state => state.ui.omniboxOpen);
  const isContextboxExpanded = useAppSelector(state => state.ui.contextboxOpen);
  const isAnalysisBoxExpanded = useAppSelector(state => state.ui.analysisboxOpen);

  const handleSettingChange = (event: React.MouseEvent<HTMLElement>, newValue: boolean, fieldId: keyof ReduxState['uiSettings']['ui']) => {
    if (isUsercodeUser) {
      appDispatch(updateSetting({ category: 'ui', field: fieldId as keyof ReduxState['settings']['ui'], value: newValue }));
      appDispatch(updateLocalUiSettingsField({ field: fieldId as keyof ReduxState['settings']['ui'], value: newValue }));
    } else {
      appDispatch(updateUiSettingField({ field: fieldId as keyof ReduxState['settings']['ui'], value: newValue }));
    }
    toggleFullscreen(newValue)
    if (newValue === isOmniboxExpanded) {
      appDispatch(toggleOmnibox())
    }
    // For some reason contextboxOpen value is opposite of expected
    // true => closed; false => expanded
    if (newValue !== isContextboxExpanded) {
      appDispatch(toggleContextbox())
    }
    if (newValue && isAnalysisBoxExpanded) {
      appDispatch(toggleAnalysisbox());
    }
    var supportBtn = document.getElementById('freshworks-container')
    if (supportBtn) {
      supportBtn.style.display = newValue ? 'none' : 'inherit';
    }
  };

  return (<Tooltip title={t('tooltip')} placement="top">
    <DisplayModeButton value={isActive ? "true" : undefined} onClick={evt => handleSettingChange(evt, !isActive, 'displayMode')} aria-label={t('tooltip')}>
      <TvIcon />
    </DisplayModeButton>
  </Tooltip>)
}
