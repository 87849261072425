import { Box, Button, Grid, Typography } from '@mui/material';
import Maps from 'components/maps';
import Translation from 'components/shared/translate';
import useAllFeatureAssets from 'contexts/featureAssets/useAllFeatureAssets';
import { noticeError } from 'helpers/newRelic';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { MapTemplate } from 'mapTemplates/reactmapgl/types';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { MapProvider } from 'react-map-gl';
import { useSelector } from 'react-redux';
import { MapSettings, closeMap, setAssetFollow, setBaseLayer } from 'slices/map.slice';
import {
  selectMeasurementState,
  setMeasurementState,
  toggleMeasurementState
} from 'slices/map/multiPointMeasurement.slice';
import { setSelectedReport } from 'slices/report.slice';
import { resetSearchPatterns } from 'slices/searchPatterns.slice';
import { useAppDispatch } from 'store/types';
import { useTranslations } from 'use-intl';
import { useAssetLabel } from '../shared/assetLabel';
import { useManageMapWithTemplate } from './behaviourHooks/useManageMapWithTemplate';
import MapControls from './modules/controls';
import SettingsDrawer from './modules/settingsDrawer';
import { useKmlManager } from './modules/settingsDrawer/modules/kmlLoader/useKmlManager';

const trackingPanelStyle = {
  position: 'absolute',
  left: '50%',
  bottom: '60px',
  transform: 'translateX(-50%)',
  display: 'inline-block',
  mt: '10px',
  p: 2,
  borderRadius: 2,
  overflow: 'hidden',
  opacity: 1,
  textAlign: 'center',
  zIndex: 999,
  boxShadow: 'none',
  '& p': {
    fontWeight: 500,
    fontSize: '1rem',
    color: 'common.whiteConstant'
  },
};

const logError = (error: Error) => {
  console.error(error);
  noticeError(error);
};

// NOTE: looks like a component but it isn't one so hooks can't be used
const fallbackRender = ({ resetErrorBoundary }) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 1,
    alignItems: 'center',
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRow: 'span 1',
    gridColumn: 'span 1',
  }}>
    <Typography><Translation render={t => t('pages.map.error.message')} /></Typography>
    <Button variant="contained" size="large" onClick={resetErrorBoundary}><Translation render={t => t('pages.map.error.reset')} /></Button>
  </Box>
);

interface MapProps {
  index: number;
  xs: number;
  config: MapSettings;
  template: MapTemplate;
  selected: boolean;
  onMouseDown: (e: MouseEvent) => void;
  selectedItemForThisMap: AssetBasic;
  kmlFilenames: string[];
  refetchKmls: () => void;
}

const Map = ({
  index,
  xs,
  config,
  template,
  selected,
  onMouseDown,
  selectedItemForThisMap,
  kmlFilenames,
  refetchKmls
}: MapProps) => {
  const t = useTranslations('pages.map.mapControls');
  const t2 = useTranslations('pages.map.template');

  const featureAssets = useAllFeatureAssets();
  const assetLabel = useAssetLabel();
  const MapInstance = Maps[config.baseLayerLibrary];
  const isClosable = config.id !== 'default';

  const highContrastControls = useSelector((state: ReduxState) => state.settings.map.highContrastControls);
  const follow = useSelector((state: ReduxState) => state.map.assetsAreBeingFollowedOnMaps[config.id]);
  const selectedReport = useSelector((state: ReduxState) => state.reports.selectedReportPerMap[config.id]);
  const measurementState = useSelector(selectMeasurementState);
  const dispatch = useAppDispatch();

  const [measureToggle, setMeasureToggle] = useState(false);
  const [distanceRingsToggle, setDistanceRingsToggle] = useState(false);
  const [velocityLeadersToggle, setVelocityLeadersToggle] = useState(false);
  const [reportToggle, setReportToggle] = useState(false);
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);
  const [visibleKmls, kmlOrder, kmlStatus, setKmlOrder, setKmlStatus] = useKmlManager(config.id, kmlFilenames);

  // feature flag
  const multipointMeasurementFeature = useFeatureFlag('multipointMeasurementTool');

  const measurementModeEnabled = useMemo(() => {
    if (multipointMeasurementFeature) {
      return measurementState !== 'disabled';
    }
    return measureToggle;
  }, [measureToggle, measurementState, multipointMeasurementFeature]);

  // Measurement panel toggle on and off
  const toggleMeasurePanel = useCallback(() => {
    if (multipointMeasurementFeature) {
      dispatch(toggleMeasurementState());
    } else {
      setMeasureToggle(!measureToggle);
    }
    setReportToggle(false);
  }, [dispatch, measureToggle, multipointMeasurementFeature]);

  const disableMeasurementMode = useCallback(() => {
    if (multipointMeasurementFeature) {
      dispatch(setMeasurementState('disabled'));
    } else {
      setMeasureToggle(false);
    }
  }, [dispatch, multipointMeasurementFeature]);

  const setKmlOrderAndRefetch = useCallback(o => {
    setKmlOrder(o);
    refetchKmls();
  }, [setKmlOrder, refetchKmls]);

  const followAsset = useCallback(() => {
    dispatch(setAssetFollow({ mapId: config.id, isFollowed: !follow }));
  }, [config.id, dispatch, follow]);

  useEffect(() => {
    if (selected) dispatch(setSelectedReport({ mapId: config.id, report: null }));
  }, [config.id, selected, dispatch]);

  useEffect(() => {
    if (template.feature && template.replacementId && !featureAssets.isLoading && !featureAssets.getFeature(template.feature).some) {
      dispatch(setBaseLayer({ mapId: config.id, baseLayer: { name: template.name, library: template.library, id: template.replacementId } }));
    }
  }, [config.id, dispatch, featureAssets, template]);

  const [minZoom, maxZoom] = useManageMapWithTemplate(config.id, template);

  // Settings Drawer show and close logic
  const handleSettingsDrawerOpen = () => setSettingsDrawerOpen(true);
  const handleSettingsDrawerClose = () => setSettingsDrawerOpen(false);

  // Distance rings toggle on and off
  const toggleDistanceRings = () => {
    setDistanceRingsToggle(v => !v);
  };

  // Velocity leaders toggle on and off
  const toggleVelocityLeaders = () => {
    setVelocityLeadersToggle(v => !v);
  };

  const closeTheMap = () => {
    dispatch(closeMap(config.id));
  };

  return (
    <Grid item xs={xs} key={`grid${index}`} sx={{ position: 'relative', display: 'grid' }}>
      <ErrorBoundary
        fallbackRender={fallbackRender}
        onError={logError}
        onReset={() => {
          disableMeasurementMode();
          setDistanceRingsToggle(false);
          setVelocityLeadersToggle(false);
          setReportToggle(false);
          dispatch(resetSearchPatterns());
        }}
      >
        <MapProvider>
          <MapInstance
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              gridRow: 'span 1',
              gridColumn: 'span 1'
            }}
            key={config.id}
            template={template}
            config={config}
            onMouseDown={onMouseDown}
            selected={selected}
            minZoom={minZoom}
            maxZoom={maxZoom}
            follow={follow}
            measureToggle={measurementModeEnabled}
            distanceRingsToggle={distanceRingsToggle}
            velocityLeadersToggle={velocityLeadersToggle}
            selectedReport={selectedReport}
            kmlFilenames={visibleKmls}
          >
            {follow && selectedItemForThisMap && (
              <Box
                aria-label="trackingPanel"
                sx={{
                  ...(trackingPanelStyle),
                  backgroundColor: highContrastControls ? 'mapControls.bgHighContrast' : 'rgba(0,0,0,.4)',
                }}>
                <Typography>{`${t('following')}: ${assetLabel(selectedItemForThisMap)}`}</Typography>
              </Box>
            )}
          </MapInstance>
          <Box
            sx={{
              position: 'absolute',
              bottom: 20,
              left: 20,
              ...(selected && {
                display: 'block',
              })
            }}
          >
            <MapControls
              provider={t2(`provider.${template.providerId}`)}
              handleSettingsDrawerOpen={handleSettingsDrawerOpen}
              toggleMeasurePanel={toggleMeasurePanel}
              toggleDistanceRings={toggleDistanceRings}
              toggleVelocityLeaders={toggleVelocityLeaders}
              measureToggle={measurementModeEnabled}
              distanceRingsToggle={distanceRingsToggle}
              velocityLeadersToggle={velocityLeadersToggle}
              closeMap={closeTheMap}
              isClosable={isClosable}
              highContrastControls={highContrastControls}
              controlsVisible={selected}
              minZoom={minZoom}
              maxZoom={maxZoom}
            />
          </Box>
        </MapProvider>
      </ErrorBoundary>



      <SettingsDrawer
        settingsDrawerOpen={settingsDrawerOpen}
        handleSettingsDrawerClose={handleSettingsDrawerClose}
        config={config}
        kmlOrder={kmlOrder}
        kmlStatus={kmlStatus}
        setKmlOrder={setKmlOrderAndRefetch}
        setKmlStatus={setKmlStatus}
      />
    </Grid>
  );
};

export default Map;
