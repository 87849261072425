import { type QueryClient, type UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAsset } from 'apis/rest/assets/requests';
import { cellToLatLng } from 'h3-js';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { LngLat } from 'mapbox-gl';
import { useMemo } from 'react';
import { geonameQueryKeys } from './queryKeys';
import {
  type ConvertGeonameItem,
  type GeocodedLocation,
  type Geoname,
  type Geonames,
  convertGeonames,
  reverseGeocodeBatch,
} from './requests';

type Options<QueryData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError>,
  'queryKey' | 'queryFn' | 'placeholderData' | 'staleTime' | 'cacheTime'
>;

// collate geonames from all previously queried report geonames
export const getKnownGeonames = (queryClient: QueryClient, organisationId: string) =>
  queryClient
    .getQueriesData<Geonames>({ queryKey: geonameQueryKeys.listsConvert(organisationId) })
    .reduce<Geonames>((acc, item) => ({ ...acc, ...item[1] }), {});

export const getNearbyGeonames = (reports: Pick<Report, 'id' | 'longitude' | 'latitude'>[], known: Geonames) => {
  const knownArray = Object.values(known).filter((g): g is Geoname => !!g);

  return reports.reduce<Geonames>((acc, r) => {
    const coords = new LngLat(r.longitude, r.latitude);

    // lookup matching report from known geonames
    if (r.id in known) {
      acc[r.id] = known[r.id];
      return acc;
    }

    // lookup known geonames to find the closest within 100m of each report
    const [closest] = knownArray.reduce<[Geoname, number] | [undefined, undefined]>(
      (result, geoname) => {
        // quick throw away of any with latitude difference of more than 1111m
        if (Math.abs(r.latitude - geoname.latitude) > 0.01) return result;

        const distance = coords.distanceTo(new LngLat(geoname.longitude, geoname.latitude));
        if (distance > 100) return result;
        if (result[1] === undefined) return [geoname, distance];
        if (result[1] > distance) return [geoname, distance];
        return result;
      },
      [undefined, undefined],
    );

    if (closest) acc[r.id] = closest;
    return acc;
  }, {});
};

export const bucketReports = (reports: Pick<Report, 'id' | 'longitude' | 'latitude'>[]) => {
  const { coordinates, mappings } = reports.reduce<{
    coordinates: ConvertGeonameItem[];
    mappings: Record<number, number>;
    tree: Record<number, Record<number, number>>;
  }>(
    (acc, r, index) => {
      // coordinates rounded to thousandths to give buckets of about 111m×111m
      const latitude = Math.round(r.latitude * 1000) / 1000;
      const longitude = Math.round(r.longitude * 1000) / 1000;

      if (latitude in acc.tree) {
        if (longitude in acc.tree[latitude]) {
          acc.mappings[r.id] = acc.tree[latitude][longitude];
          return acc;
        }
      } else {
        acc.tree[latitude] = {};
      }

      acc.tree[latitude][longitude] = index;
      acc.mappings[r.id] = index;
      acc.coordinates.push({ index, latitude, longitude });

      return acc;
    },
    { coordinates: [], mappings: {}, tree: {} },
  );

  return { coordinates, mappings };
};

export const useGetGeonamesForReports = (reports: Report[], options?: Options<Geonames>) => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  const queryKey = geonameQueryKeys.listsConvertReports(organisationId, reports.map(r => r.id).sort());
  const known = useMemo(() => getKnownGeonames(queryClient, organisationId), [queryClient, organisationId]);

  // lookup each report's geoname from already known set
  const placeholderData = useMemo(
    () =>
      reports.reduce<Geonames>((acc, r) => {
        if (r.id in known) acc[r.id] = known[r.id];
        return acc;
      }, {}),
    [known, reports],
  );

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const nearby = getNearbyGeonames(reports, known);

      // store found nearby geonames in query client
      if (Object.keys(nearby).length) {
        const foundReportIds = reports
          .map(r => r.id)
          .filter(id => id in nearby)
          .sort();
        queryClient.setQueryData<Geonames>(
          geonameQueryKeys.listsConvertReports(organisationId, foundReportIds),
          nearby,
        );
      }

      const remainingReports = reports.filter(r => !(r.id in nearby));

      // if there are no remaining reports to lookup from the server we return the nearby set
      if (!remainingReports.length) return nearby;

      // group remaining reports into buckets of lowered precision coordinates to reduce the number of items search on the server side
      const { coordinates, mappings } = bucketReports(remainingReports);

      const bucketResults = await convertGeonames(organisationId, { coordinates });

      // map each report ID to the geoname for its bucket
      const out = remainingReports.reduce<Geonames>((acc, r) => {
        acc[r.id] = bucketResults[mappings[r.id]];
        return acc;
      }, {});

      return { ...nearby, ...out };
    },
    staleTime: Number.POSITIVE_INFINITY,
    placeholderData,
    ...options,
  });

  return { query, queryKey };
};

export const useGetGeocodedLocationByReportId = (
  assetId: number | undefined,
  coords: Pick<Report, 'id' | 'latitude' | 'longitude'>[],
) => {
  const organisationId = useOrganisationId();

  const queryKey = geonameQueryKeys.locationsByReportId(coords.map(r => r.id));

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const category = assetId ? (await getAsset(organisationId, assetId))?.category : 'Unknown';

      const geocodingCache: GeocodedLocation[] = sessionStorage?.geocodingCache
        ? JSON.parse(sessionStorage?.geocodingCache).filter((g: GeocodedLocation) => g.category)
        : [];
      const remainingCoords = coords.filter(
        c => !geocodingCache.some(g => g.lat === c.latitude && g.lon === c.longitude),
      );

      const geocodedLocations = remainingCoords.length > 0 ? await reverseGeocodeBatch(category, remainingCoords) : [];
      const updatedCache = geocodingCache.concat(geocodedLocations);
      sessionStorage.setItem('geocodingCache', JSON.stringify(updatedCache));

      return updatedCache.reduce<Record<number, string>>((acc, loc) => {
        const reportId = coords.find(r => r.latitude === loc.lat && r.longitude === loc.lon)?.id;
        if (reportId) acc[reportId] = loc.location;
        return acc;
      }, {});
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
  return { query, queryKey };
};

export const useGetGeocodedLocationByH3Index = (h3Index: string) => {
  const organisationId = useOrganisationId();

  const queryKey = geonameQueryKeys.h3Index(organisationId, h3Index);
  const [lat, lng] = cellToLatLng(h3Index);
  const query = useQuery({
    queryKey,
    queryFn: async () => (await reverseGeocodeBatch('Unknown', [{ latitude: lat, longitude: lng }]))[0],
  });
  return query;
};
