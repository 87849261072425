import { HttpResponseError, serenityFetch } from 'helpers/api';

export interface ConvertGeonameItem {
  index: number;
  latitude: number;
  longitude: number;
}

export interface Geoname {
  name: string;
  latitude: number;
  longitude: number;
}

interface GeocodingResponse {
  name: string;
  position: [number, number];
}

export interface GeocodedLocation {
  lat: number;
  lon: number;
  location: string;
  category: string;
}

export type Geonames = Record<number, Geoname | null>;

export const convertGeonames = async (
  organisationId: string,
  body: { coordinates: ConvertGeonameItem[] },
): Promise<Geonames> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/geonames/convert`, body);
  HttpResponseError.detect(response);
  return (await response.json()).results;
};

export const reverseGeocodeBatch = async (
  assetCategory: string,
  positions: { latitude: number; longitude: number }[],
): Promise<GeocodedLocation[]> => {
  const positionsString = positions.map(({ latitude, longitude }) => `${longitude},${latitude}`).join(';');
  const response = await serenityFetch('POST', `/geocoding/batch/${assetCategory}`, {
    positions: positionsString,
  });
  HttpResponseError.detect(response);
  return (await response.json()).responses.map((p: GeocodingResponse) => ({
    location: p.name,
    lon: p.position[0],
    lat: p.position[1],
    category: assetCategory,
  }));
};
