import { useEffect, useMemo, useState } from 'react';
import { minBy, maxBy } from 'lodash/fp';
import { type QueryClient, useQueries, useQuery, useQueryClient, type UseQueryOptions, type UseQueryResult } from '@tanstack/react-query';
import { type GeocodedLocation, reverseGeocodeBatch } from 'apis/rest/geocoding/requests';
import type { InferredEventId, ReportWithInferredEvents } from 'apis/rest/inferredEvents/types';
import { useAssetsInferredEventsByReportId, useInferredEventsForReports } from 'repositories/inferredEvents/hooks';
import { eventDoesntMakeSense, getMostSignificantEvent } from './events';
import { useAssetsReports } from 'repositories/reports/hooks';
import { noticeError } from './newRelic';
import { di } from '../di';

const consoleDep = di.depend('console');

export const eventMap = {
  EVT_STARTUP: 'S',
  EVT_SHUTDOWN: 's',
  EVT_TAKEOFF: 'T',
  EVT_LANDING: 'L',
  EVT_ENGINEON: 'S',
  EVT_ENGINEOFF: 's',
  INFERRED_MOVEMENT_START: 'S',
  INFERRED_MOVEMENT_END: 's',
  INFERRED_TAKEOFF: 'T',
  INFERRED_LANDING: 'L',
};
export const legEventTypes = Object.keys(eventMap).join(',');
export const stopEvents = [
  eventMap.EVT_ENGINEOFF,
  eventMap.EVT_SHUTDOWN,
  eventMap.EVT_LANDING,
  eventMap.INFERRED_MOVEMENT_END,
  eventMap.INFERRED_LANDING,
];

// Disables inferred events if there is any corresponding real event
const calcInferredEventsToDisable = (reports: ReportWithInferredEvents[]): readonly (InferredEventId | string)[] => {
  const disabled: InferredEventId[] = [];
  const events = reports.flatMap(r => r.events);
  if (events.some(e => ['EVT_STARTUP', 'EVT_ENGINEON'].includes(e))) disabled.push('INFERRED_MOVEMENT_START');
  if (events.some(e => ['EVT_SHUTDOWN', 'EVT_ENGINEOFF'].includes(e))) disabled.push('INFERRED_MOVEMENT_END');
  if (events.some(e => ['INFERRED_TAKEOFF'].includes(e))) disabled.push('INFERRED_TAKEOFF');
  if (events.some(e => ['INFERRED_LANDING'].includes(e))) disabled.push('INFERRED_LANDING');
  return disabled as readonly InferredEventId[];
};

const reducePrecision = (coord?: number): number => +parseFloat(String(coord)).toFixed(5);
const reduceReportPrecision = (reports: Report[], leg: LegRaw): { startLat: number; startLon: number; endLat: number, endLon: number } => {
  const validReports = reports.filter(r => r.isValid && r.longitude !== 0 && r.latitude !== 0);
  const firstValidReport = minBy(r => r.received, validReports);
  const lastValidReport = maxBy(r => r.received, validReports);

  return ({
    startLat: reducePrecision(firstValidReport?.latitude),
    startLon: reducePrecision(firstValidReport?.longitude),
    endLat: reducePrecision(leg.end !== undefined ? lastValidReport?.latitude : undefined),
    endLon: reducePrecision(leg.end !== undefined ? lastValidReport?.longitude : undefined)
  });
};
// Regex that define legs, once mapped using eventMap
const legRegex = /S?TL(?!s)|TS*Ls?|Ss|STLs/g;

interface LegRaw {
  start: number;
  end?: number;
  takeoff?: number;
  landing?: number
}

const findEvents = (reports: ReportWithInferredEvents[], eventId: string, inferredEventId: InferredEventId, reversed = false) => {
  const reportsOrdered = reversed ? [...reports].reverse() : reports;
  const takeoff = reportsOrdered.find(r => r.events.includes(eventId));
  if (takeoff !== undefined) {
    return takeoff;
  }
  return reportsOrdered.find(r => r.inferredEvents?.includes(inferredEventId));
};

export const getLegMatches = (legsStringMapped: string, legIndices: number[], reports: ReportWithInferredEvents[]): LegRaw[] => {
  let lastEvent = 0;
  let firstEvent = legIndices.length;

  const legMatches: LegRaw[] = [...legsStringMapped.matchAll(legRegex)].map(match => {
    const legStart = match.index || 0;
    const legEnd = legStart + match[0].length - 1;
    lastEvent = Math.max(lastEvent, legEnd);
    firstEvent = Math.min(firstEvent, legStart);

    const indices: LegRaw = {
      start: legIndices[legStart],
      end: legIndices[legEnd],
    };

    return indices;
  });

  const lastIndex = legMatches.at(-1)?.end ?? -1;
  if (lastIndex !== reports.length - 1) {
    // find start event after last complete leg
    const startAfter = legIndices.filter((ri, si) => ri > lastIndex && !stopEvents.includes(legsStringMapped[si])).at(0);
    if (startAfter !== undefined) {
      legMatches.push({ start: startAfter });
    }
  }

  if (legMatches.length === 0 && reports.length > 1) {
    const firstReportIsShutdown = legIndices.at(0) === 0 && stopEvents.includes(legsStringMapped.at(0) ?? '');
    if (!firstReportIsShutdown) {
      legMatches.push({ start: 0, end: legIndices.at(0) });
    }
  }

  return legMatches;
}

export const processLegs = async (legsStringMapped: string, legIndices: number[], reports: ReportWithInferredEvents[], existingLegs: Leg[], assetCategory: string, ignoreGeocoding = false): Promise<Leg[]> => {
  const legMatches = getLegMatches(legsStringMapped, legIndices, reports);

  // 1. make list of leg lat/lons that aren't in geocodingCache
  const locationsToGeocode: { latitude: number, longitude: number }[] = [];
  let updatedCache: GeocodedLocation[] = [];
  if (!ignoreGeocoding && legMatches.length) {
    const t0 = performance.now();
    // TODO: cache in IndexedDB instead of local storage because the size could exceed the quota
    let geocodingCache: GeocodedLocation[] = [];
    if (!ignoreGeocoding && localStorage?.geocodingCache) {
      try {
        geocodingCache = JSON.parse(localStorage.getItem('geocodingCache') ?? '').filter((g: GeocodedLocation) => g.category);
      } catch (error) {
        noticeError(new Error('Failed to read geocoding cache from local storage'), {
          originalErrorMessage: (error as Error).message,
        });
      }
    }
    const t1 = performance.now();

    // TODO: this is horrifically inefficient, needs an implementation that doesn't have 4 nested loops
    legMatches.forEach(leg => {
      const legReports = reports.slice(leg.start, leg.end === undefined ? undefined : leg.end + 1);

      const {
        startLat, startLon, endLat, endLon
      } = reduceReportPrecision(legReports, leg);
      const cachedStartLocation = geocodingCache.find(g => g.lat === startLat && g.lon === startLon && g.category === assetCategory) || locationsToGeocode.find(g => g.latitude === startLat && g.longitude === startLon);
      const cachedEndLocation = geocodingCache.find(g => g.lat === endLat && g.lon === endLon && g.category === assetCategory) || locationsToGeocode.find(g => g.latitude === endLat && g.longitude === endLon);
      if (!cachedStartLocation) locationsToGeocode.push({ latitude: startLat, longitude: startLon });
      if (!cachedEndLocation) locationsToGeocode.push({ latitude: endLat, longitude: endLon });
    });
    const t2 = performance.now();

    // 2. fetch locations for above list and add it to cache
    const validLocationsToGeocode = locationsToGeocode.filter(l => !Number.isNaN(l.latitude) && !Number.isNaN(l.longitude));
    const geocodedLocations = validLocationsToGeocode.length > 0 ? await reverseGeocodeBatch(assetCategory, validLocationsToGeocode) : [];
    const t3 = performance.now();

    if (geocodedLocations.length) {
      updatedCache = geocodingCache.concat(geocodedLocations);
      try {
        localStorage.setItem('geocodingCache', JSON.stringify(updatedCache));
      } catch (error) {
        noticeError(new Error('Failed to persist geocoding cache to local storage'), {
          originalErrorMessage: (error as Error).message,
          locationCount: updatedCache.length,
        });
      }
    }
    const t4 = performance.now();

    const console = consoleDep.inject('Legs');
    console.debug('Leg geocoding for device', {
      deviceId: reports[0].deviceId,
      legs: legMatches.length,
      uncachedLocations: validLocationsToGeocode.length,
      readCache: `${t1 - t0} ms`,
      findCachedLocations: `${t2 - t1} ms`,
      geocode: `${t3 - t2} ms`,
      writeCache: `${t4 - t3} ms`,
    });
  }

  // 3. return legs with locations from geocodingCache
  return legMatches.flatMap(leg => {
    // return existing leg if it exists instead of requesting geocoding again
    const existingLeg = existingLegs?.length && existingLegs?.find(l => l.id === reports[leg.start].id);
    if (existingLeg) return [];

    const legReports = reports.slice(leg.start, leg.end === undefined ? undefined : leg.end + 1);

    // reject if no reports in the leg have a valid position
    if (legReports.every(r => !r.isValid)) return [];

    const takeoff = findEvents(legReports, 'EVT_TAKEOFF', 'INFERRED_TAKEOFF');
    const landing = findEvents(legReports, 'EVT_LANDING', 'INFERRED_LANDING', true);

    // get from/to from cache and return
    const {
      startLat, startLon, endLat, endLon
    } = reduceReportPrecision(legReports, leg);
    const from = updatedCache.find(g => g.lat === startLat && g.lon === startLon)?.location;
    const to = updatedCache.find(g => g.lat === endLat && g.lon === endLon)?.location;

    // If this leg doesn't have an end, use the time of the most recent report as the end for accurate elapsed time
    const endReport = legReports.at(-1);

    return [{
      id: reports[leg.start].id,
      deviceId: reports[leg.start].deviceId,
      assetId: reports[leg.start].assetId,
      start: reports[leg.start].received,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      end: endReport!.received,
      from,
      to,
      complete: leg.end !== undefined && leg.end !== leg.start,
      takeoff: takeoff?.received || null,
      landing: landing?.received || null,
      reports: {
        start: reports[leg.start],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        end: endReport!,
        takeoff,
        landing,
      },
    }];
  });
};

export interface PreProcessedLegs {
  legStringMapped: string
  legIndices: number[]
  reportsAsc: ReportWithInferredEvents[]
}

export const preProcessLegs = (reports: ReportWithInferredEvents[], existingLegs: Leg[], assetCategory: string, deviceMake: string | null): PreProcessedLegs => {
  if (reports.length === 0) return ({
    legStringMapped: '',
    legIndices: [],
    reportsAsc: [],
  });
  // Create string representation of leg starts/ends
  // Create list of indices of reports relating to string representation
  let legStringMapped = '';
  const legIndices: number[] = [];
  const reportsAsc = reports.slice(0).sort((a, b) => a.received - b.received);
  const disabledInferredEvents = calcInferredEventsToDisable(reportsAsc);
  reportsAsc.forEach((report, index) => {
    const { eventId } = getMostSignificantEvent(report);
    if (disabledInferredEvents.includes(eventId)) {
      // do not include inferred events if they are disabled, `getMostSignificantEvent` will prioritise real events
      // over inferred events so we don't need to check if `eventId` is an inferred event first
      return;
    }
    if (eventId && Object.keys(eventMap).includes(eventId) && !eventDoesntMakeSense(report, assetCategory, deviceMake)) {
      // @ts-ignore
      const mapped = eventMap[eventId];
      // compress SSss to Ss, with the last S and the first s
      if (legStringMapped.at(-1) !== mapped) {
        legStringMapped += mapped;
        legIndices.push(index);
      } else {
        legIndices[legIndices.length - 1] = index;
      }
    }
  });
  return ({
    legStringMapped: legStringMapped,
    legIndices: legIndices,
    reportsAsc: reportsAsc
  });
};

export const getLegsQueryKey = (reportsForAsset: Report[], numInferredEvents: number | undefined, options: { ignoreGeocoding?: boolean } = {}) => (
  ['legs', reportsForAsset[0]?.deviceId, reportsForAsset.length, numInferredEvents, maxBy('received', reportsForAsset)?.received, options]
);
export const getLegsQueryFn = (selectedAsset: Pick<AssetBasic, 'category' | 'deviceMake'>, reportsForAsset: ReportWithInferredEvents[], ignoreGeocoding = false) => (async (): Promise<Leg[]> => {
  const {legStringMapped, legIndices, reportsAsc} = preProcessLegs(reportsForAsset, [], selectedAsset.category, selectedAsset?.deviceMake)

  if (reportsAsc.length === 0) {
    return [];
  }

  return processLegs(legStringMapped, legIndices, reportsAsc, [], selectedAsset.category, ignoreGeocoding);
});

export const useQueryLegs = <T = Leg[]>(selectedAsset: AssetBasic, reportsForAsset: Report[], options: Omit<UseQueryOptions<Leg[], unknown, T>, 'queryFn' | 'queryKey'>) => {
  const reports = useInferredEventsForReports(reportsForAsset);
  const numInferredEvents = useMemo(() => reports?.flatMap(r => r.inferredEvents ?? []).length, [reports]);
  return useQuery({
    queryFn: getLegsQueryFn(selectedAsset, reports ?? []),
    queryKey: getLegsQueryKey(reports ?? [], numInferredEvents),
    gcTime: 30_000,
    ...options,
  });
};

export default preProcessLegs;

interface LegsForAssetsOptions {
  ignoreGeocoding?: boolean
}

export const calculateLegsForAssets = async (
  assets: Pick<AssetBasic, 'id' | 'category' | 'deviceMake'>[],
  reportsForAssets: Record<number, Report[]>,
  inferredEvents: Record<number, Record<number, InferredEventId[]>> | undefined,
  queryClient: QueryClient,
  options: LegsForAssetsOptions = {},
) => {
  const console = consoleDep.inject('Legs');
  const t0 = performance.now();

  const promises = assets.reduce<Promise<{ assetId: number, legs: Leg[], cached: boolean }>[]>((acc, a) => {
    if (!(a.id in reportsForAssets) || !reportsForAssets[a.id].length) return acc;

    const numInferredEvent = inferredEvents?.[a.id] ? Object.values(inferredEvents?.[a.id]).flat(1).length : undefined;

    const queryKey = getLegsQueryKey(reportsForAssets[a.id], numInferredEvent, options);

    const legs = queryClient.getQueryData<Leg[]>(queryKey);
    if (legs) {
      acc.push(Promise.resolve({ assetId: a.id, legs, cached: true }));
      return acc;
    }

    const reports = reportsForAssets[a.id].map(r => {
      const inferred = inferredEvents?.[a.id]?.[r.id];
      if (!inferred) return r;
      return { ...r, inferredEvents: inferred ?? null };
    });
    acc.push(getLegsQueryFn(a, reports, options.ignoreGeocoding)().then(legs => {
      queryClient.setQueryData<Leg[]>(queryKey, legs);
      return { assetId: a.id, legs, cached: false };
    }));

    return acc;
  }, []);

  const result = await Promise.allSettled(promises);

  let cachedCount = 0;
  let failureCount = 0;
  const legsByAsset: Record<number, Leg[]> = {};
  for (const r of result) {
    if (r.status === 'rejected') {
      failureCount++;
      continue;
    }
    if (r.value.cached) cachedCount++;
    legsByAsset[r.value.assetId] = r.value.legs;
  }

  const t1 = performance.now();

  console.debug('calculateLegsForAssets', {
    assetCount: assets.length,
    cachedCount,
    failureCount,
    milliseconds: Math.ceil(t1 - t0),
    ...options,
  });

  return legsByAsset;
};

export const useLegsForAssets = (assets: Pick<AssetBasic, 'id' | 'category' | 'deviceMake'>[], { ignoreGeocoding }: LegsForAssetsOptions = {}) => {
  const reportsForAssets = useAssetsReports(assets);
  const inferredEvents = useAssetsInferredEventsByReportId(assets);
  const queryClient = useQueryClient();

  const [data, setData] = useState<{ pending: boolean, data: Record<number, Leg[]> | undefined }>({ pending: false, data: undefined });

  // NOTE: We manually implement a promise for each asset here instead of handling it with useQueries
  //       because it was discovered that useQueries scales terribly for large numbers of queries and
  //       caused multi-second thread blocking.
  useEffect(() => {
    setData({ pending: true, data: undefined });
    let mounted = true;

    calculateLegsForAssets(
      assets,
      reportsForAssets,
      inferredEvents,
      queryClient,
      { ignoreGeocoding },
    ).then(data => {
      if (!mounted) return;
      setData({ pending: false, data });
    });

    return () => {
      mounted = false;
    };
  }, [queryClient, reportsForAssets, assets, inferredEvents, ignoreGeocoding]);

  return data;
};
