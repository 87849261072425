import { debounce } from 'lodash';
import type { ADSBExchangeResponse, AdsbAircraft } from './types';

export const getAircraftWithin = async (lat: number, lon: number, dist = 250): Promise<AdsbAircraft[]> => {
  const options = {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': '436a491838mshdcf79aca66e2e7dp1b058ejsn62fa8838dec9',
      'X-RapidAPI-Host': 'adsbexchange-com1.p.rapidapi.com',
    },
  };

  const url = `https://adsbexchange-com1.p.rapidapi.com/v2/lat/${lat}/lon/${lon}/dist/${dist}/`;

  const resp = await fetch(url, options);
  const res = (await resp.json()) as ADSBExchangeResponse;
  return res.ac.map(a => ({ ...a, timeOfFix: res.now - a.seen * 1000 }));
};

export const getAircraftWithinDebounced = debounce(getAircraftWithin, 1000);
