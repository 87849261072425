import React from 'react';
import { DeleteForever, Edit, Place, Route, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  deletePath,
  deletePoint,
  selectPaths,
  selectPoints,
  setEditPathDialog,
  setEditPointDialog,
  togglePathVisibility,
  togglePointVisibility
} from 'slices/map/annotations.slice';
import { useAppDispatch } from 'store/useAppDispatch';
import { useTranslations } from 'use-intl';
import {
  openDeleteSearchPatternDialog,
  selectSearchPatterns,
  setSelectedSearchPattern,
  toggleSearchPatternVisibility
} from 'slices/searchPatterns.slice';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';

const AnnotationsView: React.FC = () => {
  const t = useTranslations('pages.map.settingsDialog.annotations');
  const points = useSelector(selectPoints);
  const paths = useSelector(selectPaths);
  const enableSearchPatternsFF = useFeatureFlag('frontendSearchPatterns');
  const enableSearchPatternsFT = useFeatureAssets('map.searchPatterns').some;
  const enableSearchPatterns = enableSearchPatternsFF && enableSearchPatternsFT;
  const searchPatterns = useSelector(selectSearchPatterns);
  const dispatch = useAppDispatch();

  return (
    <Stack direction="column" spacing={2}>
      {points.length === 0 && paths.length === 0 && ((enableSearchPatterns && searchPatterns.length === 0) || !enableSearchPatterns) && (
        <Typography>{t('noData')}</Typography>
      )}
      {points.map(x => (
        <Stack key={`point-r-${x.id}`} direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            <Place />
            <Box
              sx={{
                backgroundColor: x.colour,
                width: '1rem',
                height: '1rem',
                borderRadius: 1,
                flexShrink: 0
              }}
            />
            <Typography>{x.name}</Typography>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <IconButton size="small" onClick={() => dispatch(togglePointVisibility(x.id))}>{x.isVisible ? (<Visibility />) : (<VisibilityOff />)}</IconButton>
            <IconButton size="small" onClick={() => dispatch(setEditPointDialog(x))}><Edit /></IconButton>
            <IconButton size="small" onClick={() => dispatch(deletePoint(x.id))}><DeleteForever /></IconButton>
          </Stack>
        </Stack>
      ))}
      {paths.map(x => (
        <Stack key={`paths-r-${x.id}`} direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            <Route />
            <Box
              sx={{
                backgroundColor: x.colour,
                width: '1rem',
                height: '1rem',
                borderRadius: 1,
                flexShrink: 0
              }}
            />
            <Typography>{x.name}</Typography>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <IconButton size="small" onClick={() => dispatch(togglePathVisibility(x.id))}>{x.isVisible ? (<Visibility />) : (<VisibilityOff />)}</IconButton>
            <IconButton size="small" onClick={() => dispatch(setEditPathDialog(x))}><Edit /></IconButton>
            <IconButton size="small" onClick={() => dispatch(deletePath(x.id))}><DeleteForever /></IconButton>
          </Stack>
        </Stack>
      ))}
      {enableSearchPatterns && searchPatterns.map(sp => (
        <Stack key={`sp-r-${sp.id}`} direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M 7,8 v-7 h8 v14 h-14 v-14 h2 v12 h10 v-10 h-4 v6 z" />
              <circle cx="8" cy="8.5" r="2" />
            </svg>

            <Typography>{sp.name}</Typography>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <IconButton size="small" onClick={() => dispatch(toggleSearchPatternVisibility(sp))}>{sp.isHidden ? (<VisibilityOff />) : (<Visibility />)}</IconButton>
            <IconButton size="small" onClick={() => dispatch(setSelectedSearchPattern(sp))}><Edit /></IconButton>
            <IconButton size="small" onClick={() => dispatch(openDeleteSearchPatternDialog(sp))}><DeleteForever /></IconButton>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default AnnotationsView;
