import { HttpResponseError, serenityFetch } from 'helpers/api';
import type { EventCode, EventCodeGroup } from './types';

export const getEventCodes = async (organisationId: string, language: string): Promise<EventCode[]> => {
  const response = await serenityFetch(
    'GET',
    `/organisations/${organisationId}/event-codes?language=${language}`,
    null,
  );
  HttpResponseError.detect(response);
  return response.json();
};

export const getEventCodeGroups = async (organisationId: string, language: string): Promise<EventCodeGroup[]> => {
  const response = await serenityFetch(
    'GET',
    `/organisations/${organisationId}/event-codes/groups?language=${language}`,
    null,
  );
  HttpResponseError.detect(response);
  return response.json();
};

export const getGeofenceCodes = async (organisationId: string, language: string): Promise<EventCode[]> => {
  const response = await serenityFetch(
    'GET',
    `/organisations/${organisationId}/event-codes/geofence?language=${language}`,
    null,
  );
  HttpResponseError.detect(response);
  return (await response.json()).events;
};
