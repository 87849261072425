import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Stack, type SxProps,
  TextField,
  type TextFieldProps,
  Typography,
} from '@mui/material';
import { BearingInput } from 'components/shared/BearingInput';
import { LatitudeInput, LongitudeInput } from 'components/shared/CoordinatesInput';
import { DistanceInput } from 'components/shared/DistanceInput';
import { TPButton } from 'components/shared/button/TPButton';
import { useViewport } from 'contexts/viewport/useViewport';
import type { SearchPattern } from 'helpers/searchPatterns';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import { DateTime } from 'luxon';
import type React from 'react';
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedMapId } from 'slices/map.slice';
import {
  openDeleteSearchPatternDialog,
  selectSearchPatternOverlayState,
  setSelectedSearchPattern,
  updateSearchPatternOverlay,
} from 'slices/searchPatterns.slice';
import { useAppSelector } from 'store/useAppSelector';
import { useTranslations } from 'use-intl';

const fieldTypes = [
  'name',
  'origin',
  'orientation',
  'firstTurnDirection',
  'turnDirection',
  'trackSpacingMetres',
  'legCount',
  'legLengthMetres',
  'orientationArc',
  'legLengthRangeMetres',
  'notes',
  'isLocked',
];

interface NumberInputProps extends Omit<TextFieldProps, 'type' | 'onChange'> {
  value: number;
  adornment?: string;
  allowNegative?: boolean;
  onChangeValue?: (val: number) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({ value, adornment, allowNegative, onChangeValue, ...rest }) => {
  const [intermediaryValue, setIntermediaryValue] = useState<string>(() => value.toString());

  useEffect(() => {
    setIntermediaryValue(value.toString());
  }, [value]);

  const onChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const potentialNumber = evt.target.value.trim();
      setIntermediaryValue(potentialNumber);

      if (potentialNumber === '' || (allowNegative && potentialNumber === '-')) {
        return;
      }

      const num = Number(potentialNumber);
      if (!Number.isNaN(num)) {
        onChangeValue?.(num);
      }
    },
    [allowNegative, onChangeValue],
  );

  return (
    <TextField
      {...rest}
      value={intermediaryValue}
      onChange={onChange}
      type="number"
      InputProps={{
        endAdornment: adornment ? <InputAdornment position="end">{adornment}</InputAdornment> : undefined,
      }}
      sx={{
        flex: 1,
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' },
      }}
    />
  );
};

interface FieldInputProps {
  type: string;
  invalidReason: string | undefined;
  selected: SearchPattern;
  onChange: (sp: Partial<SearchPattern>) => void;
}

const FieldInput: React.FC<FieldInputProps> = ({ type, invalidReason, selected, onChange }) => {
  const t = useTranslations('pages.map.searchPatterns.overlay');
  const { coordinate } = useUnitSettings();

  const errorText = useMemo(() => {
    if (invalidReason) {
      // @ts-ignore
      return t(`fieldErrors.${type}.${invalidReason}`, { type: selected.type });
    }
    return undefined;
  }, [t, type, invalidReason, selected.type]);

  const coordFormat = () => {
    switch (coordinate) {
      case 'coordinatesDMS':
        return 'dms';
      case 'coordinatesDDM':
        return 'dm';
      default:
        return 'n';
    }
  };

  switch (type) {
    case 'name':
      return (
        <TextField
          label={t('fields.name')}
          value={selected.name}
          onChange={e => onChange({ name: e.target.value })}
          error={!!errorText}
          helperText={errorText}
          variant="outlined"
          size="small"
          fullWidth
        />
      );
    case 'notes':
      return (
        <TextField
          label={t('fields.notes')}
          value={selected.notes}
          onChange={e => onChange({ notes: e.target.value })}
          error={!!errorText}
          helperText={errorText}
          variant="outlined"
          size="small"
          fullWidth
          multiline
          maxRows={5}
        />
      );
    case 'origin':
      return (
        <>
          <LatitudeInput
            label={t('fields.latitude')}
            value={selected.origin.lat}
            onChangeValue={lat => {
              if (lat) onChange({ origin: { lng: selected.origin.lng, lat } });
            }}
            displayFormat={coordFormat()}
            size="small"
            fullWidth
          />
          <LongitudeInput
            label={t('fields.longitude')}
            value={selected.origin.lng}
            onChangeValue={lng => {
              if (lng) onChange({ origin: { lng, lat: selected.origin.lat } });
            }}
            displayFormat={coordFormat()}
            size="small"
            fullWidth
          />
        </>
      );
    case 'firstTurnDirection':
    case 'turnDirection':
      if (!(type in selected)) throw new Error(`${type} should exist on object`);
      if (invalidReason) throw new Error(`${type} should always be valid`);
      return (
        <TextField
          label={t(`fields.${type}`)}
          value={selected[type as keyof SearchPattern]}
          onChange={e => {
            if (e.target.value === 'LEFT' || e.target.value === 'RIGHT') {
              onChange({ [type]: e.target.value });
            }
          }}
          variant="outlined"
          size="small"
          fullWidth
          select
        >
          <MenuItem value="LEFT">{t('fieldValues.direction.left')}</MenuItem>
          <MenuItem value="RIGHT">{t('fieldValues.direction.right')}</MenuItem>
        </TextField>
      );
    case 'orientation':
      if (!(type in selected)) throw new Error(`${type} should exist on object`);
      if (invalidReason) throw new Error(`${type} should always be valid`);
      return (
        <BearingInput
          label={t(`fields.${type}`)}
          value={selected[type as keyof SearchPattern] as number}
          onChangeValue={val => onChange({ [type]: val })}
          time={DateTime.now()}
          location={selected.origin}
          size="small"
          fullWidth
        />
      );
    case 'orientationArc':
    case 'legCount':
      if (!(type in selected)) throw new Error(`${type} should exist on object`);
      return (
        <NumberInput
          label={t(`fields.${type}`)}
          value={selected[type as keyof SearchPattern] as number}
          onChangeValue={val => onChange({ [type]: val })}
          error={!!errorText}
          helperText={errorText}
          size="small"
          fullWidth
        />
      );
    case 'trackSpacingMetres':
    case 'legLengthMetres':
    case 'legLengthRangeMetres':
      if (!(type in selected)) throw new Error(`${type} should exist on object`);
      return (
        <DistanceInput
          label={t(`fields.${type}`)}
          value={selected[type as keyof SearchPattern] as number}
          onChangeValue={val => onChange({ [type]: val })}
          error={!!errorText}
          helperText={errorText}
          size="small"
          fullWidth
        />
      );
    case 'isLocked':
      if (invalidReason) throw new Error(`${type} should always be valid`);
      return (
        <FormControlLabel
          label={t('fields.locked')}
          control={<Checkbox checked={selected.isLocked} onChange={e => onChange({ isLocked: e.target.checked })} />}
        />
      );
    default:
      throw new Error(`unsupported field type: ${type}`);
  }
};

const defaultStyles: SxProps = {
  p: 3,
  pointerEvents: 'initial',
  textAlign: 'left',
};

const condensedStyles = (maxHeight: number): SxProps => ({
  ...defaultStyles,
  maxHeight: Math.max(150, maxHeight - 100),
  overflowY: 'scroll',
});

export const SearchPatternsOverlay: React.FC<{ enabled: boolean }> = ({ enabled }) => {
  const t = useTranslations('pages.map.searchPatterns');
  const dispatch = useDispatch();
  const { searchPattern: selected, invalidFields } = useSelector(selectSearchPatternOverlayState);

  const fields = useMemo(() => {
    if (!selected) return null;
    return fieldTypes
      .filter(f => Object.keys(selected).includes(f))
      .map(f => ({
        type: f,
        invalidReason: invalidFields.find(inv => inv.field === f)?.reason,
      }));
  }, [invalidFields, selected]);

  const selectedMapId = useAppSelector(getSelectedMapId);
  const viewport = useViewport(selectedMapId);
  const styles = useMemo(() => viewport.height < 720 ? condensedStyles(viewport.height) : defaultStyles, [viewport.height]);

  const onChange = (sp: Partial<SearchPattern>) => {
    dispatch(updateSearchPatternOverlay(sp));
  };

  if (!enabled || !selected) return null;

  return (
    <Paper elevation={0} sx={styles}>
      <Stack gap={3}>
        <Typography variant="h4" component="div">
          {t('overlay.title', { name: selected.name })}
        </Typography>
        {fields?.map(({ type, invalidReason }) => (
          <FieldInput key={type} type={type} invalidReason={invalidReason} selected={selected} onChange={onChange} />
        ))}
        <Stack direction="row" gap={3}>
          <TPButton variant="destructive" onClick={() => dispatch(openDeleteSearchPatternDialog(selected))} fullWidth>
            {t('overlay.delete')}
          </TPButton>
          <TPButton variant="outlined" onClick={() => dispatch(setSelectedSearchPattern({ id: undefined }))} fullWidth>
            {t('overlay.close')}
          </TPButton>
        </Stack>
      </Stack>
    </Paper>
  );
};
