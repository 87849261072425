import { HttpResponseError, serenityFetch } from 'helpers/api';
import { DateTime } from 'luxon';
import type { ApiInferredEventsResponse, InferredEvent, InferredEventId } from './types';

const mapResponse = (resp: ApiInferredEventsResponse): InferredEvent[] =>
  resp.inferredEvents.map(
    evt =>
      ({
        id: evt.id,
        assetId: evt.assetId,
        reportId: evt.reportId,
        reportTime: DateTime.fromISO(evt.reportTime),
        eventId: evt.eventId as InferredEventId,
      }) satisfies InferredEvent,
  );

export const getInferredEventsForOrganisation = async (
  organisationId: string,
  from: DateTime,
  until: DateTime,
): Promise<InferredEvent[]> => {
  const params = new URLSearchParams({
    from: from.toUTC().toISO({ suppressSeconds: true }),
    until: until.toUTC().toISO({ suppressSeconds: true }),
  });
  const response = await serenityFetch('GET', `/organisations/${organisationId}/inferred-events?${params}`, null);
  HttpResponseError.detect(response);
  const resp = (await response.json()) as ApiInferredEventsResponse;
  return mapResponse(resp);
};

export const getInferredEventsForAsset = async (
  organisationId: string,
  assetId: number | undefined,
  from: DateTime,
  until: DateTime,
): Promise<InferredEvent[]> => {
  if (assetId === undefined) {
    return [];
  }

  const params = new URLSearchParams({
    from: from.toUTC().toISO({ suppressSeconds: true }),
    until: until.toUTC().toISO({ suppressSeconds: true }),
  });
  const response = await serenityFetch(
    'GET',
    `/organisations/${organisationId}/assets/${assetId}/inferred-events?${params}`,
    null,
  );
  HttpResponseError.detect(response);
  const resp = (await response.json()) as ApiInferredEventsResponse;
  return mapResponse(resp);
};
