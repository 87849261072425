import { type UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { iceContactGroupsQueryKeys } from './queryKeys';
import {
  type HealthResult,
  addIceContactGroup,
  deleteIceContactGroup,
  getIceContactGroups,
  getIceContactGroupsHealth,
  updateIceContactGroup,
  updateIceContactGroupAssignDevices,
  updateIceContactGroupAssignPeople,
} from './requests';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetIceContactGroups = <T = ContactGroup[]>(options?: Options<ContactGroup[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = iceContactGroupsQueryKeys.lists(organisationId);

  const query = useQuery({
    queryKey,
    queryFn: () => getIceContactGroups(organisationId),
    ...options,
  });
  return { query, queryKey };
};

export const useGetIceContactGroupsHealth = <T = HealthResult>(options?: Options<HealthResult, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = iceContactGroupsQueryKeys.health(organisationId);

  const query = useQuery({
    queryKey,
    queryFn: () => getIceContactGroupsHealth(organisationId),
    ...options,
  });
  return { query, queryKey };
};

export const useMutateNewIceContactGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();
  const listsQueryKey = iceContactGroupsQueryKeys.lists(organisationId);

  return useMutation({
    mutationFn: (value: Pick<ContactGroup, 'name'>) => addIceContactGroup(organisationId, value),
    onMutate: async value => {
      await queryClient.cancelQueries({
        queryKey: listsQueryKey,
      });
      const previousGroups = queryClient.getQueryData<ContactGroup[]>(listsQueryKey);
      if (previousGroups) {
        queryClient.setQueryData<ContactGroup[]>(listsQueryKey, [
          ...previousGroups,
          {
            ...value,
            id: -1,
            isDefault: false,
            temporary: true,
            deviceAndAssetIds: [],
            peopleWithOrder: [],
            peopleVersion: -1,
            deviceVersion: -1,
          },
        ]);
      }
      return { previousGroups };
    },
    onError: (_, __, context) => {
      if (context?.previousGroups) {
        queryClient.setQueryData(listsQueryKey, context.previousGroups);
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: iceContactGroupsQueryKeys.all(organisationId),
      }),
  });
};

export const useMutateIceContactGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();
  const listsQueryKey = iceContactGroupsQueryKeys.lists(organisationId);

  return useMutation({
    mutationFn: (value: Pick<ContactGroup, 'id' | 'name' | 'isDefault'>) =>
      updateIceContactGroup(organisationId, value),
    onMutate: async value => {
      await queryClient.cancelQueries({
        queryKey: listsQueryKey,
      });
      const previousGroups = queryClient.getQueryData<ContactGroup[]>(listsQueryKey);
      if (previousGroups?.some(g => g.id === value.id)) {
        queryClient.setQueryData<ContactGroup[]>(listsQueryKey, () =>
          previousGroups.map(g => {
            if (g.id === value.id) return { ...g, ...value, temporary: true };
            if (value.isDefault && g.isDefault) return { ...g, isDefault: false, temporary: true };
            return g;
          }),
        );
      }
      return { previousGroups };
    },
    onError: (_, __, context) => {
      if (context?.previousGroups) {
        queryClient.setQueryData(listsQueryKey, context.previousGroups);
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: iceContactGroupsQueryKeys.all(organisationId),
      }),
  });
};

export const useMutateIceContactGroupAssignDevices = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();
  const listsQueryKey = iceContactGroupsQueryKeys.lists(organisationId);

  return useMutation({
    mutationFn: (value: Pick<ContactGroup, 'id' | 'deviceAndAssetIds' | 'deviceVersion'>) =>
      updateIceContactGroupAssignDevices(organisationId, {
        id: value.id,
        deviceVersion: value.deviceVersion,
        deviceIds: value.deviceAndAssetIds.map(x => x.deviceId),
      }),
    onMutate: async value => {
      await queryClient.cancelQueries({ queryKey: listsQueryKey });
      const previousGroups = queryClient.getQueryData<ContactGroup[]>(listsQueryKey);
      if (previousGroups?.some(g => g.id === value.id)) {
        queryClient.setQueryData<ContactGroup[]>(listsQueryKey, () =>
          previousGroups.map(g => (g.id === value.id ? { ...g, ...value, temporary: true } : g)),
        );
      }
      return { previousGroups };
    },
    onError: (_, __, context) => {
      if (context?.previousGroups) {
        queryClient.setQueryData(listsQueryKey, context.previousGroups);
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: iceContactGroupsQueryKeys.all(organisationId),
      }),
  });
};

export const useMutateIceContactGroupAssignPeople = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();
  const listsQueryKey = iceContactGroupsQueryKeys.lists(organisationId);

  return useMutation({
    mutationFn: (value: Pick<ContactGroup, 'id' | 'peopleWithOrder' | 'peopleVersion'>) =>
      updateIceContactGroupAssignPeople(organisationId, {
        id: value.id,
        peopleVersion: value.peopleVersion,
        peopleForGroup: value.peopleWithOrder,
      }),
    onMutate: async value => {
      await queryClient.cancelQueries({ queryKey: listsQueryKey });
      const previousGroups = queryClient.getQueryData<ContactGroup[]>(listsQueryKey);
      if (previousGroups?.some(g => g.id === value.id)) {
        queryClient.setQueryData<ContactGroup[]>(listsQueryKey, () =>
          previousGroups.map(g => (g.id === value.id ? { ...g, ...value, temporary: true } : g)),
        );
      }
      return { previousGroups };
    },
    onError: (_, __, context) => {
      if (context?.previousGroups) {
        queryClient.setQueryData(listsQueryKey, context.previousGroups);
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: iceContactGroupsQueryKeys.all(organisationId),
      }),
  });
};

export const useMutateDeleteIceContactGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();
  const listsQueryKey = iceContactGroupsQueryKeys.lists(organisationId);

  return useMutation({
    mutationFn: (value: Pick<ContactGroup, 'id'>) => deleteIceContactGroup(organisationId, value),
    onMutate: async value => {
      await queryClient.cancelQueries({ queryKey: listsQueryKey });
      const previousGroups = queryClient.getQueryData<ContactGroup[]>(listsQueryKey);
      if (previousGroups?.some(g => g.id === value.id)) {
        queryClient.setQueryData<ContactGroup[]>(listsQueryKey, () => previousGroups.filter(g => g.id !== value.id));
      }
      return { previousGroups };
    },
    onError: (_, __, context) => {
      if (context?.previousGroups) {
        queryClient.setQueryData(listsQueryKey, context.previousGroups);
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: iceContactGroupsQueryKeys.all(organisationId),
      }),
  });
};
