import { type UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { geofenceQueryKeys } from './queryKeys';
import { createGeofence, deleteGeofence, getGeofences, updateGeofence } from './requests';
import type { GeofenceCreationRequestBody, GeofenceResponseItem, GeofenceUpdateRequestBody } from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetGeofences = <T = GeofenceResponseItem[]>(options?: Options<GeofenceResponseItem[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = geofenceQueryKeys.org(organisationId);

  return useQuery({
    queryKey,
    queryFn: async () => getGeofences(organisationId),
    ...options,
  });
};

export const useCreateGeofence = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();
  return useMutation({
    mutationFn: (geofence: GeofenceCreationRequestBody) => createGeofence(organisationId, geofence),
    onSettled: () => queryClient.invalidateQueries({ queryKey: geofenceQueryKeys.org(organisationId) }),
  });
};

export const useDeleteGeofence = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();
  return useMutation({
    mutationFn: (geofenceId: number) => deleteGeofence(organisationId, geofenceId),
    onSettled: () => queryClient.invalidateQueries({ queryKey: geofenceQueryKeys.org(organisationId) }),
  });
};

export const useUpdateGeofence = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();
  return useMutation({
    mutationFn: (geofence: GeofenceUpdateRequestBody) => updateGeofence(organisationId, geofence),
    onSettled: () => queryClient.invalidateQueries({ queryKey: geofenceQueryKeys.org(organisationId) }),
  });
};
