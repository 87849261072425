import { createUseDependency, type Dependencies, DependencyInjectionContainer } from 'utils/dependency-injection-container';
import type { Store } from 'redux';
import type { Persistor } from 'redux-persist';
import type { FirebaseService } from 'firebaseApp/firebase-service';

interface Injectables extends Dependencies {
  reduxStore: () => Store
  reduxStorePersistor: () => Persistor
  firebaseService: () => FirebaseService
  console: (name: string) => Console
}

export const di = new DependencyInjectionContainer<Injectables>();
