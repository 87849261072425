/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Feature,
  featureCollection,
  lineString,
  LineString,
  Point,
  point,
  polygon,
  Polygon
} from '@turf/helpers';
import bearing from '@turf/bearing';
import circle from '@turf/circle';
import destination from '@turf/destination';
import midpoint from '@turf/midpoint';
import {
  CreepingLineSearchPattern,
  ExpandingBoxSearchPattern,
  ParallelTrackSearchPattern,
  SearchPattern,
  isCreepingLine,
  isExpandingBox,
  isParallelTrack,
  getLegCount,
  isSectorSearch,
  SectorSearchPattern,
  isRangeBearingLine,
  RangeBearingLineSearchPattern,
} from 'helpers/searchPatterns';
import { normaliseBearing } from 'helpers/geo';
import lineArc from '@turf/line-arc';
import { FeatureCollection } from 'geojson';

const getFirstAxis = (sp: SearchPattern) => {
  if (isParallelTrack(sp) || isCreepingLine(sp)) {
    return sp.firstTurnDirection === 'LEFT' ? 'x' : 'y';
  }

  return 'x';
};

const getRotationOffset = (sp: SearchPattern) => {
  if (isCreepingLine(sp)) {
    return -90;
  }

  return 0;
};

const calculateHandleBearings = (sp: SearchPattern, bbox: number[][]) => {
  if (isSectorSearch(sp)) return undefined;
  if (bbox.length !== 5 || bbox.some(c => c.length !== 2)) throw new Error('invalid bbox');

  const origin = [sp.origin.lng, sp.origin.lat];
  return bbox.slice(0, 4).map(coord => bearing(origin, coord) - sp.orientation);
};

const getProps = (sp: SearchPattern, meta: string) => ({
  searchPatternId: sp.id,
  meta,
});
const getBboxProps = (sp: SearchPattern, handleBearings: number[] = []) => ({
  searchPattern: sp,
  firstAxis: getFirstAxis(sp),
  rotationOffset: getRotationOffset(sp),
  handleBearings,
  meta: 'bbox',
});

const calculateArrow = (sp: SearchPattern, center: Feature<Point>, orientation: number, length: number, idx: number) => {
  const middle = destination(center, length / 2, orientation, { units: 'meters' });
  const left = destination(middle, length, orientation - 155, { units: 'meters' });
  const right = destination(middle, length, orientation + 155, { units: 'meters' });
  return lineString(
    [left.geometry.coordinates, middle.geometry.coordinates, right.geometry.coordinates],
    getProps(sp, 'arrow'),
    { id: `${sp.id}_arrow-${idx}` }
  );
};

const calculateDoubleArrow = (sp: SearchPattern, center: Feature<Point>, orientation: number, length: number, idx: number): Feature<LineString>[] => {
  const l1 = destination(center, length, orientation - 155, { units: 'meters' });
  const r1 = destination(center, length, orientation + 155, { units: 'meters' });
  const middle = destination(center, length, orientation, { units: 'meters' });
  const l2 = destination(middle, length, orientation - 155, { units: 'meters' });
  const r2 = destination(middle, length, orientation + 155, { units: 'meters' });
  return [
    lineString(
      [l1.geometry.coordinates, center.geometry.coordinates, r1.geometry.coordinates],
      getProps(sp, 'arrow'),
      { id: `${sp.id}_arrow-${idx}-0` }
    ),
    lineString(
      [l2.geometry.coordinates, middle.geometry.coordinates, r2.geometry.coordinates],
      getProps(sp, 'arrow'),
      { id: `${sp.id}_arrow-${idx}-1` }
    ),
  ];
};

const bufferedBoxParallelTrack = (bbox: number[][], sp: ParallelTrackSearchPattern | CreepingLineSearchPattern) => {
  const box = bbox.slice(0);
  const buffer = sp.trackSpacingMetres / 2;

  // const direction = 'firstTurnDirection' in sp ? sp.firstTurnDirection : sp.turnDirection;
  const orientationOffset = isCreepingLine(sp) ? 90 : 0;

  if (sp.firstTurnDirection === 'RIGHT') {
    box[0] = destination(box[0], buffer, orientationOffset + sp.orientation - 135, { units: 'meters' }).geometry.coordinates;
    box[1] = destination(box[1], buffer, orientationOffset + sp.orientation + 135, { units: 'meters' }).geometry.coordinates;
    box[2] = destination(box[2], buffer, orientationOffset + sp.orientation + 45, { units: 'meters' }).geometry.coordinates;
    box[3] = destination(box[3], buffer, orientationOffset + sp.orientation - 45, { units: 'meters' }).geometry.coordinates;
  } else {
    box[0] = destination(box[0], buffer, orientationOffset + sp.orientation + 135, { units: 'meters' }).geometry.coordinates;
    box[1] = destination(box[1], buffer, orientationOffset + sp.orientation + 45, { units: 'meters' }).geometry.coordinates;
    box[2] = destination(box[2], buffer, orientationOffset + sp.orientation - 45, { units: 'meters' }).geometry.coordinates;
    box[3] = destination(box[3], buffer, orientationOffset + sp.orientation - 135, { units: 'meters' }).geometry.coordinates;
  }

  // eslint-disable-next-line prefer-destructuring
  box[4] = box[0];
  return box;
};

const calculateBoundingBoxParallelTrack = (sp: ParallelTrackSearchPattern | CreepingLineSearchPattern, ls: LineString) => {
  let bbox = [];

  if (ls.coordinates.length < 4) {
    throw new Error('parallel track / creeping line requires a min of 4 points');
  }

  const legCount = getLegCount(sp);

  if (sp.firstTurnDirection === 'LEFT') {
    if (legCount % 2 === 0) {
      bbox = [
        ls.coordinates.at(0)!,
        ls.coordinates.at(1)!,
        ls.coordinates.at(-2)!,
        ls.coordinates.at(-1)!,
        ls.coordinates.at(0)!,
      ];
    } else {
      bbox = [
        ls.coordinates.at(0)!,
        ls.coordinates.at(1)!,
        ls.coordinates.at(-1)!,
        ls.coordinates.at(-2)!,
        ls.coordinates.at(0)!,
      ];
    }
  } else if (legCount % 2 === 0) {
    bbox = [
      ls.coordinates.at(0)!,
      ls.coordinates.at(-1)!,
      ls.coordinates.at(-2)!,
      ls.coordinates.at(1)!,
      ls.coordinates.at(0)!,
    ];
  } else {
    bbox = [
      ls.coordinates.at(0)!,
      ls.coordinates.at(-2)!,
      ls.coordinates.at(-1)!,
      ls.coordinates.at(1)!,
      ls.coordinates.at(0)!,
    ];
  }

  return bufferedBoxParallelTrack(bbox, sp);
};

const bufferedBoxExpandingBox = (bbox: number[][], sp: ExpandingBoxSearchPattern) => {
  const box = bbox.slice(0);
  const buffer = sp.trackSpacingMetres / 2;

  box[0] = destination(box[0], buffer, sp.orientation + 135, { units: 'meters' }).geometry.coordinates;
  box[1] = destination(box[1], buffer, sp.orientation + 45, { units: 'meters' }).geometry.coordinates;
  box[2] = destination(box[2], buffer, sp.orientation - 45, { units: 'meters' }).geometry.coordinates;
  box[3] = destination(box[3], buffer, sp.orientation - 135, { units: 'meters' }).geometry.coordinates;

  // eslint-disable-next-line prefer-destructuring
  box[4] = box[0];
  return box;
};

const calculateBoundingBoxExpandingBox = (sp: ExpandingBoxSearchPattern, ls: LineString) => {
  let bbox = [];

  if (ls.coordinates.length < 4) {
    throw new Error('expanding box requires a min of 4 points');
  }

  // when legCount-1 is a multiple of 4 the shape is inverted
  if ((getLegCount(sp) - 1) % 4 !== 0) {
    if (sp.turnDirection === 'LEFT') {
      bbox = [
        ls.coordinates.at(-4)!,
        ls.coordinates.at(-3)!,
        ls.coordinates.at(-2)!,
        ls.coordinates.at(-1)!,
        ls.coordinates.at(-4)!,
      ];
    } else {
      bbox = [
        ls.coordinates.at(-1)!,
        ls.coordinates.at(-2)!,
        ls.coordinates.at(-3)!,
        ls.coordinates.at(-4)!,
        ls.coordinates.at(-1)!,
      ];
    }
  } else if (sp.turnDirection === 'LEFT') {
    bbox = [
      ls.coordinates.at(-2)!,
      ls.coordinates.at(-1)!,
      ls.coordinates.at(-4)!,
      ls.coordinates.at(-3)!,
      ls.coordinates.at(-2)!,
    ];
  } else {
    bbox = [
      ls.coordinates.at(-3)!,
      ls.coordinates.at(-4)!,
      ls.coordinates.at(-1)!,
      ls.coordinates.at(-2)!,
      ls.coordinates.at(-3)!,
    ];
  }

  return bufferedBoxExpandingBox(bbox, sp);
};

const calculateBoundingBoxSectorSearch = (sp: SectorSearchPattern) => {
  const buffer = sp.trackSpacingMetres / 2;
  const radius = sp.trackSpacingMetres + buffer;
  const props = ({
    ...getBboxProps(sp),
    radius,
  });

  const coords = circle([sp.origin.lng, sp.origin.lat], radius, {
    units: 'meters',
    steps: 64,
  });

  return polygon(coords.geometry.coordinates, props, { id: sp.id });
};

const calculateBoundingBoxRangeBearingLine = (sp: RangeBearingLineSearchPattern) => {
  const minArc = lineArc(
    [sp.origin.lng, sp.origin.lat],
    Math.max(sp.legLengthMetres - sp.legLengthRangeMetres, 0),
    sp.orientation - sp.orientationArc - 0.1,
    sp.orientation + sp.orientationArc + 0.1,
    { units: 'meters' }
  );
  const maxArc = lineArc(
    [sp.origin.lng, sp.origin.lat],
    sp.legLengthMetres + sp.legLengthRangeMetres,
    sp.orientation - sp.orientationArc - 0.1,
    sp.orientation + sp.orientationArc + 0.1,
    { units: 'meters' }
  );

  const bbox = polygon(
    [[...minArc.geometry.coordinates, ...maxArc.geometry.coordinates.toReversed(), minArc.geometry.coordinates[0]]],
    getBboxProps(sp),
    { id: sp.id }
  );

  const midpointArc = midpoint(minArc.geometry.coordinates.at(-1)!, maxArc.geometry.coordinates.at(-1)!);
  const handleArc = point(
    midpointArc.geometry.coordinates,
    { ...getProps(sp, 'rbl_handle_arc'), axis: 'x' },
    { id: `${sp.id}_rbl_handle_arc` }
  );

  return { bbox, handleArc };
};

const calculateBoundingBox = (sp: SearchPattern, ls: LineString): Feature<Polygon> => {
  let bbox;
  if (isParallelTrack(sp) || isCreepingLine(sp)) {
    bbox = calculateBoundingBoxParallelTrack(sp, ls);
  } else if (isExpandingBox(sp)) {
    bbox = calculateBoundingBoxExpandingBox(sp, ls);
  }

  if (!bbox) throw new Error(`unsupported search pattern type ${sp.type}`);
  const props = getBboxProps(sp, calculateHandleBearings(sp, bbox));
  return polygon([bbox], props, { id: sp.id });
};

// Calculates the GeoJSON required to render a search pattern + draw controls
export const calculateFeatures = (sp: SearchPattern): FeatureCollection => {
  const origin = point([sp.origin.lng, sp.origin.lat]);
  const legPoints = [origin];

  // E.g. arrows
  const suppFeatures: Feature<Point | LineString | Polygon>[] = [];

  if (isParallelTrack(sp) || isCreepingLine(sp)) {
    const legCount = getLegCount(sp);
    const orientationOffset = sp.type === 'creepingLine' ? 90 : 0;
    for (let i = 0; i < legCount; i++) {
      // find the next point (i.e. create a leg)
      const prev = legPoints.at(-1)!;
      const normalBearing = normaliseBearing(orientationOffset + sp.orientation + (i % 2 !== 0 ? 180 : 0));
      const next = destination(prev, sp.legLengthMetres, normalBearing, { units: 'meters' });
      legPoints.push(next);

      const mid = midpoint(prev, next);
      if (i === 0) {
        suppFeatures.push(...calculateDoubleArrow(sp, mid, normalBearing, sp.trackSpacingMetres / 5, i));
      } else {
        suppFeatures.push(calculateArrow(sp, mid, normalBearing, sp.trackSpacingMetres / 5, i));
      }

      // if not the last point then find the start of the next leg
      if (i < legCount - 1) {
        const turnBearing = orientationOffset + sp.orientation + (sp.firstTurnDirection === 'LEFT' ? -90 : 90);
        const p = destination(next, sp.trackSpacingMetres, turnBearing, { units: 'meters' });
        legPoints.push(p);
      }
    }
  } else if (isExpandingBox(sp)) {
    const legCount = getLegCount(sp);
    for (let i = 0; i < legCount; i++) {
      const prev = legPoints.at(-1)!;
      const turn = sp.turnDirection === 'LEFT' ? -90 : 90;
      const normalBearing = normaliseBearing(sp.orientation + ((i % 4) * turn));
      const multi = i < legCount - 1 ? Math.ceil((i + 1) / 2) : Math.floor((i + 1) / 2);
      const next = destination(prev, sp.trackSpacingMetres * multi, normalBearing, { units: 'meters' });
      legPoints.push(next);

      const mid = midpoint(prev, next);
      if (i === 0) {
        suppFeatures.push(...calculateDoubleArrow(sp, mid, normalBearing, sp.trackSpacingMetres / 5, i));
      } else {
        suppFeatures.push(calculateArrow(sp, mid, normalBearing, sp.trackSpacingMetres / 5, i));
      }
    }
  } else if (isSectorSearch(sp)) {
    const orientation = sp.orientation + (sp.turnDirection === 'LEFT' ? 0 : 180);
    const p1 = destination(origin, sp.trackSpacingMetres, orientation, { units: 'meters' });
    const p2 = destination(p1, sp.trackSpacingMetres, orientation - 120, { units: 'meters' });
    const p3 = destination(p2, sp.trackSpacingMetres * 2, orientation - 240, { units: 'meters' });
    const p4 = destination(p3, sp.trackSpacingMetres, orientation, { units: 'meters' });
    const p5 = destination(p4, sp.trackSpacingMetres * 2, orientation - 120, { units: 'meters' });
    const p6 = destination(p5, sp.trackSpacingMetres, orientation - 240, { units: 'meters' });
    legPoints.push(p1, p2, p3, p4, p5, p6, origin);

    const handle = destination(origin, sp.trackSpacingMetres * 1.5, sp.orientation, { units: 'meters' });

    // NOTE: using `sp.orientation` since we don't want to invert the arrows
    const arrowLength = sp.trackSpacingMetres / 5;
    if (!sp.isLocked) {
      suppFeatures.push(point(handle.geometry.coordinates, getProps(sp, 'sector_handle'), { id: `${sp.id}_sector_handle` }));
    }
    suppFeatures.push(
      ...calculateDoubleArrow(sp, midpoint(origin, sp.turnDirection === 'LEFT' ? p1 : p6), sp.orientation, arrowLength, 0),
      calculateArrow(sp, midpoint(p1, p2), sp.orientation - 120, arrowLength, 1),
      calculateArrow(sp, midpoint(p2, midpoint(p2, p3)), sp.orientation - 240, arrowLength, 2),
      calculateArrow(sp, midpoint(midpoint(p2, p3), p3), sp.orientation - 240, arrowLength, 3),
      calculateArrow(sp, midpoint(p3, p4), sp.orientation, arrowLength, 4),
      calculateArrow(sp, midpoint(p4, midpoint(p4, p5)), sp.orientation - 120, arrowLength, 5),
      calculateArrow(sp, midpoint(midpoint(p4, p5), p5), sp.orientation - 120, arrowLength, 6),
      calculateArrow(sp, midpoint(p5, p6), sp.orientation - 240, arrowLength, 7),
      calculateArrow(sp, midpoint(sp.turnDirection === 'LEFT' ? p6 : p1, origin), sp.orientation, arrowLength, 8),
    );
  } else if (isRangeBearingLine(sp)) {
    const endPoint = destination(origin, sp.legLengthMetres, sp.orientation, { units: 'meters' });
    legPoints.push(endPoint);
    if (!sp.isLocked) {
      suppFeatures.push(point(endPoint.geometry.coordinates, getProps(sp, 'rbl_point'), { id: `${sp.id}_rbl_point` }));
    }
    suppFeatures.push(
      lineString([
        destination(endPoint, sp.legLengthMetres / 24, sp.orientation - 90, { units: 'meters' }).geometry.coordinates,
        destination(endPoint, sp.legLengthMetres / 24, sp.orientation + 90, { units: 'meters' }).geometry.coordinates,
      ], getProps(sp, 'rbl_head'), { id: `${sp.id}_rbl_head` })
    );

    // only show the range handle when there either the range or arc is present otherwise the handles interfere
    // with the interaction of the end point when you don't want any range or arc
    if (!sp.isLocked && (sp.legLengthRangeMetres > 0 || sp.orientationArc > 0)) {
      const handlePoint = destination(origin, sp.legLengthMetres + sp.legLengthRangeMetres, sp.orientation, { units: 'meters' });
      const handle = point(handlePoint.geometry.coordinates, { ...getProps(sp, 'rbl_handle_range'), axis: 'y' }, { id: `${sp.id}_rbl_handle_range` });
      suppFeatures.push(handle);
    }
  }

  const feats = {
    origin: point(origin.geometry.coordinates, getProps(sp, 'origin'), { id: `${sp.id}_origin` }),
    lineString: lineString(legPoints.map(p => p.geometry.coordinates), getProps(sp, 'path'), { id: `${sp.id}_path` }),
  };

  let bbox;
  if (isParallelTrack(sp) || isCreepingLine(sp) || isExpandingBox(sp)) {
    bbox = calculateBoundingBox(sp, feats.lineString.geometry);
  } else if (isSectorSearch(sp)) {
    bbox = calculateBoundingBoxSectorSearch(sp);
  } else if (isRangeBearingLine(sp)) {
    const { bbox: b, handleArc } = calculateBoundingBoxRangeBearingLine(sp);
    bbox = b;
    // only show the arc handle when there either the range or arc is present otherwise the handles interfere
    // with the interaction of the end point when you don't want any range or arc
    if (!sp.isLocked && (sp.legLengthRangeMetres > 0 || sp.orientationArc > 0)) suppFeatures.push(handleArc);
  }

  if (!bbox) throw new Error(`unsupported search pattern type ${sp.type}`);
  return featureCollection(suppFeatures.concat(feats.origin, feats.lineString, bbox));
};
