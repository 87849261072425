import WebMercatorViewport from '@math.gl/web-mercator';
import React, { useMemo } from 'react';
import { AnnotationPoint } from 'slices/map/annotations.slice';
import { IconLayer, TextLayer } from 'deck.gl';
import ReactDOMServer from 'react-dom/server';
import PointIcon, { PointIconType } from 'components/shared/icons/annotations/pointIcon';
import useSupercluster from 'use-supercluster';
import { BBox, Feature, Point } from 'geojson';
import { GeographicCoordinates } from 'components/pages/manage/markers/types';
import { NestedPathGeometry } from '@deck.gl/layers/dist/path-layer/path';

const ICON_SIZE = 64;
const LABEL_COLOR: Color = [255, 255, 255];
const LABEL_BACKGROUND: ColorAlpha = [0, 0, 0, 128];
const LABEL_PADDING: [number, number, number, number] = [3, 1, 3, 1];

export const ANNOTATION_POINT_LAYER_ICON = 'annotation-point-icon';
export const ANNOTATION_PATH_LAYER_LINE = 'annotation-path-line';
const useIconLayer = (data: ClusteredPoint[], enabled: boolean, suffix: string | undefined, onSelect: (point: AnnotationPoint) => void) => useMemo(() => new IconLayer<ClusteredPoint>({
  id: `${ANNOTATION_POINT_LAYER_ICON}${suffix ? `-${suffix}` : ''}`,
  data,
  billboard: true,
  pickable: true,
  autoHighlight: true,
  sizeScale: 1,
  getSize: x => (x.type === 'pin' ? 50 : 40),
  getAngle: 0,
  getPosition: x => [x.location.longitude ?? 0, x.location.latitude ?? 0, x.location.altitude ?? 1],
  getIcon: x => ({
    url: `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<PointIcon type={x.type} fill={x.colour} />))}`,
    height: ICON_SIZE,
    width: ICON_SIZE,
    anchorX: ICON_SIZE / 2,
    anchorY: x.type === 'pin' ? ((ICON_SIZE * 4) / 5) : (ICON_SIZE / 2),
  }),
  getColor: [0, 0, 0, 255],
  visible: enabled,
  onClick(pickingInfo, event) {
    if (typeof (pickingInfo.object.id) === 'string') { // ignore clicks on clusters
      onSelect(pickingInfo.object);
    }
  },
}), [data, enabled, suffix]);

const useLabelLayer = (
  points: ClusteredPoint[],
  use3d: boolean,
  enabled: boolean,
  suffix?: string
) => useMemo(() => new TextLayer<ClusteredPoint>({
  id: `annotationPoint-label-${use3d ? '3d' : '2d'}${suffix ? `-${suffix}` : ''}`,
  data: points,
  getText: d => d.name,
  fontWeight: 600,
  getPosition: d => [d.location.longitude ?? 0, d.location.latitude ?? 0, use3d ? 1 : (d.location.altitude ?? 1)],
  getSize: 12,
  getColor: LABEL_COLOR,
  getAngle: 0,
  getTextAnchor: 'middle',
  getAlignmentBaseline: 'top',
  getPixelOffset: [0, 10],
  visible: enabled,
  // parameters: { depthTest: false },
  fontSettings: {
    sdf: true,
    fontSize: 30,
    radius: 12,
  },
  fontFamily: 'objektiv-mk2,sans-serif',
  background: true,
  getBackgroundColor: LABEL_BACKGROUND,
  backgroundPadding: LABEL_PADDING,
}), [points, use3d, enabled, suffix]);

export const useAnnotationsLayers = (
  points: AnnotationPoint[],
  viewport: WebMercatorViewport,
  enabled: boolean,
  suffix: string | undefined,
  use3d = false,
  onSelect: (point: AnnotationPoint) => void
) => {
  const dataPoints: Feature<Point, { cluster: false, point: AnnotationPoint }>[] = useMemo(() => points?.filter(x => x.isVisible).map(d => ({
    type: 'Feature',
    properties: { cluster: false, point: d },
    geometry: {
      type: 'Point',
      coordinates: [d.location.longitude ?? 0, d.location.latitude ?? 0],
    },
  })) ?? [], [points]);

  const bounds = useMemo(() => viewport.getBounds().flat(), [viewport]) as BBox;

  const { clusters } = useSupercluster<{ cluster: false, point: AnnotationPoint }, { points: AnnotationPoint[] }>({
    points: dataPoints,
    bounds,
    zoom: viewport.zoom,
    options: {

    }
  });

  const clusterData: ClusteredPoint[] = clusters.map(x => (
    x.properties.cluster ? {
      name: `${x.properties.point_count} points`,
      location: { latitude: x.geometry.coordinates[1], longitude: x.geometry.coordinates[0], altitude: 1 },
      colour: '#f00',
      cluster: true,
      count: x.properties.point_count,
      type: 'cluster'
    } : x.properties.point));

  const iconLayer = useIconLayer(clusterData, enabled, suffix, onSelect);
  const labelLayer = useLabelLayer(clusterData, use3d, enabled, suffix);
  return [iconLayer, labelLayer];
};

type ClusteredPoint = {
  cluster: boolean;
  count: number;
  name: string;
  location: GeographicCoordinates
  colour: string;
  type: PointIconType | 'cluster';
} | AnnotationPoint;
