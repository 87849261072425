import { HttpResponseError, serenityFetch } from 'helpers/api';
import type {
  CreateGroupSharesBody,
  CreateSharesBody,
  DeleteGroupShareBody,
  DeleteShareBody,
  TemporalGroupShare,
  TemporalShare,
  UpdateGroupShareBody,
  UpdateShareBody,
} from './types';

export const fetchSharesFrom = async (organisationId: string): Promise<TemporalShare[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/shares/from`, null);
  HttpResponseError.detect(response);
  return (await response.json()).shares;
};

export const fetchGroupSharesFrom = async (organisationId: string): Promise<TemporalGroupShare[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/shares/group/from`, null);
  HttpResponseError.detect(response);
  return (await response.json()).groupShares;
};

export const fetchSharesTo = async (organisationId: string): Promise<TemporalShare[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/shares/to`, null);
  HttpResponseError.detect(response);
  return (await response.json()).shares;
};

export const fetchGroupSharesTo = async (organisationId: string): Promise<TemporalGroupShare[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/shares/group/to`, null);
  HttpResponseError.detect(response);
  return (await response.json()).groupShares;
};

export const fetchSharesSelf = async (organisationId: string): Promise<TemporalShare[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/shares/self`, null);
  HttpResponseError.detect(response);
  return (await response.json()).shares;
};

export const createShares = async (organisationId: string, body: CreateSharesBody): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/shares/from`, body);
  HttpResponseError.detect(response);
};

export const createGroupShares = async (organisationId: string, body: CreateGroupSharesBody): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/shares/group`, body);
  HttpResponseError.detect(response);
};

export const updateShare = async (organisationId: string, body: UpdateShareBody): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/shares/from/${body.id}`, body);
  HttpResponseError.detect(response);
};

export const updateGroupShare = async (organisationId: string, body: UpdateGroupShareBody): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/shares/group/${body.id}`, body);
  HttpResponseError.detect(response);
};

export const deleteShare = async (organisationId: string, body: DeleteShareBody): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/shares/from/${body.id}`, null);
  HttpResponseError.detect(response);
};

export const deleteGroupShare = async (organisationId: string, body: DeleteGroupShareBody): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/shares/group/${body.id}`, null);
  HttpResponseError.detect(response);
};
