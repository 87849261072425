import * as d3 from 'd3';
import { BaseType, NumberValue } from 'd3';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { DateTime } from 'luxon';
import EventIcon from 'components/shared/icons/eventIcons';
import { InferredEventId } from 'apis/rest/inferredEvents/types';
import { getMostSignificantEvent } from 'helpers/events';
import { Theme } from '@mui/material';
import { altitude, distance, speed } from 'helpers/unitsOfMeasure';
import { Margin, RootElement } from './common';

const GROUND_COLOR = '#157a15';
const MINOR_DOT_RADIUS = 2;
const STROKE_WIDTH = 2;

export type XAxis = 'time' | 'distance';
export type AltitudeMetric = 'agl' | 'amsl';
type MinimalUserTransition = Pick<UserTransition, 'toState' | 'reportTime'>;

const xAccessors = {
  time: (d: TripSlimReport) => new Date(d.timeOfFix),
  distance: (d: TripSlimReport) => d.distance
} as const;

type TripSlimReportWithInferredEvents = TripSlimReport & { inferredEvents?: InferredEventId[] };

export interface ChartTranslations {
  speed: string
  altitude: string
  time: string
  distance: string
}

export default class TerrainSpeedChart {
  private quadtree: d3.Quadtree<TripSlimReport>;
  private data: TripSlimReportWithInferredEvents[];
  private yAltitude: d3.ScaleLinear<number, number>;
  private ySpeed: d3.ScaleLinear<number, number>;
  private x: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number>;
  rootElement: RootElement;
  private chartHeight: number;
  private margin: Margin;
  private dimensions: [number, number];
  private assetColour: string;
  private spacing: number;
  private xAccessor: typeof xAccessors[XAxis];
  private xAxis: XAxis = 'time';
  private showTerrain = true;
  private selectedInformation: d3.Selection<SVGGElement, unknown, null, undefined> | undefined;
  private useTransition = false;
  // @ts-ignore
  zoom: d3.ZoomBehavior<d3.ZoomedElementBaseType, unknown>;
  // @ts-ignore
  private unscaledX: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number>;
  private previousSelectedReport: TripSlimReport | undefined;
  private showTripBoundaries: boolean;
  trip: Trip;
  private inferredEvents: Record<number, InferredEventId[]> | undefined;
  private userTransitions: MinimalUserTransition[] = [];
  private speedGroup!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private altitudeGroup!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private terrainGroup!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private knownTransitionsGroup!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private existingTransitionsGroup!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private eventsGroup!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private theme: Theme;
  private distanceUnit: ReduxState['unitSettings']['units']['distance'];
  private altitudeUnit: ReduxState['unitSettings']['units']['altitude'];
  private speedUnit: ReduxState['unitSettings']['units']['speed'];
  private translations: ChartTranslations;
  private timezone: string;
  private originalWidth: number | undefined;

  constructor(
    trip: Trip,
    inferredEvents: Record<number, InferredEventId[]> | undefined,
    element: RootElement,
    contHeight: number,
    contWidth: number,
    margin: Margin,
    spacing: number,
    assetColour: string,
    showTripBoundaries: boolean | undefined,
    theme: Theme,
    distanceUnit: ReduxState['unitSettings']['units']['distance'],
    altitudeUnit: ReduxState['unitSettings']['units']['altitude'],
    speedUnit: ReduxState['unitSettings']['units']['speed'],
    translations: ChartTranslations,
    timezone: string,
    showTerrain: boolean
  ) {
    this.trip = trip;
    this.inferredEvents = inferredEvents;
    this.data = trip.reports
      .toSorted((a, b) => b.timeOfFix - a.timeOfFix)
      .map(report => ({ ...report, inferredEvents: this.inferredEvents?.[report.id] }));
    this.assetColour = assetColour;
    this.rootElement = element;
    this.dimensions = [contWidth, contHeight];
    this.margin = margin;
    this.spacing = spacing;
    this.showTripBoundaries = showTripBoundaries ?? false;
    this.xAccessor = xAccessors.time;
    this.theme = theme;
    this.chartHeight = contHeight - margin.bottom - margin.top;
    this.distanceUnit = distanceUnit;
    this.altitudeUnit = altitudeUnit;
    this.speedUnit = speedUnit;
    this.translations = translations;
    this.timezone = timezone;
    this.showTerrain = showTerrain;

    this.x = d3.scaleUtc(d3.extent(this.data, this.xAccessor) as [Date, Date], [margin.left, contWidth - margin.right]);
    this.yAltitude = this.getYAltitudeScale();
    this.ySpeed = this.getYSpeedScale();

    this.quadtree = this.generateQuadtree();

    this.addZoom();
    this.initialDraw();
  }

  private xAxisLabels(): Record<XAxis, string> {
    return {
      time: this.translations.time,
      distance: `${this.translations.distance} (${distance.label(this.distanceUnit)})`
    };
  }

  private getYAltitudeScale() {
    return d3.scaleLinear(
      [0, d3.max(this.data, d => d.altitude) ?? 1],
      [this.dimensions[1] - this.margin.bottom - this.spacing - (this.chartHeight / 2), this.margin.top]
    );
  }

  private getYSpeedScale() {
    return d3.scaleLinear(
      [0, d3.max(this.data, d => d.speed) ?? 1],
      [this.dimensions[1] - this.margin.bottom, this.margin.top + (this.chartHeight / 2) + this.spacing]
    );
  }

  private addZoom() {
    this.unscaledX = this.x;
    this.rootElement.on('.zoom', null);

    this.zoom = d3.zoom()
      .scaleExtent([1, Infinity])
      .on('zoom', ({ transform }) => {
        this.updateFromTransform(transform);
      });

    this.rootElement.call(this.zoom);
  }

  updateFromTransform(transform: d3.ZoomTransform) {
    this.x = transform.rescaleX(this.unscaledX);
    this.redraw();
    this.quadtree = this.generateQuadtree();
    this.showSelectedReport(this.previousSelectedReport?.id);
  }

  initialDraw() {
    this.drawGroups();
    this.drawEvents();

    this.drawSpeedAxes();
    this.drawAltitudeAxes();

    if (this.showTerrain) {
      this.drawTerrainArea();
    }
    this.drawKnownTransitions();

    this.drawAltitudeLine();
    this.drawSpeedLine();

    if (this.showTripBoundaries) {
      this.drawTripBoundaries();
    }
  }

  public setInferredEvents(inferredEvents: Record<number, InferredEventId[]> | undefined) {
    this.inferredEvents = inferredEvents;
    this.data = this.trip.reports
      .toSorted((a, b) => b.timeOfFix - a.timeOfFix)
      .map(report => ({ ...report, inferredEvents: this.inferredEvents?.[report.id] }));
    this.redraw();
  }

  public setShowTerrain(showTerrain: boolean) {
    this.showTerrain = showTerrain;
    this.useTransition = true;
    this.redraw();
    this.useTransition = false;
  }

  public setXAxisScale(newScale: XAxis) {
    this.xAccessor = xAccessors[newScale];
    this.xAxis = newScale;

    if (newScale === 'time') {
      this.x = d3.scaleUtc(
        d3.extent(this.data, xAccessors.time) as [Date, Date],
        [this.margin.left, this.dimensions[0] - this.margin.right]
      );
    } else {
      this.x = d3.scaleLinear(
        d3.extent(this.data, xAccessors.distance) as [number, number],
        [this.margin.left, this.dimensions[0] - this.margin.right]
      );
    }

    this.addZoom();
    this.redraw();
    this.quadtree = this.generateQuadtree();
  }

  public setAltitudeUnit(unit: ReduxState['unitSettings']['units']['altitude']) {
    this.altitudeUnit = unit;
    this.drawAltitudeAxes();
  }

  public setTimezone(timezone: string) {
    this.timezone = timezone;
    if (this.xAxis === 'time') {
      this.drawSpeedAxes();
    }
  }

  public setTranslations(translations: ChartTranslations) {
    this.translations = translations;
    this.drawAltitudeAxes();
    this.drawSpeedAxes();
  }

  public setDistanceUnit(unit: ReduxState['unitSettings']['units']['distance']) {
    this.distanceUnit = unit;
    this.drawSpeedAxes();
  }

  public setSpeedUnit(unit: ReduxState['unitSettings']['units']['speed']) {
    this.speedUnit = unit;
    this.drawSpeedAxes();
  }

  public redraw() {
    this.drawEvents();
    this.updateTerrainArea();
    this.drawSpeedAxes();
    this.drawKnownTransitions();
    this.updateAltitudeLine();
    this.updateSpeedLine();
    if (this.showTripBoundaries) {
      this.drawTripBoundaries();
    }
  }

  private drawGroups() {
    this.terrainGroup = this.rootElement.append('g').attr('class', 'terrain');
    this.existingTransitionsGroup = this.rootElement.append('g').attr('class', 'existingTransitions');
    this.knownTransitionsGroup = this.rootElement.append('g').attr('class', 'knownTransitions');
    this.altitudeGroup = this.rootElement.append('g').attr('class', 'altitude');
    this.speedGroup = this.rootElement.append('g').attr('class', 'speed');
    this.eventsGroup = this.rootElement.append('g').attr('class', 'events');
  }

  private drawEvents() {
    const y = this.margin.top + (this.chartHeight / 2) - 8;
    this.eventsGroup
      .selectAll('.event')
      .data(this.data.filter(d => d.events.length > 0 || (d.inferredEvents?.length ?? 0) > 0))
      .join('g')
      .attr('class', 'event')
      .attr('transform', d => `translate(${this.x(this.xAccessor(d)) - 8}, ${y})`)
      .html(d => ReactDOMServer.renderToString(<EventIcon type={getMostSignificantEvent(d).eventId} size={16} />));
  }

  private drawTripBoundaries() {
    this.existingTransitionsGroup.selectAll('.tripBoundaries')
      .data(this.trip.endTime ? [this.trip.startTime, this.trip.endTime] : [this.trip.startTime])
      .join('line')
      .attr('id', x => `tripBoundary-${x}`)
      .attr('class', 'tripBoundaries')
      .attr('stroke', (_, idx) => (idx === 0 ? '#0c0' : '#f00'))
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '8')
      .attr('transform', x => `translate(${this.x(new Date(x))}, 0)`)
      .attr('y1', this.margin.top)
      .attr('y2', this.dimensions[1] - this.margin.bottom);
  }

  private drawKnownTransitions() {
    this.knownTransitionsGroup.selectAll('.knownTransition')
      .data(this.userTransitions)
      .join('line')
      .attr('id', x => `knownTransition-${x.toState}-${x.reportTime}`)
      .attr('class', 'knownTransition')
      .attr('stroke', k => (k.toState === 'ACTIVE' ? '#0c0' : '#f00'))
      .attr('stroke-width', 4)
      .attr('transform', (datum, index) => {
        let x = this.x(new Date(datum.reportTime));
        const next = this.userTransitions.at(index + 1);
        const previous = this.userTransitions.at(index - 1);
        if (next?.reportTime === datum.reportTime) x -= 2;
        if (previous?.reportTime === datum.reportTime) x += 2;
        return `translate(${x}, 0)`;
      })
      .attr('y1', this.margin.top)
      .attr('y2', this.dimensions[1] - this.margin.bottom);
  }

  private drawSpeedAxes() {
    this.speedGroup.select('.x-axis').remove();
    this.speedGroup.select('.y-axis').remove();

    this.speedGroup.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${this.dimensions[1] - this.margin.bottom})`)
      .call(d3.axisBottom(this.x)
        .ticks(this.dimensions[0] / 100)
        .tickSizeOuter(0)
        .tickFormat(x => this.formatX(x, 0, true)))
      .call(g => g.append('text')
        .attr('x', this.margin.left + (this.dimensions[0] / 2) - this.margin.right)
        .attr('y', 35)
        .attr('text-anchor', 'middle')
        .attr('font-size', '1rem')
        .text(this.xAxisLabels()[this.xAxis])
        .attr('fill', this.theme.palette.common.black)
        .attr('paint-order', 'stroke')
        .attr('stroke-width', 5)
        .attr('stroke', this.theme.palette.background.paper));

    const transformedScaleY = this.ySpeed.copy().domain(this.ySpeed.domain().map(kmh => speed.fromKmh(kmh, this.speedUnit)));

    this.speedGroup.append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left},0)`)
      .call(d3.axisLeft(transformedScaleY).ticks(this.dimensions[1] / 100))
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('.tick line').clone()
        .attr('x2', this.dimensions[0] - this.margin.left - this.margin.right)
        .attr('stroke-opacity', 0.1))
      .call(g => g.append('text')
        .attr('fill', this.theme.palette.common.black)
        .attr('font-size', '1rem')
        .attr('text-anchor', 'middle')
        .text(`${this.translations.speed} (${speed.label(this.speedUnit)})`)
        .attr('paint-order', 'stroke')
        .attr('stroke-width', 5)
        .attr('stroke', this.theme.palette.background.paper)
        .attr('transform', `translate(${-this.margin.left + 15}, ${this.chartHeight * 0.75}) rotate(-90)`));
  }

  private generateQuadtree(): d3.Quadtree<TripSlimReport> {
    return d3
      .quadtree<TripSlimReport>()
      .y(() => this.yAltitude(0))
      .x(d => this.x(this.xAccessor(d)))
      .addAll(this.data);
  }

  private drawAltitudeAxes() {
    this.altitudeGroup.select('.altitude-x-axis').remove();
    this.altitudeGroup.select('.altitude-y-axis').remove();

    const transformedScale = this.yAltitude.copy().domain(this.yAltitude.domain().map(m => altitude.fromSI(m, this.altitudeUnit)));

    this.altitudeGroup.append('g')
      .attr('class', 'altitude-y-axis')
      .attr('transform', `translate(${this.margin.left},0)`)
      .call(d3.axisLeft(transformedScale).ticks(this.dimensions[1] / 100))
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('.tick line').clone()
        .attr('x2', this.dimensions[0] - this.margin.left - this.margin.right)
        .attr('stroke-opacity', 0.1))
      .call(g => g.append('text')
        .attr('fill', this.theme.palette.common.black)
        .attr('font-size', '1rem')
        .attr('text-anchor', 'middle')
        .text(`${this.translations.altitude} (${altitude.label(this.altitudeUnit)})`)
        .attr('paint-order', 'stroke')
        .attr('stroke-width', 5)
        .attr('stroke', this.theme.palette.background.paper)
        .attr('transform', `translate(${-this.margin.left + 15}, ${this.chartHeight * 0.25}) rotate(-90)`));

    this.altitudeGroup.append('g')
      .attr('class', 'altitude-x-axis')
      .attr('transform', `translate(0,${(this.chartHeight / 2) - this.spacing + this.margin.top})`)
      .call(d3.axisBottom(this.x).ticks(0));
  }

  private drawAltitudeLine() {
    const altitudeLine = this.getAltitudeLine();

    this.altitudeGroup.append('path')
      .attr('id', 'altitude-path')
      .attr('fill', 'none')
      .attr('stroke', this.assetColour)
      .attr('stroke-width', STROKE_WIDTH)
      .attr('d', altitudeLine(this.data));

    this.altitudeGroup.selectAll('.alt-dot')
      .data(this.data)
      .join('circle')
      .attr('class', 'alt-dot')
      .attr('cx', d => this.x(this.xAccessor(d)))
      .attr('cy', d => this.yAltitude(Math.max(0, d[this.showTerrain ? 'altitude' : 'elevation'])))
      .attr('r', MINOR_DOT_RADIUS)
      .attr('fill', this.assetColour);
  }

  private updateAltitudeLine() {
    const altitudeLine = this.getAltitudeLine();
    this.withTransition(this.rootElement.select('#altitude-path'))
      .attr('d', altitudeLine(this.data));

    this.withTransition(this.rootElement.selectAll('.alt-dot').data(this.data).join('circle'))
      .attr('cx', d => this.x(this.xAccessor(d)))
      .attr('cy', d => this.yAltitude(Math.max(0, d[this.showTerrain ? 'altitude' : 'elevation'])));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private withTransition<DataT>(selection: d3.Selection<BaseType, DataT, any, any>) {
    if (this.useTransition) {
      return selection.transition();
    }
    return selection;
  }

  private getAltitudeLine() {
    return d3.line<TripSlimReport>()
      .x(d => this.x(this.xAccessor(d)))
      .y(d => this.yAltitude(Math.max(0, d[this.showTerrain ? 'altitude' : 'elevation'])))
      .curve(d3.curveMonotoneX);
  }

  private drawTerrainArea() {
    const terrainArea = this.getTerrainArea();

    this.terrainGroup.append('path')
      .attr('id', 'terrain-area')
      .attr('fill', GROUND_COLOR)
      .attr('stroke', GROUND_COLOR)
      .attr('stroke-width', STROKE_WIDTH)
      .attr('d', terrainArea(this.data))
      .style('opacity', this.showTerrain ? 1 : 0);
  }

  private getTerrainArea() {
    return d3.area<TripSlimReport>()
      .x(d => this.x(this.xAccessor(d)))
      .y0(() => this.yAltitude(0))
      .y1(d => this.yAltitude(this.showTerrain ? Math.max(0, d.altitude - d.elevation) : 0))
      .curve(d3.curveMonotoneX);
  }

  private updateTerrainArea() {
    const terrainArea = this.getTerrainArea();

    this.withTransition(this.rootElement.select('#terrain-area'))
      .style('visibility', this.showTerrain ? 'visible' : 'hidden')
      .attr('d', terrainArea(this.data));
  }

  private drawSpeedLine() {
    const speedLine = this.getSpeedLine();

    this.speedGroup.append('path')
      .attr('class', 'speed-path')
      .attr('fill', 'none')
      .attr('stroke', this.assetColour)
      .attr('stroke-width', STROKE_WIDTH)
      .attr('d', speedLine(this.data));

    this.speedGroup.selectAll('.spd-dot')
      .data(this.data)
      .join('circle')
      .attr('class', 'spd-dot')
      .attr('cx', d => this.x(this.xAccessor(d)))
      .attr('cy', d => this.ySpeed(d.speed))
      .attr('r', MINOR_DOT_RADIUS)
      .attr('fill', this.assetColour);
  }

  private getSpeedLine() {
    return d3.line<TripSlimReport>()
      .x(d => this.x(this.xAccessor(d)))
      .y(d => this.ySpeed(d.speed))
      .curve(d3.curveMonotoneX);
  }

  private updateSpeedLine() {
    const speedLine = this.getSpeedLine();

    this.withTransition(this.rootElement.select('.speed-path'))
      .attr('d', speedLine(this.data));

    this.withTransition(this.rootElement.selectAll('.spd-dot').data(this.data))
      .attr('cx', d => this.x(this.xAccessor(d)))
      .attr('cy', d => this.ySpeed(d.speed));
  }

  public addSelectedReportListener(callback: (id: number | undefined) => void) {
    this.rootElement.on('mousemove', e => {
      const pointer = d3.pointer(e);
      const report = this.quadtree.find(pointer[0], this.yAltitude(0));
      callback(report?.id);
    }).on('mouseleave', () => callback(undefined));
  }

  public showSelectedReport(id: number | undefined) {
    const selectedReport = this.data.find(r => r.id === id);
    this.previousSelectedReport = selectedReport;

    const selectedDotRadius = 3;

    if (!selectedReport) {
      this.selectedInformation?.attr('opacity', 0);
      return;
    }

    this.selectedInformation?.attr('opacity', 1);

    if (!this.selectedInformation) {
      this.selectedInformation = this.rootElement.append('g').attr('class', 'selectedReport');

      this.selectedInformation.append('line')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', this.margin.top)
        .attr('y2', this.margin.top + (this.chartHeight / 2) - this.spacing)
        .attr('stroke', '#aaa');

      this.selectedInformation.append('line')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', this.margin.top + (this.chartHeight / 2) + this.spacing)
        .attr('y2', this.margin.top + this.chartHeight)
        .attr('stroke', '#aaa');

      this.selectedInformation.append('circle')
        .attr('class', 'selectedAlt')
        .attr('r', selectedDotRadius)
        .attr('cx', 0)
        .attr('cy', this.yAltitude(selectedReport[this.showTerrain ? 'altitude' : 'elevation']))
        .attr('fill', this.assetColour);

      if (this.showTerrain) {
        this.selectedInformation.append('circle')
          .attr('class', 'selectedTrn')
          .attr('r', selectedDotRadius)
          .attr('cx', 0)
          .attr('cy', this.yAltitude(Math.max(0, selectedReport.altitude - selectedReport.elevation)))
          .attr('fill', GROUND_COLOR);

        this.selectedInformation.append('circle')
          .attr('class', 'selectedTrn')
          .attr('r', selectedDotRadius - 1)
          .attr('cx', 0)
          .attr('cy', this.yAltitude(Math.max(0, selectedReport.altitude - selectedReport.elevation)))
          .attr('fill', 'white');
      }

      this.selectedInformation.append('circle')
        .attr('class', 'selectedSpeed')
        .attr('r', selectedDotRadius)
        .attr('cx', 0)
        .attr('cy', this.ySpeed(selectedReport.speed))
        .attr('fill', this.assetColour);

      const textGroup = this.selectedInformation.append('g')
        .attr('font-size', 10)
        .attr('fill', this.theme.palette.common.black)
        .attr('paint-order', 'stroke')
        .attr('stroke-width', 5)
        .attr('stroke', this.theme.palette.background.paper);

      textGroup.append('text')
        .attr('class', 'selectedTime')
        .attr('x', 0)
        .attr('y', this.dimensions[1] - this.margin.bottom + 16)
        .attr('text-anchor', 'middle');

      textGroup.append('text')
        .attr('class', 'selectedSpeedText')
        .attr('x', 0)
        .attr('y', this.ySpeed(selectedReport.speed))
        .attr('text-anchor', 'end');

      textGroup.append('text')
        .attr('class', 'selectedAltText')
        .attr('x', 0)
        .attr('y', this.yAltitude(selectedReport.altitude))
        .attr('text-anchor', 'end');
    }

    const selectedReportX = this.x(this.xAccessor(selectedReport));
    this.selectedInformation.attr('transform', `translate(${selectedReportX},0)`);
    this.selectedInformation.select('.selectedSpeed').attr('cy', this.ySpeed(selectedReport.speed));
    this.selectedInformation.select('.selectedSpeedText')
      .attr('y', this.ySpeed(selectedReport.speed))
      .text(speed.withUnits(speed.fromKmh(selectedReport.speed, this.speedUnit), this.speedUnit));

    this.selectedInformation.selectAll('.selectedTime').text(this.formatX(this.xAccessor(selectedReport)));

    const altGraphDotHeight = this.showTerrain ? selectedReport.altitude : selectedReport.elevation;
    this.selectedInformation.selectAll('.selectedAltText')
      .attr('y', this.yAltitude(Math.max(0, altGraphDotHeight)))
      .text(altitude.withUnits(altitude.fromSI(altGraphDotHeight, this.altitudeUnit), this.altitudeUnit));

    this.selectedInformation.selectAll('.selectedAlt').attr('cy', this.yAltitude(Math.max(0, altGraphDotHeight)));

    if (this.showTerrain) {
      this.selectedInformation.selectAll('.selectedTrn')
        .attr('cy', this.yAltitude(Math.max(0, selectedReport.altitude - selectedReport.elevation)))
        .style('visibility', 'visible');
      return;
    }

    this.selectedInformation.selectAll('.selectedTrn').style('visibility', 'hidden');
  }

  private formatX(xValue: NumberValue | number | Date, digits = 2, ignoreSeconds = false): string {
    if (xValue instanceof Date) {
      if (xValue.getSeconds() && ignoreSeconds) {
        return '';
      }
      return DateTime.fromJSDate(xValue).setZone(this.timezone).toFormat(digits === 0 ? 'HH:mm' : 'HH:mm:ss');
    }
    if (typeof xValue === 'number') {
      return `${xValue.toFixed(digits)}${distance.label(this.distanceUnit)}`;
    }
    return `${xValue.valueOf().toFixed(digits)}km`;
  }

  setReports(reports: TripSlimReport[]) {
    if (this.originalWidth === undefined) {
      const oldReportTimes = this.data.map(r => r.timeOfFix);
      this.originalWidth = Math.max(...oldReportTimes) - Math.min(...oldReportTimes);
    }
    const newReportTimes = reports.map(r => r.timeOfFix);
    const newWidth = Math.max(...newReportTimes) - Math.min(...newReportTimes);

    this.data = reports
      .toSorted((a, b) => b.timeOfFix - a.timeOfFix)
      .map(report => ({ ...report, inferredEvents: this.inferredEvents?.[report.id] }));
    this.zoom = this.zoom.scaleExtent([this.originalWidth / newWidth, Infinity]);
    this.rootElement.selectChildren().remove();
    this.rootElement.on('.zoom', null);
    this.rootElement.call(this.zoom);

    this.yAltitude = this.getYAltitudeScale();
    this.ySpeed = this.getYSpeedScale();
    this.quadtree = this.generateQuadtree();
    this.selectedInformation = undefined;

    this.initialDraw();
  }

  setUserTransitions(userTransitions: MinimalUserTransition[]) {
    this.userTransitions = userTransitions;
    this.redraw();
  }
}
