import { di } from 'di';
import TpcFirebaseService from 'firebaseApp/tpc-firebase-service';
import { store } from 'store';
import { persistorFactory } from 'store/persistor';
import { createConsole } from 'utils/console';

// Register dependencies
di.bindSingleton('firebaseService', () => new TpcFirebaseService());
di.bindSingleton('reduxStore', () => store);
di.bindSingleton('reduxStorePersistor', persistorFactory);
di.bind('console', createConsole);
