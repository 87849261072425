import { HttpResponseError, serenityFetch } from 'helpers/api';

export const getMessagingWhitelistContactGroups = async (organisationId: string): Promise<ContactGroup[]> => {
  const response = await serenityFetch(
    'GET',
    `/organisations/${organisationId}/people/groups/messaging-whitelist`,
    null,
  );
  HttpResponseError.detect(response);
  return (await response.json()).messagingWhitelistGroups;
};

export const addMessagingWhitelistContactGroup = async (
  organisationId: string,
  body: Pick<ContactGroup, 'name'>,
): Promise<ContactGroup> => {
  const response = await serenityFetch(
    'POST',
    `/organisations/${organisationId}/people/groups/messaging-whitelist`,
    body,
  );
  HttpResponseError.detect(response);
  return (await response.json()).messagingWhitelistGroup;
};

export const updateMessagingWhitelistContactGroup = async (
  organisationId: string,
  body: Pick<ContactGroup, 'id' | 'name'>,
): Promise<void> => {
  const response = await serenityFetch(
    'PUT',
    `/organisations/${organisationId}/people/groups/messaging-whitelist/${body.id}`,
    body,
  );
  HttpResponseError.detect(response);
};

export interface GroupAssignDevicesRequestBody {
  id: number;
  deviceVersion: number;
  deviceIds: number[];
}
export const updateMessagingWhitelistContactGroupAssignDevices = async (
  organisationId: string,
  body: GroupAssignDevicesRequestBody,
): Promise<void> => {
  const response = await serenityFetch(
    'POST',
    `/organisations/${organisationId}/people/groups/messaging-whitelist/${body.id}/devices`,
    body,
  );
  HttpResponseError.detect(response);
};

export interface GroupAssignPeopleRequestBody {
  id: number;
  peopleVersion: number;
  peopleForGroup: {
    personId: number;
    order: number;
  }[];
}
export const updateMessagingWhitelistContactGroupAssignPeople = async (
  organisationId: string,
  body: GroupAssignPeopleRequestBody,
): Promise<void> => {
  const response = await serenityFetch(
    'POST',
    `/organisations/${organisationId}/people/groups/messaging-whitelist/${body.id}/people`,
    body,
  );
  HttpResponseError.detect(response);
};

export const deleteMessagingWhitelistContactGroup = async (
  organisationId: string,
  body: Pick<ContactGroup, 'id'>,
): Promise<void> => {
  const response = await serenityFetch(
    'DELETE',
    `/organisations/${organisationId}/people/groups/messaging-whitelist/${body.id}`,
    null,
  );
  HttpResponseError.detect(response);
};
