import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useMemo } from 'react';
import {
  addPeopleGroup,
  addPeopleToPeopleGroup,
  deletePeopleGroup,
  getPeopleGroup,
  updatePeopleGroup,
} from './requests';

type PeopleGroupsQueryKey = ['peopleGroups', { organisationId: string; peopleGroupTypeId: number }];
const usePeopleGroupQueryKey = (organisationId: string, peopleGroupTypeId: number): PeopleGroupsQueryKey =>
  useMemo(() => ['peopleGroups', { organisationId, peopleGroupTypeId }], [organisationId, peopleGroupTypeId]);

// TODO: make peopleGroupTypeId dynamic when more groups are added

export const useGetPeopleGroups = <T = ContactGroup[]>() => {
  const organisationId = useOrganisationId();
  const peopleGroupTypeId = 3;
  const queryKey = usePeopleGroupQueryKey(organisationId, peopleGroupTypeId);
  const query = useQuery<ContactGroup[], HttpResponseError, T>({
    queryKey,
    queryFn: () => getPeopleGroup(organisationId, peopleGroupTypeId),
  });
  return { query, queryKey };
};

export const useMutateDeletePeopleGroup = () => {
  const organisationId = useOrganisationId();
  const peopleGroupTypeId = 3;
  const queryClient = useQueryClient();
  const queryKey = usePeopleGroupQueryKey(organisationId, peopleGroupTypeId);

  return useMutation<void, HttpResponseError, Pick<ContactGroup, 'id'>, { previousGroups?: ContactGroup[] }>({
    mutationKey: ['deletePeopleGroup'],
    mutationFn: value => deletePeopleGroup(organisationId, peopleGroupTypeId, value.id),
    onMutate: async value => {
      await queryClient.cancelQueries({ queryKey });
      const previousGroups = queryClient.getQueryData<ContactGroup[]>(queryKey);
      if (previousGroups?.some(x => x.id === value.id)) {
        queryClient.setQueryData<ContactGroup[]>(queryKey, () => previousGroups.filter(g => g.id !== value.id));
      }
      return { previousGroups };
    },
    onError: (_, __, context) => {
      if (context?.previousGroups) {
        queryClient.setQueryData(queryKey, context.previousGroups);
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });
};

export const useMutateCreatePeopleGroup = () => {
  const organisationId = useOrganisationId();
  const peopleGroupTypeId = 3;
  const queryKey = usePeopleGroupQueryKey(organisationId, peopleGroupTypeId);
  const queryClient = useQueryClient();

  return useMutation<ContactGroup, HttpResponseError, Pick<ContactGroup, 'name'>, { previousGroups?: ContactGroup[] }>({
    mutationKey: ['createPeopleGroup'],
    mutationFn: value => addPeopleGroup(organisationId, peopleGroupTypeId, value),
    onMutate: async value => {
      await queryClient.cancelQueries({ queryKey });
      const previousGroups = queryClient.getQueryData<ContactGroup[]>(queryKey);
      if (previousGroups?.some(x => x.name === value.name)) {
        queryClient.setQueryData<ContactGroup[]>(queryKey, () => previousGroups.filter(g => g.name !== value.name));
      }
      return { previousGroups };
    },
    onError: (_, __, context) => {
      if (context?.previousGroups) {
        queryClient.setQueryData(queryKey, context.previousGroups);
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });
};

export const useMutateAddPeopleToPeopleGroup = () => {
  const organisationId = useOrganisationId();
  const peopleGroupTypeId = 3;
  const queryKey = usePeopleGroupQueryKey(organisationId, peopleGroupTypeId);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['addPeopleToPeopleGroup'],
    mutationFn: (value: Pick<ContactGroup, 'id' | 'peopleWithOrder' | 'peopleVersion'>) =>
      addPeopleToPeopleGroup(organisationId, peopleGroupTypeId, {
        id: value.id,
        peopleForGroup: value.peopleWithOrder,
        peopleVersion: value.peopleVersion,
      }),
    onMutate: async value => {
      await queryClient.cancelQueries({ queryKey });
      const previousGroups = queryClient.getQueryData<ContactGroup[]>(queryKey);
      if (previousGroups?.some(x => x.id === value.id)) {
        queryClient.setQueryData<ContactGroup[]>(queryKey, () =>
          previousGroups.map(x => (x.id === value.id ? { ...x, ...value, temporary: true } : x)),
        );
      }
      return { previousGroups };
    },
    onError: (err, value, context) => {
      if (context?.previousGroups) {
        queryClient.setQueryData(queryKey, context.previousGroups);
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });
};

export const useMutateUpdatePeopleGroup = () => {
  const organisationId = useOrganisationId();
  const peopleGroupTypeId = 3;
  const queryKey = usePeopleGroupQueryKey(organisationId, peopleGroupTypeId);
  const queryClient = useQueryClient();

  return useMutation<
    void,
    HttpResponseError,
    Pick<ContactGroup, 'id' | 'name' | 'isDefault'>,
    { previousGroups?: ContactGroup[] }
  >({
    mutationKey: ['updatePeopleGroup'],
    mutationFn: value => updatePeopleGroup(organisationId, peopleGroupTypeId, value),
    onMutate: async value => {
      await queryClient.cancelQueries({ queryKey });
      const previousGroups = queryClient.getQueryData<ContactGroup[]>(queryKey);

      if (previousGroups?.some(g => g.id === value.id)) {
        queryClient.setQueryData<ContactGroup[]>(queryKey, () =>
          previousGroups.map(g => {
            if (g.id === value.id) return { ...g, ...value, temporary: true };
            if (value.isDefault && g.isDefault) return { ...g, isDefault: false, temporary: true };
            return g;
          }),
        );
      }
      return { previousGroups };
    },
    onError: (err, value, context) => {
      if (context?.previousGroups) {
        queryClient.setQueryData(queryKey, context.previousGroups);
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });
};
