import React, { Fragment, useReducer, useMemo, useEffect, useRef, useState } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { useSize } from 'hooks/useSize';
import { minBy } from 'lodash';
import { useTranslations } from 'use-intl';
import { DateTime } from 'luxon';
import { useGetInferredEventsByReportIdForAsset } from 'apis/rest/inferredEvents/hooks';
import { useGetEngineUsageForAssets } from 'apis/rest/engineUsage/hooks';
import { alpha } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { AssetTripsSummary } from './assetTripsSummary';
import { AssetTripTimelineView } from './assetTripTimelineView';
import { TripDetail, LoadTrip } from '../tripDetails/tripDetail';
import { Action, TripViewState } from '../types';

interface TripTimelineViewProps {
  query: { assets: number[], from: number, until: number }
  trips: Trip[]
  assets: AssetWithDevice[]
  timezone: string
  displayEngineUsage: boolean
}

export const reducer = (state: TripViewState, action: Action): TripViewState => {
  switch (action.type) {
    case 'SELECT_NEXT_TRIP': {
      if (!state.selectedTrip) {
        return state;
      }
      const nextTrip = state.trips
        .filter(t => t.startTime > (state.selectedTrip?.startTime ?? Infinity))
        .sort((a, b) => a.startTime - b.startTime)
        .find(t => t.assetId === state.selectedTrip?.assetId);

      if (!nextTrip) {
        return state;
      }
      mixpanel.track('Select Next Trip');
      return ({ ...state, selectedTrip: nextTrip });
    }
    case 'SELECT_PREVIOUS_TRIP': {
      if (!state.selectedTrip) {
        return state;
      }
      const nextTrip = state.trips
        .filter(t => t.startTime < (state.selectedTrip?.startTime ?? Infinity))
        .sort((a, b) => b.startTime - a.startTime)
        .find(t => t.assetId === state.selectedTrip?.assetId);

      if (!nextTrip) {
        return state;
      }
      mixpanel.track('Select Previous Trip');
      return ({ ...state, selectedTrip: nextTrip });
    }
    case 'SET_SELECTED_TRIP': {
      const selectedTrip = state.trips.find(t => t.id === action.tripId);
      if (selectedTrip) {
        mixpanel.track('Select Trip', {
          tripAssetId: selectedTrip.assetId,
          tripStart: selectedTrip.start,
          tripStartTime: selectedTrip.startTime,
          tripEnd: selectedTrip.end,
          tripEndTime: selectedTrip.endTime,
          tripDuration: selectedTrip.duration
        });
      }
      return ({ ...state, selectedTrip, selectedReport: undefined });
    }
    case 'SET_SELECTED_REPORT': {
      return ({ ...state, selectedReport: action.report });
    }
    case 'SET_CHANGE_REQUEST':
      if (action.changeRequest) {
        return ({ ...state, mostRecentChangeRequest: action.changeRequest });
      }
      return state;
    case 'SET_TRIPS': {
      if (action.trips) {
        if (state.mostRecentChangeRequest?.transitions.length) {
          const earliestStart = minBy(state.mostRecentChangeRequest.transitions, r => r.reportTime);
          const matchingTrip = action.trips.find(t => t.startTime === earliestStart?.reportTime && t.assetId === state.mostRecentChangeRequest?.assetId);
          if (matchingTrip) {
            return ({ trips: action.trips, selectedTrip: matchingTrip });
          }
        }
        const selectedTrip = action.trips.find(t => t.id === state.selectedTrip?.id);
        if (selectedTrip) {
          return ({ trips: action.trips, selectedTrip, selectedReport: state.selectedReport });
        }

        if (state.findTripAround) {
          const matchingTrip = action.trips.find(t => t.startTime <= state.findTripAround && t.endTime >= state.findTripAround);
          if (matchingTrip) {
            return ({ trips: action.trips, selectedTrip: matchingTrip });
          }
        }

        return ({ trips: action.trips });
      }
      return state;
    }
    default:
      return state;
  }
};

export const TripTimelineView = ({
  query,
  trips,
  assets,
  timezone,
  displayEngineUsage,
}: TripTimelineViewProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis');
  const [assetHeights, setAssetHeights] = useState<number[]>([]);
  const maxAssetHeight = useMemo<number>(() => Math.max(...assetHeights), [assetHeights]);

  const boxRef = useRef<HTMLDivElement>(null);
  const size = useSize(boxRef);

  const engineUsageQuery = useGetEngineUsageForAssets(
    query.assets,
    query.from,
    query.until,
    { enabled: displayEngineUsage },
  );

  useEffect(() => {
    if (size && boxRef?.current) {
      const children = [...boxRef.current.querySelectorAll('.assetLabel')];
      const heights = children.map(c => parseFloat(getComputedStyle(c).height));
      setAssetHeights(heights);
    }
  }, [size, setAssetHeights]);

  const { state } = useLocation();

  const [tripsState, dispatch] = useReducer(reducer, { trips, findTripAround: state?.findTripAround });

  const navigate = useNavigate();
  useEffect(() => {
    if (state) {
      navigate('.', { state: undefined });
    }
  }, [state, navigate]);

  useEffect(() => {
    dispatch({ type: 'SET_TRIPS', trips });
  }, [trips]);

  const selectedAssetId = tripsState.selectedTrip?.assetId;
  const filteredAssets = useMemo(() => assets.filter(a => selectedAssetId === undefined || a.id === selectedAssetId), [assets, selectedAssetId]);

  const inferredEventsQuery = useGetInferredEventsByReportIdForAsset(
    selectedAssetId,
    DateTime.fromMillis(query.from),
    DateTime.fromMillis(query.until),
  );

  const timeBounds = useMemo<[number, number]>(() => [query.from, query.until], [query]);

  return (
    <>
      <Paper sx={{ my: 3, p: 3 }} elevation={0}>
        <Box textAlign="center">
          <Typography variant="subtitle1">{t('promptSelectTrip')}</Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'max-content minmax(0, auto)',
          }}
        >
          <Box
            ref={boxRef}
            gridColumn={1}
            sx={{
              gridRow: 1,
              display: 'grid',
              gridTemplateColumns: 'max-content max-content',
              columnGap: 3,
            }}
          >
            {filteredAssets.map((asset, index) => {
              const assetTrips = trips.filter(trip => trip.assetId === asset.id).sort((a, b) => (b.startTime - a.startTime));
              return (
                <Fragment key={asset.id}>
                  <AssetTripsSummary
                    asset={asset}
                    trips={assetTrips}
                    displayEngineUsage={displayEngineUsage}
                    engineUsagesQuery={engineUsageQuery}
                    assetHeight={maxAssetHeight}
                  />
                  {index !== filteredAssets.length - 1 && (
                    <Box
                      gridColumn="1 / -1"
                      sx={theme => ({
                        boxSizing: 'border-box',
                        height: '0px',
                        mb: '-1px',
                        borderTop: '1px solid',
                        borderTopColor: alpha(theme.palette.common.black, 0.1)
                      })}
                    />
                  )}
                </Fragment>
              );
            })}
          </Box>
          <Box gridColumn={2} gridRow="1/3">
            {assetHeights.length > 0 && (
              <AssetTripTimelineView
                trips={trips}
                assets={filteredAssets}
                assetHeights={assetHeights}
                maxMinTimes={timeBounds}
                dispatch={dispatch}
                selectedId={tripsState.selectedTrip?.id}
                engineUsageQuery={engineUsageQuery}
                displayEngineUsage={displayEngineUsage}
                timezone={timezone}
              />
            )}
          </Box>
        </Box>
      </Paper>

      {tripsState.selectedTrip && (
        <Paper sx={{ my: 3 }} elevation={0}>
          <LoadTrip
            tripId={tripsState.selectedTrip.id}
            assetId={tripsState.selectedTrip.assetId}
            loaded={innerTrip => <TripDetail trip={innerTrip} inferredEvents={inferredEventsQuery.data} dispatch={dispatch} timezone={timezone} />}
          />
        </Paper>
      )}
    </>
  );
};
